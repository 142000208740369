import Icon from "../../../Icon/Icon";
import AccordionButton from "./AccordionButton";
import { AccordionSectionInterface } from "./AccordionSection.types";
import { ByLang } from "../../../../language/translation";
import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";

const AccordionSection = ({
  appearance = "primary",
  children,
  isOpen = false,
  item,
  onClick,
  onClickCollapse,
  selected,
}: AccordionSectionInterface) => {
  const { i18n } = useTranslation();

  const APPEARANCE_SECTION_HEAD = {
    default: "text-bdo-text-01-charcoal",
    accordion: "",
    input: "",
    primary: "text-bdo-text-01-charcoal text-start",
    secondary: "text-bdo-text-01-charcoal bg-bdo-button-primary-red",
  };

  const APPEARANCE_SECTION_CONTENT = {
    default: "text-bdo-text-01-charcoal",
    accordion: "",
    input: "",
    primary: "text-bdo-text-01-charcoal",
    secondary: "text-bdo-text-01-charcoal bg-bdo-button-primary-red",
  };

  const buildTooltip = () => <ul>
    {
      item?.children?.map((report, index) => 
      <li className="py-1 flex gap-2" key={report.id}>
        <span>-</span>
        <p className="text-wrap">{ByLang(report?.label, i18n)}</p>
      </li>)
    }
  </ul>

  return (
    <div className={`w-full ${APPEARANCE_SECTION_HEAD[appearance]}`}>
      <div className={`flex justify-between w-full items-center  pl-0 cursor-pointer`} onClick={onClick}>
        <Tooltip placement="right" title={buildTooltip()} color="#4d4c4c" overlayStyle={{ maxWidth: "200px", wordBreak: "break-word", whiteSpace: "normal" }}>
          {item && item.children && item.children.length > 0 && (
            <label className="flex w-full items-center">
              {item && (
                <AccordionButton
                  className={`max-w-fit text-start text-sm py-0 font-semibold hover:text-[#ED1A3B] min-h-[56px]`}
                  onClick={onClickCollapse}
                  size="md"
                  textSize="md"
                  value={item?.label?.en || ""}
                  qtty={item?.children?.length}
                >
                  {ByLang(item?.label, i18n)}
                </AccordionButton>
              )}
              <span className="flex ml-auto text-bdo-button-primary-red cursor-pointer items-center justify-center">
                {isOpen && <Icon name="expand_more" />}
                {!isOpen && <Icon name="chevron_right" />}
              </span>
            </label>
          )}
        </Tooltip>

        {item && (!item.children || !(item.children.length > 0)) && (
          <div className=" w-full">
            {item && (
              <AccordionButton
                className={` accordionReportsHover w-full text-start text-sm py-3 pl-10 min-h-[56px]`}
                onClick={item.onClick}
                selected={selected}
                size="md"
                textSize="md"
                value={item?.label?.en || ""}
              >
                {ByLang(item?.label, i18n)}
              </AccordionButton>
            )}
          </div>
        )}
      </div>
      {isOpen && <div className={`flex flex-col w-full${APPEARANCE_SECTION_CONTENT[appearance]}`}>{children}</div>}
    </div>
  );
};

export default AccordionSection;
