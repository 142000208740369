import BrowserRoutes from "./router/BrowserRoutes";
import MaxWidthWrapper from "./components/page/MaxWidthWrapper";
import Header from "./pageContent/Header";
import { ReactNode, useContext, useEffect, useState } from "react";
import "./i18n";
import SideBar from "./components/Sidebar";
import { useTranslation } from 'react-i18next';
import useCallApi from "./hooks/useCallApi";
import { GlobalContext } from "./contextAPI/globalContext";
import { BarLoader } from "./components/Loader";
import { useGetId } from "./hooks/useGetOid";
import IdleTimerContainer from "./components/IdleTimerContainer";
import { ReportPageProvider } from "./contextAPI/reportPageContext";
import { LandingDefaultPage } from './components/NoAuthorized/index';

function App() {
  const [headerTitle, setHeaderTitle] = useState<ReactNode>();
  const { t, i18n } = useTranslation();
  const { callApi, callApiLoading } = useCallApi();
  const { setIsAppAdmin, setIsReportAdmin, setOid } = useContext(GlobalContext);
  const { getOid } = useGetId();
  const [haveAccess, setHaveAccess] = useState<null | boolean>(null);
  const [accessResult, AccessResult] = useState({
    title: 'TITULO',
    message: 'ESTO ES UNA PRUEBA',
    status: 401
  })

  const ERROR500 = "Internal server error, please contact the administrator"
  const ERROR401 = "You don't have the required privileges to access this application"

  const authorization = async () => {
    const res = await callApi({ endpoint: 'IsAuthorized' }); //--> 'userInfo'
    // res.statusCodeResult=401; //--> just for testing
    if (res.statusCodeResult === 200) {
      let oid = await getOid();
      oid && setOid(oid);
      setHaveAccess(true);
      //checking if user is application admin
      res.userInfo.isApplicationAdmin && setIsAppAdmin(res.userInfo.isApplicationAdmin)
      //checking if user is report admin
      res.userInfo.hasReportManagementAccess && setIsReportAdmin(res.userInfo.hasReportManagementAccess);
    } else if (res.statusCodeResult === 401) {
      setHaveAccess(false);
      AccessResult({
        title: 'Unauthorized Access',
        message: ERROR401,
        status: 401
      })
    } else if (res.statusCodeResult === 500) {
      setHaveAccess(false);
      AccessResult({
        title: 'Error Access',
        message: ERROR500,
        status: res.statusCodeResult
      })
    } else {
      setHaveAccess(false);
      AccessResult({
        title: `${res.status} ${res.statusText}`,
        message: `Somenthing went wrong`,
        status: 0
      })
    }

  }

  useEffect(() => {
    // const lng = localStorage.getItem('i18nextLng');
    const lng = navigator.language;
    i18n.changeLanguage(lng);
    authorization();
  }, [])
  // setIsAppAdmin(false); //--> just for testing

  return (
    callApiLoading || haveAccess === null
      ? <BarLoader />
      : !haveAccess
        ? <div className="h-[100vh]">
          <LandingDefaultPage title={accessResult.title} message={accessResult.message} typeOfImage={accessResult.status === 401 ? "appAuth" : "appError"} />
        </div>
        :
        <div className="flex h-[100vh] w-full bg-bdo-bg-03-charcoal-paler font-proximanova ">
          <SideBar />
          <MaxWidthWrapper className="flex-col viewsContainer">
            <ReportPageProvider>
              <Header headerTitle={headerTitle} />
              <BrowserRoutes setHeaderTitle={setHeaderTitle} />
            </ReportPageProvider>
          </MaxWidthWrapper>
          <IdleTimerContainer />
        </div>
  );
}

export default App;
