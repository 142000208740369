import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { Category } from 'src/pages/index.type';
import { CategoryManagementPageContext } from 'src/contextAPI/categoryManagementPageContext';
import { GlobalContext } from 'src/contextAPI/globalContext';
import { MdOutlineEdit } from 'react-icons/md'
import { Modal } from 'antd'
import { useNotification } from 'src/hooks/useNotification';
import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react'
import TextInputWithSearchIcon from '../input/TextInputWithSearchIcon';
import useCallApi from 'src/hooks/useCallApi';
import useCategoryLogic from 'src/hooks/useCategoryLogic';

const EditModule = (category: Category) => {
    const { t, i18n } = useTranslation();
    const { callApi, isError, callApiLoading } = useCallApi();
    const { categoryList, setIsCollapsible } = useContext(CategoryManagementPageContext)
    const { fetchCategoryList } = useCategoryLogic()
    const { oid } = useContext(GlobalContext)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isValidationOn, setIsValidationOn] = useState(false);
    const [isEnglish, setIsEnglish] = useState(i18n.language === "en");
    const { contextHolder, notification } = useNotification();
    const INITIALDATAFORM = {
        name: { en: category.categoryEn || "", fr: category.categoryFr || "" },
        description: { en: category.descriptionEn || "", fr: category.descriptionFr || "" }
    }
    const [dataForm, setDataForm] = useState<any>(INITIALDATAFORM);

    useEffect(() => {
        console.log('category', category)
        setDataForm(INITIALDATAFORM)
    }, [category])


    const callApiToUpdateCategory = async () => {
        const body = { //-> data to send to the server
            id: category.id,
            categoryEn: dataForm.name.en.trim(),
            categoryFr: dataForm.name.fr.trim(),
            descriptionEn: dataForm.description.en.trim(),
            descriptionFr: dataForm.description.fr.trim(),
            categoryGroupId: category.categoryGroupId,
            submittedByUserId: oid
        }
        let res = await callApi({ endpoint: 'UpdateCategory', body, method: 'PUT' }) //api/category/update
        if (isError.status || res.status !== 200) {
            console.log('error', res)
            notification({ content: { name: t("t2"), message: `${res.status} ${res && res.message || res.statusText || isError && isError.message} ` }, type: 'error' })
            return false
        }
        return true
    }

    const handleOk = async () => {
        setIsValidationOn(true);
        if (!categoryList) return
        if (dataForm.name.en === "" || dataForm.name.fr === "") return
        let isOk = await callApiToUpdateCategory()
        if (!isOk) return

        //----success, then update the categoryList
        fetchCategoryList({ notification })
        setIsCollapsible(true)
        setIsModalOpen(false)
        notification({ content: { name: t("success"), message: t("categManagement.updatedSuccessfully") }, type: 'success' })
    }

    return (
        <>
            <MdOutlineEdit size={18} onClick={() => { setIsModalOpen(!isModalOpen); setIsCollapsible(false) }} />
            <Modal
                className='addModule min-w-[700px] h-[700px] height-modal'
                title={<div><h2>{t("categManagement.editCategory")}</h2><span className='text-red-600 text-base font-medium'>{i18n.language === 'en' ? category.categoryEn : category.categoryFr}</span></div>}
                okText={t("save")}
                cancelText={t("t8")}
                open={isModalOpen}
                onOk={handleOk}
                confirmLoading={callApiLoading} //--->TODO: add a loading state when the data is sending to the server
                onCancel={() => { setIsModalOpen(false); setIsCollapsible(true); setDataForm(INITIALDATAFORM) }}
            >
                <hr />
                <form className='flex flex-col justify-start gap-5 mt-5'>
                    <section className='mb-3 flex flex-col w-full gap-3'>
                        <div className="w-full overflow-hidden">

                            {/* Toggle and Information===== */}
                            <section className='flex items-center justify-between my-2 bg-[#ebe8e8c7] px-3 py-3'>
                                <aside className='  flex items-center gap-3'>
                                    <AiOutlineExclamationCircle size={15} color='#e81a3b' />
                                    <span className='text-sm text-[#443b3b]'>{t("categManagement.info")}</span>
                                </aside>
                                <div className="flex justify-end gap-5">
                                    <div className="relative">
                                        <span className={`cursor-pointer ${isEnglish && "font-semibold"} text-[#616161]`} onClick={() => setIsEnglish(true)}>EN</span>
                                        <hr className={`h-1 ${isEnglish && "bg-[#e81a3b]"}`} />
                                        {isValidationOn && dataForm.name.en === "" && <span className="text-red-600 text-2xl absolute -top-0 -left-2">*</span>}
                                    </div>
                                    <div className="relative">
                                        <span className={`cursor-pointer ${!isEnglish && "font-semibold"} text-[#616161]`} onClick={() => setIsEnglish(false)}>FR</span>
                                        <hr className={`h-1 ${!isEnglish && "bg-[#e81a3b]"}`} />
                                        {isValidationOn && dataForm.name.fr === "" && <span className="text-red-600 text-2xl absolute -top-0 -left-2">*</span>}
                                    </div>
                                </div>
                            </section>

                            <section className="flex w-[1400px]">
                                {/*======== ENGLISH------------------- */}
                                <section className={`w-[652px] transition-all ease-in-out duration-300 ${isEnglish ? "ml-0" : "-ml-[652px]"}`}>
                                    <div className="relative">
                                        <label className='block text-[14px] font-semibold mb-1'>{t("categManagement.englishCategory")}</label>
                                        <TextInputWithSearchIcon
                                            handleChange={(e) => setDataForm({ ...dataForm, name: { en: e.target.value, fr: dataForm.name.fr } })}
                                            placeholder={t("categManagement.placeHolderEnterCategoryEnglishName")}
                                            isToSearch={false}
                                            value={dataForm.name.en}
                                        />
                                        {isValidationOn && dataForm.name.en === "" && <span className="absolute -bottom-4 text-xs text-red-600 right-0">{t("reportManagement.addmodule.fieldRequired")}</span>}
                                    </div>
                                    <div className="mt-3">
                                        <h3 className="font-semibold mb-1 text-[14px]">{t("categManagement.englishDescription")}</h3>
                                        <textarea
                                            className='w-full h-[150px] border border-[#3333331f] rounded-[6px] p-3 resize-none outline-none'
                                            maxLength={500}
                                            onChange={(e) => setDataForm({ ...dataForm, description: { en: e.target.value, fr: dataForm.description.fr } })}
                                            placeholder={t("categManagement.placeHolderEnterCategoryEnglishDescription")}
                                            value={dataForm.description.en}
                                        />
                                    </div>
                                    <div className="text-end w-full">
                                        <span className="text-[#7d7b7b] text-xs text-end w-full" >{dataForm.description.en.length}/500</span>
                                    </div>
                                </section>
                                {/* ====== FRENCH------------------------ */}
                                <section className="w-[652px]">
                                    <div className="relative">
                                        <label className='block text-[14px] font-semibold mb-1'>{t("categManagement.frenchCategory")}</label>
                                        <TextInputWithSearchIcon
                                            handleChange={(e) => setDataForm({ ...dataForm, name: { en: dataForm.name.en, fr: e.target.value } })}
                                            placeholder={t("categManagement.placeHolderEnterCategoryFrenchName")}
                                            value={dataForm.name.fr}
                                            isToSearch={false}
                                        />
                                        {isValidationOn && dataForm.name.fr === "" && <span className="absolute -bottom-4 text-xs text-red-600 right-0">{t("reportManagement.addmodule.fieldRequired")}</span>}
                                    </div>
                                    <div className="mt-3">
                                        <h3 className="font-semibold mb-1 text-[14px]">{t("categManagement.frenchDescription")}</h3>
                                        <textarea
                                            className='w-full h-[150px] border border-[#3333331f] rounded-[6px] p-3 resize-none outline-none'
                                            maxLength={500}
                                            onChange={(e) => setDataForm({ ...dataForm, description: { en: dataForm.description.en, fr: e.target.value } })}
                                            placeholder={t("categManagement.placeHolderEnterCategoryFrenchDescription")}
                                            value={dataForm.description.fr}
                                        />
                                        <div className="text-end w-full">
                                            <span className="text-[#7d7b7b] text-xs text-end w-full" >{dataForm.description.fr.length}/500</span>
                                        </div>
                                    </div>
                                </section>
                            </section>
                        </div>
                    </section>
                </form>
                {contextHolder}
            </Modal >
        </>
    )
}

export default EditModule