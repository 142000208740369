import React, { Fragment } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import msalConfig from "./auth/configuration/authConfig";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { MsalAuthenticationResult, MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import "./i18n";
import { GlobalContextProvider } from "./contextAPI/globalContext";

const msalAuthenticationInsance = new PublicClientApplication(msalConfig);

const ErrorComponent: React.FC<MsalAuthenticationResult> = ({ error }) => {
  return (
    <Fragment>
      <div className="h4">
        An Error Occurred: {error && error.errorCode ? error.errorCode : "an unknown authorization error occurred"}
      </div>
    </Fragment>
  );
};

const Loading = () => {
  return (
    <Fragment>
      <div className="h4">Authentication in progress...</div>
    </Fragment>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <BrowserRouter>
    <MsalProvider instance={msalAuthenticationInsance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        errorComponent={ErrorComponent}
        loadingComponent={Loading}
      >
        <GlobalContextProvider>
          <App />
        </GlobalContextProvider>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  </BrowserRouter>
);
reportWebVitals();
