import React, { useContext } from 'react'
import { responseFetchAccessList, UserAccessInfo } from './index.type'
import { Tooltip } from 'antd'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { useTranslation } from 'react-i18next'
import { GlobalContext } from 'src/contextAPI/globalContext'

type UserAndGroupsTableColumnsType = {
    setListOfTable: (value: responseFetchAccessList) => void,
    listOfTable: responseFetchAccessList,
}

export function UserTableColumns({ setListOfTable, listOfTable }: UserAndGroupsTableColumnsType) {
    const { t, i18n } = useTranslation();
    const { oid, isAppAdmin } = useContext(GlobalContext)
    return [
        {
            title: t('reportManagement.userAndGroups.userName'),
            key: 'name',
            render: (record: any) => record?.user?.fullName || '',
            sorter: (a: any, b: any) => a.user?.fullName.localeCompare(b.user?.fullName),
        },
        {
            title: t('reportManagement.userAndGroups.email'),
            key: 'email',
            align: 'center' as 'center',
            render: (record: UserAccessInfo) => record?.user?.email || '',
            sorter: (a: any, b: any) => a.user?.email.localeCompare(b.user?.email)
        },
        {
            title: t('reportManagement.userAndGroups.admin'),
            key: 'admin',
            align: 'center' as 'center',
            width: "20%",
            sorter: (a: any, b: any) => a.isReportAdmin - b.isReportAdmin,
            defaultSortOrder: 'descend' as 'descend' | 'ascend' | undefined,
            render: (record: UserAccessInfo) => (
                <input
                    className={`userAssignTable checkbox ${!isAppAdmin && oid === record.entraId ? '!cursor-not-allowed' : 'cursor-pointer'}`}
                    type='checkbox'
                    onChange={() => setListOfTable({
                        ...listOfTable,
                        user: listOfTable.user.map((item: UserAccessInfo) =>
                            item.entraId === record.entraId ? { ...item, isReportAdmin: !item.isReportAdmin } : item
                        )
                    })}
                    checked={record.isReportAdmin}
                    disabled={!isAppAdmin && oid === record.entraId}

                />
            )
        },
        {
            title: t('reportManagement.userAndGroups.actions'),
            key: 'action',
            align: 'center' as 'center',
            render: (record: UserAccessInfo) => <div className='flex justify-center'>
                {
                    (<Tooltip placement="left" color='#4d4c4c' title={t('reportManagement.userAndGroups.removeAccess')}>
                        <RiDeleteBin6Line
                            className={`text-[#857f7f] hover:text-red-500 ${!isAppAdmin && oid === record.entraId ? '!cursor-not-allowed' : 'cursor-pointer'}`}
                            size={15}
                            onClick={() => {
                                if (oid === record.entraId && !isAppAdmin)
                                    return
                                const index = listOfTable.user.findIndex((item: UserAccessInfo) => item.entraId === record.entraId)
                                if (index === -1) return // if the user is not in the list
                               
                                const newListOfTable = [...listOfTable.user];
                                newListOfTable[index] = {
                                    ...newListOfTable[index],
                                    hasAccess: false
                                };
                                setListOfTable({ ...listOfTable, user: newListOfTable })
                            }}
                        />
                    </Tooltip>)
                }
            </div >
        },
    ]
}

export function GroupTableColumns({ setListOfTable, listOfTable }: UserAndGroupsTableColumnsType) {
    const { t, i18n } = useTranslation();
    return [
        {
            title: t('reportManagement.userAndGroups.groupName'),
            key: 'group',
            render: (record: UserAccessInfo) => record.groupName || '',
            sorter: (a: any, b: any) => a.groupName.localeCompare(b.groupName),
        },
        {
            title: t('reportManagement.userAndGroups.actions'),
            key: 'action',
            align: 'center' as 'center',
            render: (record: UserAccessInfo) => <div className='flex justify-center'>
                <Tooltip placement="left" color='#4d4c4c' title={t('reportManagement.userAndGroups.removeAccess')}>
                    <RiDeleteBin6Line
                        className='cursor-pointer text-[#857f7f] hover:text-red-500'
                        size={15}
                        onClick={() => {
                            const index = listOfTable.group.findIndex((item: UserAccessInfo) => item.entraId === record.entraId)
                            if (index === -1) return // if the user is not in the list]

                            const newListOfTable = [...listOfTable.group];
                            newListOfTable[index] = {
                                ...newListOfTable[index],
                                hasAccess: false
                            };
                            setListOfTable({ ...listOfTable, group: newListOfTable })
                        }}
                    />
                </Tooltip>
            </div >
        },
    ]
}
