import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { GlobalContext } from 'src/contextAPI/globalContext';
import { IoClose } from 'react-icons/io5';
import { PageInterface } from './index.type'
import { ReportManagementPageContext } from 'src/contextAPI/reportManagementPageContext';
import { useClickAway } from '@uidotdev/usehooks';
import { useReportManagePage } from 'src/hooks/useReportManagePage';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/input/Button';
import Label from 'src/components/Label';
import React, { useContext, useEffect, useState } from 'react'
import ReporAddModule from 'src/components/ReportAddModule';
import ReportFilter from 'src/components/ReportFilter';
import ReportManagementSearch from 'src/components/ReportManagementSearch';
import ReportTableManage from 'src/components/ReportTableManage';
import TextInputWithSearchIcon from 'src/components/input/TextInputWithSearchIcon';

function ReportManage({ setHeaderTitle }: PageInterface) {

  const { t, i18n } = useTranslation();
  useEffect(() => setHeaderTitle(t("reportManagement.t1")), [t])

  const {
    contextHolder,
    handleSearch,
    handleReset,
    fetchCategoryList,
    fetchReportList,
    searchInputValue,
    setSearchInputValue,
    callApiLoading,
    callApi,
    setShowResults,
    showResults,
    searchQuery,
    setSearchQuery,
    getReportsQuantity
  } = useReportManagePage()

  const { isAppAdmin } = useContext(GlobalContext)
  const { pagination, setReportsPerUser, reportsPerUser, filters } = useContext(ReportManagementPageContext)
  const ref: any = useClickAway(() => { setShowResults(false) });

  //To know how many reports are there in the list
  const getReportsQtty = async () => {
    let extraHeaders = {
      pagesize: pagination.pageSize,
      pageindex: pagination.pageIndex,
    }
    let res = await getReportsQuantity({ extraHeaders })
    setReportsPerUser(res)
  }

  // Effect to hide search results when pressing the escape key
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setShowResults(false)
        setSearchQuery("")
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  //to render the Category list on the page in the first load and when the language is changed
  useEffect(() => {
    fetchCategoryList();
  }, [i18n.language])

  //to render the report list on the page in the first load
  useEffect(() => {
    let extraHeaders = {
      pagesize: pagination.pageSize,
      pageindex: pagination.pageIndex,
    }
    fetchReportList({ extraquery: extraHeaders });
    getReportsQtty()
  }, [])

  const resetBtn = () => {
    handleReset()
    setShowResults(false)
  }

  //to clean the search input when the record is selected
  const handleSelectUser = () => {
    setSearchQuery("")
    setShowResults(false)
    setSearchInputValue("")
  }

  return (
    <main className="flex w-full pt-7">
      <div className='w-full px-11'>
        <header className='flex justify-between items-center mb-6 relative'>
          {
            filters.isFilterActive &&
            <aside className='absolute left-2 -bottom-1 flex items-center gap-1'>
              <AiOutlineExclamationCircle size={15} color='#e81a3b' />
              <span className='text-xs text-[#7e7878]'>{t("reportManagement.filters.activeInfo")}</span>
            </aside>
          }
          <section className="flex sm:flex-col md:flex-row items-center gap-3 ">
            {/* ---- Input Search------ */}
            <div className='px-1 py-4 ' ref={ref}>
              <div className='flex min-w-[400px] relative' >
                <TextInputWithSearchIcon
                  handleChange={handleSearch}
                  placeholder={t("reportManagement.searchPlaceHolder")}
                  value={searchInputValue}
                />
                <aside>
                  {searchInputValue.length > 0 &&
                    <Button btnType="main" className="absolute right-2 top-[13px] rounded-full" icon={<IoClose size={12} />} handleClick={resetBtn} />
                  }
                </aside>
              </div>
              {showResults && <ReportManagementSearch searchQuery={searchQuery} handleSelectUser={handleSelectUser} />}
            </div>
            <ReportFilter fetchReportList={fetchReportList} />
          </section>
          <section className='flex sm:justify-between justify-end items-center md:gap-20'>
            <Label text={t("reportManagement.reports")} qty={`${reportsPerUser}`} />
            {isAppAdmin && <ReporAddModule />}
          </section>
        </header>
        <ReportTableManage callApi={callApi} callApiLoading={callApiLoading} />
      </div>
      {contextHolder}
    </main>
  )
}
export default ReportManage