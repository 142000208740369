import Card from "../../../Card/Card";
import { ContentSectionInterface } from "./ContentSection.type";

const ContentSection = ({
  children,
  className = "",
  hasControlActions = false,
  title,
  action,
  actionClassName = "",
  actionLabel,
}: ContentSectionInterface) => (
  <div className={`flex flex-col px-4 pb-4 ${className}`}>
    <div className="flex justify-between items-center">
      {title && <span className="font-bold text-lg pb-4">{title}</span>}
      {hasControlActions && (
        <button className={actionClassName} onClick={action}>
          {actionLabel}
        </button>
      )}
    </div>

    <Card className="h-full min-h-[164px]">{children}</Card>
  </div>
);

export default ContentSection;
