import { Modal } from "antd";
import { useIdleTimer } from "react-idle-timer";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import React, { useRef, useState, useEffect, useContext } from "react";
import { FcAlarmClock } from "react-icons/fc";
import Button from "../input/Button";
import "./style.css";
import { GlobalContext } from "src/contextAPI/globalContext";

function IdleTimerContainer() {
    const TIMETOTAKEACTION = 120; // Seconds --> Time to take action before the session is closed
    const INACTIVITYSESSION = 8; // Minutes --> Time of inactivity to be considered idle

    const { intervalRef, sessionTimeoutRef, modalIsOpen, setIsModalOpen, setIsIdle, isIdle } = useContext(GlobalContext);
    const [countdown, setCountdown] = useState(TIMETOTAKEACTION);
    // const [modalIsOpen, setIsModalOpen] = useState(false);
    // const [isIdle, setIsIdle] = useState(false); // state to check if the user is idle
    const { instance } = useMsal();
    const { t } = useTranslation();

    // const intervalRef = useRef<NodeJS.Timeout | null>(null);
    // const sessionTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    // Function to format the countdown time
    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    // Function to call when user is idle
    const onIdle = () => {
        // check if the user is already idle
        if (isIdle) return;
        setIsIdle(true); // set the user as idle
        setIsModalOpen(true);
        setCountdown(TIMETOTAKEACTION); // Reset the countdown
        startCountdown(); // Start the countdown
        sessionTimeoutRef.current = setTimeout(logOut, TIMETOTAKEACTION * 1000); // Convert seconds to milliseconds
    };

    // Function to start the countdown
    const startCountdown = () => {
        stopCountdown(); // Stop any existing interval before starting a new one
        intervalRef.current = setInterval(() => {
            setCountdown((prevCountdown) => {
                if (prevCountdown <= 1) {
                    stopCountdown();
                    return 0; // Avoid negative numbers
                }
                return prevCountdown - 1;
            });
        }, 1000); // Decrease every second
    };

    // Function to stop the countdown
    const stopCountdown = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null; // Clear the reference to avoid memory leaks
        }
    };

    // Set up the idle timer
    useIdleTimer({
        timeout: INACTIVITYSESSION * 60 * 1000, // Time of inactivity to be considered idle in milliseconds
        onIdle: onIdle, // function to call when user is idle
        debounce: 500, // Opcional: To avoid triggering the event multiple times
    });

    // Function to call when user is logged out
    const logOut = async () => {
        await instance.logoutRedirect();
        setIsModalOpen(false);
        setIsIdle(false); // Reset the idle state
        stopCountdown(); // stop the countdown
        clearTimeout(sessionTimeoutRef.current as NodeJS.Timeout);
    };

    // Function to call when user is active
    const stayActive = () => {
        setIsModalOpen(false);
        setIsIdle(false); // Reset the idle state
        stopCountdown(); // Detiene el contador
        clearTimeout(sessionTimeoutRef.current as NodeJS.Timeout);
        console.log("User is Active");
    };

    useEffect(() => {
        // Clear the session timeout on component unmount
        return () => {
            stopCountdown();
            clearTimeout(sessionTimeoutRef.current as NodeJS.Timeout);
        };
    }, []);

    return (
        <div>
            {modalIsOpen && (
                <Modal
                    className='timeout-modal flex flex-col items-center justify-center proximaNova'
                    title={t("sesionExpired_title")}
                    open={modalIsOpen}
                    onCancel={async () => { setIsModalOpen(false); await instance.logoutRedirect(); }}
                    closable={true}
                    maskClosable={false}
                    footer={
                        <Button
                            className="px-6 py-2 rounded-[5px]"
                            handleClick={stayActive}
                            btnType="main"
                            text={t("sessionExpiredButton")}
                        />
                    }
                >
                    <div className="flex flex-col justify-center items-center gap-3">
                        <FcAlarmClock size={30} />
                        <h2>{t("sessionExpiredMessage")}</h2>
                        <p className="font-semibold">{formatTime(countdown)}</p>
                    </div>
                </Modal>
            )}
        </div>
    );
}

export default IdleTimerContainer;
