import "./style.css"
import { IoFilter } from 'react-icons/io5';
import { ReportFilterProps } from './index.type';
import { ReportManagementPageContext } from 'src/contextAPI/reportManagementPageContext';
import { useClickOut } from 'src/hooks/useClickOut';
import { useTranslation } from 'react-i18next';
import Button from '../input/Button';
import Dropdown from '../FilterSections/Dropdown';
import RadioButtons from '../FilterSections/RadioButtons';
import React, { useContext, useEffect, useState } from 'react'
import { useReportManagePage } from "src/hooks/useReportManagePage";


function ReportFilter({ fetchReportList }: ReportFilterProps) {
    const { t, i18n } = useTranslation();
    //-------constants values
    const initialFilterValue = {
        dropDownValue: { id: "", name: t("reportManagement.filters.all") },
        permissionValue: { id: "", name: "All" },
        isFilterActive: false
    }
    const OPTIONS_PERMISSION = [
        { name: t("reportManagement.filters.all"), id: "" },
        { name: t("reportManagement.filters.public"), id: "1" },
        { name: t("reportManagement.filters.private"), id: "2" }
    ]

    //-------states
    const { isOpen, setIsOpen, ref } = useClickOut();
    const { filters, setFilters, categories, pagination } = useContext(ReportManagementPageContext)
    const { handleReset } = useReportManagePage()

    //-------functions
    const handleApply = () => {
        let extraquery = {
            pagesize: pagination.pageSize,
            pageindex: pagination.pageIndex,
            categoryid: filters.dropDownValue.id === "" ? undefined : filters.dropDownValue.id,
            isPrivate: filters.permissionValue.id === "1" ? false : filters.permissionValue.id === "2" ? true : undefined
        }
        fetchReportList({ extraquery });
        if (filters.dropDownValue.id !== "" || filters.permissionValue.id !== "") {
            setFilters({ ...filters, isFilterActive: true })
        } else {
            setFilters({ ...filters, isFilterActive: false })
        }
    }
    const dropdownHandler = (option: any) => {
        setFilters({ ...filters, dropDownValue: option })
    }

    const radioButtonsHandler = (option: any) => {
        setFilters({ ...filters, permissionValue: option })
    }

    //---to reset the filter values when the language is changed
    useEffect(() => {
        setFilters(initialFilterValue)
    }, [i18n.language])

    return (
        <div className='h-10 relative'>
            <button
                disabled={isOpen}
                onClick={() => setIsOpen(!isOpen)}
                className='relative flex items-center gap-2 border-[0.2px] border-[#00000026] h-full pl-3 pr-5 text-[#000000a2] cursor-pointer rounded-[4px] hover:bg-[#3333330a] hover:text-[#333] hover:border-[#bbbbbb]'
            >
                <IoFilter color="#715a5aa2" />
                <span className='text-[13.5px]'>{t("userManagement.t4")} </span>
                <div className={`absolute w-[6px] h-[6px] rounded-full top-[7px] right-[6px] ${filters.isFilterActive ? "led-red" : "border border-gray-400"}`}></div>
            </button>
            {isOpen &&
                <div ref={ref} className='absolute top-12 left-0 w-[450px] bg-white border-[0.2px] border-[#00000026] rounded-[6px] z-50 filter-container'>
                    <div>

                    </div>
                    <div className='px-4 py-7 text-[#000000a8] flex flex-col'>
                        <section>
                            <RadioButtons options={OPTIONS_PERMISSION} radioButtonHandler={radioButtonsHandler} title='Access' value={filters.permissionValue.id} />
                        </section>
                        <hr className="my-4" />
                        <section>
                            <Dropdown title={t("reportManagement.filters.category")} dropdownHandler={dropdownHandler} options={categories} value={filters.dropDownValue} />
                        </section>
                        <ul className='flex justify-end align-middle text-[15px] gap-3 mt-6'>
                            <li>
                                <Button btnType="secondary" text={t('reportManagement.filters.reset')} className="h-[35px] rounded-[5px] px-7" handleClick={() => { setFilters(initialFilterValue); handleReset(); }} />
                            </li>
                            <li>
                                <Button btnType="main" text={t("userManagement.t6")} className="h-[35px] rounded-[5px] px-7" handleClick={handleApply} />
                            </li>
                        </ul>
                    </div>
                </div>
            }
        </div>
    )
}
export default ReportFilter