import { useDebounce } from 'src/hooks/useDebounce';
import { useNotification } from 'src/hooks/useNotification';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react'
import useCallApi from 'src/hooks/useCallApi';

const CategorySearch = ({ searchQuery, handleSelect }: any) => {
    const { t, i18n } = useTranslation();
    const { callApi, callApiLoading, data, setData, isError } = useCallApi();
    const debouncedSearch = useDebounce(searchQuery, 500);
    const { notification, contextHolder } = useNotification();

    const handleSearch = async () => {
        let res = await callApi({ endpoint: "CategorySearch", query: debouncedSearch }); //API call to get Category list
        (res?.data?.length > 0) && setData(res.data);
    };

    //Effect to handle search
    useEffect(() => {
        if (debouncedSearch === "") {
            setData([]);
            return;
        }
        handleSearch();
    }, [debouncedSearch]);

    //Effect to handle error
    useEffect(() => {
        if (isError.status) {
            notification({ content: { name: t("t2"), message: isError.message }, type: 'error' })
        }
    }, [isError]);

    return (
        <>
            {debouncedSearch !== "" && (
                <ul className="absolute bg-[#fbfbfb] w-full max-h-96 overflow-y-auto scroll-smooth scroll-thin  z-10 general-shadow m-0 p-0">
                    {
                        callApiLoading
                            ? <li className="w-full py-3 pl-3 pr-2 cursor-pointer text-sm">{t("loading")}</li>
                            : <>
                                {data?.length === 0 && searchQuery !== "" && (
                                    <li className="w-full py-3 pl-3 pr-2 cursor-pointer text-sm">{t("no_Matching_Results")}</li>
                                )}
                                {data.map((item: any) => (
                                    <li key={item.id}
                                        className="w-full py-3 pl-3 pr-2 cursor-pointer hover:text-[#ed1a3b] hover:bg-[#fff2ee] text-sm"
                                        onClick={() => handleSelect({ id: item.id, name: i18n.language === "fr" ? item.categoryFr : item.categoryEn })}
                                    >
                                        {i18n.language === "fr" ? item.categoryFr : item.categoryEn}
                                    </li>
                                ))}
                            </>
                    }
                </ul>
            )}
            {contextHolder}
        </>
    )
}
export default CategorySearch