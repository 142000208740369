import { createContext, ReactNode, useState } from 'react';
import { UserInterface } from 'src/components/UserTableManage/index.type';
import { UserFiltersType, UserManagementPageContextInterface } from './contextApi.type';
import { useConstant } from 'src/hooks/useConstant';


export const UserManagementPageContext = createContext<UserManagementPageContextInterface>({
    userList: [],
    setUserList: () => { },
    pagination: {
        totalPages: 1,
        pageIndex: 1,
        pageSize: 10,
    },
    setPagination: () => { },
    totalAccount: 0,
    setTotalAccount: () => { },
    filters: {
        isFilterActive: false,
        permissions: {
            id: "",
            name: ""
        },
        businessUnits: {
            id: "",
            name: ""
        },
        qty: {
            min: 0,
            max: 0
        },
        status: {
            id: "",
            name: ""
        }
    },
    setFilters: () => { },
    totalUsers: 0,
    setTotalUsers: () => { },
    businessUnits: [],
    setBusinessUnits: () => { }
});

export function UserManagementPageContextProvider({ children }: { children: ReactNode }) {
    const [userList, setUserList] = useState<UserInterface[] | []>([]);
    const { USER_MANAGE_INITIALFILTERVALUES } = useConstant()
    const [totalAccount, setTotalAccount] = useState(0);
    const [generalTotalUsers, setGeneralTotalUsers] = useState(0);
    const [businessUnits, setBusinessUnits] = useState<any[]>([])
    const [pagination, setPagination] = useState({
        totalPages: 1,
        pageIndex: 1,
        pageSize: 10,
    })
    const [filters, setFilters] = useState<UserFiltersType>(USER_MANAGE_INITIALFILTERVALUES);

    return (
        <UserManagementPageContext.Provider value={{
            userList,
            setUserList,
            pagination,
            setPagination,
            totalAccount,
            setTotalAccount,
            filters,
            setFilters,
            totalUsers: generalTotalUsers,
            setTotalUsers: setGeneralTotalUsers,
            businessUnits,
            setBusinessUnits
        }}
        >
            {children}
        </UserManagementPageContext.Provider>
    );
}
