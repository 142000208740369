import { AccordionSearchReportsInterface } from '../layout/navigation/Accordion/AccordionSection.types'
import { MenuItemInterface } from '../layout/navigation/Accordion/Accordion.types'
import { ReportPageContext } from 'src/contextAPI/reportPageContext'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import { useNotification } from 'src/hooks/useNotification'
import { useTranslation } from 'react-i18next'
import React, { useContext, useEffect } from 'react'
import useCallApi from 'src/hooks/useCallApi'
import Tooltip from 'antd/es/tooltip'

const FavoriteList = () => {
    const { setFavoriteItems, favoriteItems, setReportId, setReportDescription, setReportName, setWorkspaceGuid, setSelected, selected } = useContext(ReportPageContext)
    const { callApi } = useCallApi()
    const { t, i18n } = useTranslation();
    const { contextHolder, notification } = useNotification()
    const navigate = useNavigate();

    const handleDelete = async (id: string) => {
        let body = {
            reportId: id,
            isFavorite: false
        }
        let res = await callApi({ endpoint: 'Favorites', body: body, method: 'POST' })
        //If there is an error, show a notification
        if (res.isError) {
            notification({
                type: 'error',
                content: {
                    name: t("t2"),
                    message: `${res.status || res} ${res.message || res.statusText}`
                }
            })
            console.log("error", res)
            return
        }
        //If the request is successful, remove the item from the favorite list
        setFavoriteItems((prevItems: MenuItemInterface[]) => {
            notification({
                type: 'success',
                content: {
                    name: t("success"),
                    message: t('reports.favorite.removed')
                }
            })
            return prevItems.filter(favoriteItem => favoriteItem.id !== id)  //  delete if it is not marked as favorite
        })
    }
    const handleLoadReport = async (item: AccordionSearchReportsInterface) => {
        let description = {
            en: item.descriptionEn,
            fr: item.descriptionFr
        }
        let name = {
            en: item.nameEn,
            fr: item.nameFr
        }
        setReportId(item.id)
        setReportDescription(description)
        setReportName(name)
        setWorkspaceGuid(item.workSpaceID)
        navigate(`/reports?reportId=${item.id}`)
        setSelected(item.nameEn)
    }

    return (
        <ul>{
            favoriteItems && favoriteItems.map((item: any) => {
                return (
                    <li
                        key={item.id}
                        className={`${selected !== item.nameEn && 'hover:bg-[#fff2ee] text-bdo-text-01-charcoal'} pl-8 w-full pr-4  cursor-pointer py-2 text-sm min-h-14 flex justify-between items-center relative ${selected === item.nameEn && 'bg-[#ed1a3b] text-[#fff]'}`}
                    >
                        <h3 onClick={() => handleLoadReport(item)} className={` ${selected !== item.nameEn && 'hover:text-red-600 '}`}>
                            {i18n.language === 'fr' ? item.nameFr || item.label.fr : item.nameEn || item.label.en}
                        </h3>
                        <Tooltip placement='left' title={t('reports.favorite.tootipRemove')} color='#4d4c4c'>
                            <RiDeleteBin6Line
                                size={15}
                                className={`min-w-[15px]  ${selected !== item.nameEn && 'hover:text-[#ed1a3b] text-[#8f8e8e]'} ${selected === item.nameEn && 'text-[#fff]'}`}
                                onClick={() => handleDelete(item.id)}
                            />
                        </Tooltip>

                    </li>
                )
            })
        }
            {contextHolder}
        </ul>
    )
}

export default FavoriteList