import { useTranslation } from 'react-i18next';
import CategorySearch from './CategorySearch';
import React from 'react'
import TextInputWithSearchIcon from '../input/TextInputWithSearchIcon';
import Button from '../input/Button';
import { IoClose } from 'react-icons/io5';

interface CategoryAutocompleteProps {
    setSearchInputValue: (value: string) => void;
    searchInputValue: string;
    setSearchQuery: (value: string) => void;
    searchQuery: string;
    handleSelect: (category: { id: string, name: string }) => void;
    showResults: boolean;
    setShowResults: (value: boolean) => void;
}

const CategoryAutocomplete = ({ setSearchInputValue, searchInputValue, setSearchQuery, searchQuery, handleSelect, showResults, setShowResults }: CategoryAutocompleteProps) => {
    const { t } = useTranslation();

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInputValue(event.target.value);
        setSearchQuery(event.target.value);
        setShowResults(true)
    }

    return (
        <div className="relative">
            <label className='block text-[14px] font-semibold mb-1'>{t("reportManagement.addmodule.category")}</label>
            <div className='flex relative'>
                <TextInputWithSearchIcon
                    handleChange={(event) => handleChange(event)}
                    placeholder={t("reportManagement.addmodule.categoryPlaceholder")}
                    value={searchInputValue}
                />
                <aside>
                    {searchInputValue.length > 0 &&
                    <Button
                        btnType="main"
                        className="absolute right-2 top-[13px] rounded-full" 
                        icon={<IoClose size={12} />}
                        handleClick={() => {
                            setSearchInputValue("");
                            setSearchQuery("");
                            setShowResults(false)
                        }} />
                    }
                </aside>
            </div>
            {
                showResults && <CategorySearch searchQuery={searchQuery} handleSelect={handleSelect} />
            }
        </div>
    )
}

export default CategoryAutocomplete