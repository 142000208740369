import React, { useContext, useState } from 'react'
import "./style.css"
import Icon from '../Icon/Icon'
import { IoIosStar } from 'react-icons/io'
import FavoriteList from './FavoriteList'
import { ReportPageContext } from 'src/contextAPI/reportPageContext'
import { useTranslation } from 'react-i18next'

const Favorites = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { favoriteItems } = useContext(ReportPageContext)
  const { t } = useTranslation();

  return (
    <div>
      {
        favoriteItems.length > 0 &&
        <section className="flex justify-between pl-4 h-14 py-2 items-center cursor-pointer hover:text-[#ea2948]" onClick={() => setIsOpen(!isOpen)}>
          <div className='flex items-center justify-between w-full pr-4'>
            <aside className='flex items-center gap-1'>
              <span className='text-sm proximaNova font-semibold'>{t('reports.favorite.favorites')}</span>
              <IoIosStar size={18} style={{ fill: `#ffc107`, minWidth: '15px' }} />
            </aside>
            <aside className='bg-[#ED1A3B] rounded-full flex items-center justify-center w-5 h-5'>
              <span className="text-[13px] text-white font-normal">{favoriteItems.length}</span>
            </aside>
          </div>
          <Icon name="chevron_right" className={`text-[#ea2948] ${isOpen && "rotate-90 mr-1"}`} />
        </section>
      }
      {isOpen && <FavoriteList />}
    </div>
  )
}

export default Favorites