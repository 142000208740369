import React, { useEffect, useState } from 'react'
import "./style.css";
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';

const SwitchLenguage = () => {

    const { t, i18n } = useTranslation();
    const isEnglish = navigator.language.slice(0, 2) === "en";
    const [lng, setLng] = useState(isEnglish);


    useEffect(() => {
        const lngSelected = lng ? 'en' : 'fr';
        i18n.changeLanguage(lngSelected);
    }, [lng])

    return (
        <div className="switch-toggle relative w-[70px] h-auto" >
            <input type="checkbox" id="language-toggle" className="toggle-checkbox" checked={lng} onChange={() => setLng(!lng)} />
            <Tooltip placement="bottom" color='#4d4c4c' title={t("tooltipLenguage")}>
                <label htmlFor="language-toggle" className="toggle-label cursor-pointer block overflow-hidden relative rounded-[50px] w-full h-full">
                    <span className="toggle-inner flex justify-between items-center w-[200%] hover:font-semibold">
                        <span className="toggle-text fr text-center  text-[#333]">English</span>
                        <span className="toggle-text en text-center  text-[#333]">Français</span>
                    </span>
                    <span className="toggle-switch absolute top-[3px] left-[5px] w-[15px] h-[15px] rounded-full z-[2]"></span>
                </label>
            </Tooltip>
        </div>
    )
}

export default SwitchLenguage