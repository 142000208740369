import "./style.css"
import { CategoryManagementPageContext } from "src/contextAPI/categoryManagementPageContext"
import { Collapse } from 'antd'
import { useNotification } from 'src/hooks/useNotification'
import { useTranslation } from 'react-i18next'
import React, { useContext, useEffect } from 'react'
import useCategoryLogic from "src/hooks/useCategoryLogic"

function CategoryTableManage() {
  const { notification, contextHolder } = useNotification();
  const { t, i18n } = useTranslation();
  const { fetchCategoryList } = useCategoryLogic()
  const { categoryList, isCollapsible, setApiResponseCategoryList } = useContext(CategoryManagementPageContext)

  useEffect(() => {
    fetchCategoryList({ notification, setApiResponseCategoryList })
  }, [t])

  return (
    <>
      <Collapse
        items={categoryList}
        collapsible={isCollapsible ? 'header' : 'disabled'}
        size='middle'
        className='scroll-auto overflow-auto rounded-none'
        style={{ maxHeight: "calc(100vh - 250px)" }}
        expandIconPosition='end'
        accordion
      />
      {contextHolder}
    </>
  )
}
export default CategoryTableManage