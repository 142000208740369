import { CircleLoader } from '../Loader'
import { FaPlus, FaUserSlash, FaUsersSlash } from 'react-icons/fa'
import { GlobalContext } from 'src/contextAPI/globalContext'
import { GroupTableColumns, UserTableColumns } from './UserAndGroupsTableColumns'
import { Table } from 'antd'
import { useNotification } from 'src/hooks/useNotification'
import { UserAccessInfo, UserAndGroupAssignmentTableType } from './index.type'
import { useTranslation } from 'react-i18next'
import Button from '../input/Button'
import React, { useContext, useEffect, useState } from 'react'
import TextInputWithSearchIcon from '../input/TextInputWithSearchIcon'
import useCallApi from 'src/hooks/useCallApi'
import UserAndGroupSearchResult from './UserAndGroupSearchResult'
import { IoIosSearch } from 'react-icons/io'

function UserAndGroupAssignmentTable(
    {
        modalInfo,
        listOfTable,
        setListOfTable,
        searchUserInputValue,
        setSearchUserInputValue,
        searchQuery,
        setSearchQuery,
        showResults,
        setShowResults,
        callApiLoading,
        handleSearch,
        report
    }: UserAndGroupAssignmentTableType) {
    const [checkBoxValue] = useState(false)
    const { isAppAdmin, oid } = useContext(GlobalContext)
    const { t } = useTranslation();
    const { notification, contextHolder } = useNotification()
    const { callApi, isError } = useCallApi()

    const handleAddUser = async () => {
        let typed_Email = searchUserInputValue.user.trim();
        setSearchUserInputValue({ ...searchUserInputValue, user: typed_Email });

        //====verify if it is right email structure
        let emailRegex = /^[a-zA-Z0-9._%+-]+@bdo\.ca$/;
        if (!emailRegex.test(searchUserInputValue.user)) {
            notification({ content: { name: t("t2"), message: t('userManagement.addUser.invalidEmail') }, type: 'error' })
            return
        }
        //===verify if the user selected is already in the temporary list and if it was added before just change the hasAccess property to true
        let userExist = listOfTable.user.some((user: any) => user.user.email.toLowerCase() === searchUserInputValue.user.toLowerCase())
        let userExistIndex = listOfTable.user.findIndex((user: any) => user.user.email.toLowerCase() === searchUserInputValue.user.toLowerCase())
        if (userExist) {
            if (listOfTable.user[userExistIndex].hasAccess) {
                notification({ content: { name: t("t2"), message: t('userManagement.addUser.emailAlreadyInTheList') }, type: 'error' })
            }
            setListOfTable(
                {
                    ...listOfTable,
                    user:
                        [...listOfTable.user.slice(0, userExistIndex),
                        { ...listOfTable.user[userExistIndex], hasAccess: true },
                        ...listOfTable.user.slice(userExistIndex + 1)
                        ]
                })
            setSearchUserInputValue({ ...searchUserInputValue, user: "" })
            return
        }

        let query = `userObjectId=${oid}&email=${searchUserInputValue.user}`;
        let res = await callApi({ endpoint: "SearchUserForAccess", query: query })

        // //---Validate if the user exists
        if (res.status === 404) {
            console.log("Validate if the user exists")
            notification({ content: { name: t("t2"), message: `${res.status === 404 ? t('userManagement.addUser.userNotFound') : res.statusText || isError.message}` }, type: 'error' })
            return
        }

        // -------check if the user is assigned to PBI Service when the Report is Auth method User Context
        if (`${report.authMethod}` === '1') {
            let queryIsAssigned = `powerBIReportId=${report.powerBIReportId}&entraId=${res?.userObjectId}&accessType=1`
            let isAssigned = await callApi({ endpoint: "IsAssignedToPbiService", query: queryIsAssigned }) //--> API TO CALL TO CHECK IF THE USER IS ASSIGNED TO PBI SERVICE
            if (!isAssigned) {
                notification({ content: { name: t("t2"), message: `${t("reportManagement.userAndGroups.userMustBeAssignedFirst")} : ${res.email}` }, type: 'error' })
                return
            }
        }

        let newUser: UserAccessInfo = {
            accessType: 1,
            entraId: res?.userObjectId,
            groupName: null,
            email: res?.email,
            hasAccess: true,
            isReportAdmin: checkBoxValue || false, //===> 
            user: res
        }
        setListOfTable({ ...listOfTable, user: [...listOfTable.user, newUser] })
        setSearchUserInputValue({ ...searchUserInputValue, user: "" })
    }

    return (
        <div className='w-full overflow-hidden min-h-[500px] assignment-user-group'>
            <div className="flex w-[1800px]">
                <section className={`px-1 w-[852px] flex flex-col gap-5 transition-all ease-in-out duration-300 ${modalInfo.title.id === 1 ? "ml-0" : "-ml-[852px]"}`}>
                    <div className='w-full flex items-center gap-6' onKeyDown={e => { e.key === "Enter" && handleAddUser() }}>
                        <div className='relative w-[520px]'>
                            <TextInputWithSearchIcon
                                handleChange={(event) => handleSearch({ event, assignmentType: 1 })}
                                placeholder={t('reportManagement.userAndGroups.placeholderSearch')}
                                value={searchUserInputValue.user}
                            />
                            {/* {showResults.user &&
                                <UserAndGroupSearchResult
                                    searchQuery={searchQuery.user}
                                    setSearchQuery={setSearchQuery}
                                    assignmentType={1}  //--> 1 for user and 2 for group
                                    setListOfTable={setListOfTable}
                                    checkBoxValue={checkBoxValue}
                                    setShowResults={setShowResults}
                                    listOfTable={listOfTable}
                                    report={report}
                                    setSearchUserInputValue={setSearchUserInputValue}
                                />
                            } */}
                        </div>
                        <Button
                            text={`${t('userManagement.addUser.search')}`}
                            icon={<IoIosSearch />}
                            btnType='main'
                            handleClick={handleAddUser}
                            className='px-10 h-full'
                        />
                    </div>
                    <h2 className='font-semibold'>{t('reportManagement.userAndGroups.listUserTitle')}</h2>
                    <Table
                        columns={UserTableColumns({ setListOfTable, listOfTable })}
                        loading={{ indicator: <div><CircleLoader /></div>, spinning: callApiLoading }}
                        dataSource={listOfTable.user.filter((user: UserAccessInfo) => user.hasAccess)}
                        className='w-full'
                        rowKey={(record: UserAccessInfo) => record.user?.email || record.entraId}
                        showSorterTooltip={false}
                        pagination={false}
                        scroll={{ y: 350 }}
                        locale={{
                            emptyText() {
                                return <div className='flex justify-center items-center flex-col h-[250px]'><FaUserSlash size={40} /><h2>{t('reportManagement.userAndGroups.noUserAssigned')}</h2></div>
                            },
                        }}
                    />
                </section>
                {
                    isAppAdmin &&
                    <section className='px-1 w-[852px] flex flex-col gap-5'>
                        <div className='relative w-[520px]'>
                            <TextInputWithSearchIcon
                                handleChange={(event) => handleSearch({ event, assignmentType: 2 })}
                                placeholder={t('reportManagement.userAndGroups.placeholderSearchGroup')}
                                value={searchUserInputValue.group}
                            />
                            {showResults.group &&
                                <UserAndGroupSearchResult
                                    searchQuery={searchQuery.group}
                                    setSearchQuery={setSearchQuery}
                                    assignmentType={2}  //--> 1 for user and 2 for group
                                    setListOfTable={setListOfTable}
                                    setShowResults={setShowResults}
                                    listOfTable={listOfTable}
                                    report={report}
                                    setSearchUserInputValue={setSearchUserInputValue}
                                />
                            }
                        </div>
                        <h2 className='font-semibold'>{t('reportManagement.userAndGroups.listGroupTitle')}</h2>
                        <Table
                            columns={GroupTableColumns({ setListOfTable, listOfTable })}
                            loading={{ indicator: <div><CircleLoader /></div>, spinning: callApiLoading }}
                            dataSource={listOfTable.group.filter((group: UserAccessInfo) => group.hasAccess)}
                            className='w-full '
                            rowKey={(record: UserAccessInfo) => record.entraId}
                            showSorterTooltip={false}
                            pagination={false}
                            scroll={{ x: 'max-content', y: 350 }}
                            locale={{
                                emptyText() {
                                    return <div className='flex justify-center items-center flex-col h-[250px]'><FaUsersSlash size={40} /><h2>{t('reportManagement.userAndGroups.noGroupAssigned')}</h2></div>
                                },
                            }}
                        />
                    </section>
                }
            </div>
            {contextHolder}
        </div>
    )
}

export default UserAndGroupAssignmentTable