import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from "./i18n/en"
import fr from "./i18n/fr"

const resources = {
    en,
    fr
};

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        debug: true,
        fallbackLng: ["en","fr"],
        resources,
        defaultNS: "translation",
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        react: {
            bindI18n: "languageChanged",
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
            useSuspense: true,
        }
    });

export default i18n;
