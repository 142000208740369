import { useTranslation } from "react-i18next";
import { Access, Actions } from "./CustomColumns";
import { ReportType, UserGroupCountInterface } from "./index.type";
import { Tooltip } from "antd";

export function Columns(): any {
    const { t } = useTranslation();

    return [
        {
            title: `${t("reportManagement.table.titleName")}`,
            dataIndex: `${t("reportManagement.table.name")}`,
            key: "name",
            ellipsis: { showTitle: false },
            width: "20%",
            sorter: () => { },
            render: (text: string) => (
                <Tooltip placement="top" title={text} color='#4d4c4c'>
                    {text}
                </Tooltip>
            ),
        },

        //Category
        {
            title: `${t("reportManagement.table.titleCategory")}`,
            dataIndex: `${t("reportManagement.table.category")}`,
            key: "category",
            ellipsis: { showTitle: false },
            width: "20%",
            sorter: () => { },
            render: (text: string) => (
                <Tooltip placement="top" title={text} color='#4d4c4c'>
                    {text}
                </Tooltip>
            ),
        },
        //Description
        {
            title: `${t("reportManagement.table.titleDescription")}`,
            dataIndex: `${t("reportManagement.table.description")}`,
            key: "description",
            width: "25%",
            ellipsis: { showTitle: false },
            sorter: () => { },
            render: (text: string) => (
                <Tooltip placement="left" title={text} color='#4d4c4c'>
                    {text}
                </Tooltip>
            ),
        },
        //report Count
        {
            title: `${t("reportManagement.table.count")}`,
            dataIndex: "userCount",
            key: "userCount",
            align: "center" as 'center',
            ellipsis: { showTitle: false },
            sorter: () => { },
        },
        //Access
        {
            title: `${t("reportManagement.table.titleAccess")}`,
            dataIndex: "isPublic",
            key: "isPublic",
            align: "center" as 'center',
            ellipsis: { showTitle: false },
            render: (_: any, report: ReportType) => <Access report={report} />,
            sorter: () => { },
        },
        //Added On
        {
            title: `${t("reportManagement.table.addedOn")}`,
            dataIndex: "dateAdded",
            key: "dateAdded",
            align: "center" as 'center',
            ellipsis: { showTitle: false },
            render: (_: any, report: ReportType) => report?.dateAdded?.split("T")[0],
            sorter: () => { },
        },
        //Actions
        {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            align: "center" as 'center',
            render: (_: any, record: ReportType) => <Actions report={record} />,
        },
    ];
}