import React, { useContext, useEffect } from 'react'
import { ReportManagementPageContext } from 'src/contextAPI/reportManagementPageContext'
import useCallApi from 'src/hooks/useCallApi'
import { useDebounce } from 'src/hooks/useDebounce'
import { useNotification } from 'src/hooks/useNotification'
import { fetchReportListType } from 'src/pages/index.type'
import { ReportType } from '../ReportTableManage/index.type'
import { useTranslation } from 'react-i18next'

interface ReportManagementSearchProps {
  searchQuery: string,
  handleSelectUser: () => void
}

function ReportManagementSearch({ searchQuery, handleSelectUser }: ReportManagementSearchProps) {
  const { t, i18n } = useTranslation();
  const { callApi, callApiLoading, data, setData, isError } = useCallApi();
  const { filters, setReportList } = useContext(ReportManagementPageContext)
  const debouncedSearch = useDebounce(searchQuery, 500);
  const { notification, contextHolder } = useNotification();

  const extraHeaderMaker = () => {
    const { isFilterActive, dropDownValue, permissionValue } = filters;

    return {
      pagesize: 50,
      pageindex: 1,
      searchFilter: debouncedSearch,
      categoryid: isFilterActive && dropDownValue.id ? dropDownValue.id : undefined,
      isPrivate: isFilterActive && permissionValue.id
        ? permissionValue.id === "2"
        : permissionValue.id === "1"
          ? false
          : undefined
    };
  };

  const fetchReportList = async () => {
    let extraHeaders = extraHeaderMaker()
    let res = await callApi({ endpoint: "ReportList", extraHeaders });
    (!res.isError) && setData(res.data)
  }

  //Effect to handle search
  useEffect(() => {
    if (debouncedSearch === "") {
      setData([]);
      return;
    }
    //I need to get the 50 first results to show them in the search list under the search input and send filters if they are active
    fetchReportList();
  }, [debouncedSearch]);

  //Effect to handle error
  useEffect(() => {
    if (isError.status) {
      notification({ content: { name: t("t2"), message: isError.message }, type: 'error' })
    }
  }, [isError]);
  return (
    <>
      {debouncedSearch !== "" && (
        <ul className="absolute bg-[#fbfbfb] w-[400px] max-h-96 overflow-y-auto scroll-smooth scroll-thin  z-10 general-shadow m-0 p-0">
          {
            callApiLoading
              ? <li className="w-full py-3 pl-3 pr-2 cursor-pointer text-sm">{t("loading")}</li>
              : <>
                {data.length === 0 && searchQuery !== "" && (
                  <li className="w-full py-3 pl-3 pr-2 cursor-pointer text-sm">{t("no_Matching_Results")}</li>
                )}
                {data.map((item: ReportType) => (
                  <li key={item.id}
                    className="w-full py-3 pl-3 pr-2 cursor-pointer hover:text-[#ed1a3b] hover:bg-[#fff2ee] text-sm"
                    onClick={() => { setReportList([item]); handleSelectUser(); }}
                  >
                    {i18n.language === 'fr' ? item.nameFr : item.nameEn}
                  </li>
                ))}
              </>
          }
        </ul>
      )}
      {contextHolder}
    </>
  )
}

export default ReportManagementSearch