import React from 'react'
import { ReportType } from './index.type'
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

interface ReportTableProps {
  reportList: ReportType[]
}

const ReportTable = ({ reportList }: ReportTableProps) => {
  const { t, i18n } = useTranslation();
  return (
    <div className='px-4'>

      {reportList.length === 0
        ? <div className='text-center text-gray-500'>{t('categManagement.noReportsAvailable')}</div>
        :
        <ul className='flex w-full border-b-[0.2px] border-gray-300 pb-4'>
          <li className='w-[40%] font-semibold  px-2 text-gray-500'>{t("reportManagement.table.titleName")}</li>
          <li className='w-[60%] font-semibold  px-2 text-gray-500'>{t("reportManagement.table.titleDescription")}</li>
        </ul>
      }
      {reportList.map((report: ReportType, index: number) => {
        return (
          <ul key={report.id} className={`group report-row flex w-full py-1 ${index !== reportList.length - 1 && 'border-b-[0.2px] border-gray-300'} `}>
            <li className='w-[40%] truncate overflow-hidden whitespace-nowrap px-2 text-gray-500 group-hover:text-red-600'>
              <Tooltip placement='right' title={i18n.language === 'fr' ? report.nameFr : report.nameEn} color='#4d4c4c'>
                {i18n.language === 'fr' ? report.nameFr : report.nameEn}
              </Tooltip>
            </li>
            <li className='w-[60%] truncate overflow-hidden whitespace-nowrap px-2 text-gray-500 group-hover:text-red-600'>
              <Tooltip placement='left' title={i18n.language === 'fr' ? report.descriptionFr : report.descriptionEn} color='#4d4c4c'>
                {i18n.language === 'fr' ? report.descriptionFr : report.descriptionEn}
              </Tooltip>
            </li>
          </ul>
        );
      })}
    </div>)
}

export default ReportTable

