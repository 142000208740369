import "./style.css"
import { IoFilter } from "react-icons/io5";
import { useClickOut } from "src/hooks/useClickOut";
import { useContext, useEffect } from "react";
import { UserManagementPageContext } from "src/contextAPI/userManagementPageContext";
import { useTranslation } from "react-i18next";
import Button from "../input/Button";
import Dropdown from "../FilterSections/Dropdown";
import RadioButtons from "../FilterSections/RadioButtons";
import useUserManagementPage from "src/hooks/useUserManagementPage";
import { useConstant } from "src/hooks/useConstant";


function UserFilter() {
    //-------states and Context
    const { t, i18n } = useTranslation();
    const { filters, setFilters, pagination, businessUnits } = useContext(UserManagementPageContext)
    const { USER_MANAGE_INITIALFILTERVALUES, USER_MANAGE_STATUS_OPTIONS, USER_MANAGE_PERMISSION_OPTIONS } = useConstant()
    const { fetchUserList, handleReset } = useUserManagementPage()
    const { isOpen, setIsOpen, ref } = useClickOut();

    const handleApply = () => {
        let extraquery = {
            isSystemAdmin: filters.permissions.id === "0" ? null : filters.permissions.id === "1" ? true : false,
            pagesize: pagination.pageSize,
            pageindex: pagination.pageIndex,
            isDeleted: filters.status.id === "0" ? null : filters.status.id === "1" ? false : true,
            // status: filters.status.id === "0" ? undefined : filters.status.id,
            businessUnit: filters.businessUnits.id === "0" ? undefined : filters.businessUnits.id
        }
        fetchUserList({ extraquery });
        if (filters.status.id !== "1" || filters.permissions.id !== "0" || filters.businessUnits.id !== "0") {
            setFilters({ ...filters, isFilterActive: true })
        } else {
            setFilters({ ...filters, isFilterActive: false })
        }
    }

    //---to reset the filter values when the language is changed
    useEffect(() => {
        setFilters(USER_MANAGE_INITIALFILTERVALUES)
    }, [i18n.language])

    return (
        <div className='h-10 relative user-filters'>
            <button
                disabled={isOpen}
                onClick={() => setIsOpen(!isOpen)}
                className='relative flex items-center gap-2 border-[0.2px] border-[#00000026] h-full pl-3 pr-5 text-[#000000a2] cursor-pointer rounded-[4px] hover:bg-[#3333330a] hover:text-[#333] hover:border-[#bbbbbb]'
            >
                <IoFilter />
                <span className='text-[13.5px]'>{t("userManagement.filters.filters")}</span>
                <div className={`absolute w-[6px] h-[6px] rounded-full top-[7px] right-[6px] ${filters.isFilterActive ? "led-red" : "border border-gray-400"}`}></div>
            </button>
            {isOpen &&
                <div ref={ref} className='absolute top-12 left-0 min-w-[400px] max-w-[450px] bg-white border-[0.2px] border-[#00000026] rounded-[6px] z-50'>
                    <div className='px-4 py-7 text-[#000000a8] flex flex-col'>
                        <section>
                            <RadioButtons
                                title={t("userManagement.filters.status")}
                                options={USER_MANAGE_STATUS_OPTIONS}
                                value={filters.status.id}
                                radioButtonHandler={(option) => setFilters({ ...filters, status: option })}
                            />
                        </section>
                        <hr className="my-4" />
                        <section>
                            <RadioButtons
                                title={t("userManagement.filters.permission")}
                                options={USER_MANAGE_PERMISSION_OPTIONS}
                                value={filters.permissions.id}
                                radioButtonHandler={(option) => setFilters({ ...filters, permissions: option })}
                            />
                        </section>
                        <hr className="my-4" />
                        <section>
                            <Dropdown
                                title={t("userManagement.filters.businessUnit")}
                                dropdownHandler={(option) => setFilters({ ...filters, businessUnits: option })}
                                options={businessUnits}
                                value={filters.businessUnits}
                            />
                        </section>
                        <hr className="my-4" />
                        {/* <section> 
                           Rsport Quantity
                        </section> */}
                        <ul className='flex justify-center text-[15px] gap-3 mt-6'>
                            <li>
                                <Button btnType="secondary" text={t('userManagement.filters.reset')} className="h-[35px] rounded-[5px] px-7" handleClick={() => { setFilters(USER_MANAGE_INITIALFILTERVALUES); handleReset(); }} />
                            </li>
                            <li>
                                <Button btnType="main" text={t("userManagement.filters.apply")} className="h-[35px] rounded-[5px] px-7" handleClick={handleApply} />
                            </li>
                        </ul>
                    </div>
                </div>
            }
        </div>
    )
}
export default UserFilter