import React, { ReactElement } from 'react'
import { ButtonBdo } from './Button.types'

function Button({ text, icon, className = "px-[24px]", btnType, handleClick }: ButtonBdo) {
    if (btnType === "main") {
        className += " bg-[#ed1a3b] text-white hover:bg-[#fff] hover:text-[#e81a3b]"
    } else {
        className += " bg-[#cccccc] text-gray-700 hover:bg-gray-300 hover:text-red-600 ";
    }
    return (
        <button
            className={`${className}  flex justify-center items-center  cursor-pointer border-[0.5px] hover:border-[#e81a3b] gap-2`}
            onClick={handleClick}
        >
            {icon && <span>{icon}</span>}
            {
                text && <span className='text-sm text-ellipsis whitespace-nowrap '> {text}</span>
            }
        </button>
    )
}

export default Button