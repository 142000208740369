import { EditModuleType, initialDataFormEditReport } from './index.type';
import { GlobalContext } from 'src/contextAPI/globalContext';
import { MdOutlineEdit } from 'react-icons/md'
import { Modal } from 'antd';
import { ReportManagementPageContext } from 'src/contextAPI/reportManagementPageContext';
import { useNotification } from 'src/hooks/useNotification';
import { useReportManagePage } from 'src/hooks/useReportManagePage';
import { useTranslation } from 'react-i18next';
import Dropdown from '../FilterSections/Dropdown';
import NameAndDescription from '../ReportAddModule/NameAndDescription';
import React, { useContext, useEffect, useState } from 'react'
import useCallApi from 'src/hooks/useCallApi';
import CategoryAutocomplete from '../CategoryAutocomplete';

function EditModule({ report }: EditModuleType) {
    const { t, i18n } = useTranslation();
    const { filters, categories, pagination } = useContext(ReportManagementPageContext)
    const [searchInputValue, setSearchInputValue] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [showResults, setShowResults] = useState(false);
    const { oid, isAppAdmin } = useContext(GlobalContext)
    const { fetchReportList } = useReportManagePage()
    const { callApi, callApiLoading, isError } = useCallApi();
    const { contextHolder, notification } = useNotification()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isValidationOn, setIsValidationOn] = useState(false);
    const INITIALDATAFORM: initialDataFormEditReport = {
        powerBiReportId: report?.powerBIReportId || "",
        workspaceId: report?.workSpaceID || "",
        category: {
            id: report?.categoryId,
            name: i18n.language === 'fr' ? report?.categoryNameFr : report?.categoryNameEn
        },
        reportName: {
            en: report?.nameEn || "",
            fr: report?.nameFr || ""
        },
        description: {
            en: report?.descriptionEn || "",
            fr: report?.descriptionEn || "",
        },
        isPrivate: report?.isPublic ? false : true,
        authMethod: {
            id: `${report?.authMethod}`,
            name: report?.authMethod === 1 ? t("reportManagement.editmodule.userContext") : t("reportManagement.editmodule.servicePrinciple")
        }
    };

    const OPTIONS_CATEGORY = categories.slice(1) // --> to remove the first element from the categories array "All"

    const [dataForm, setDataForm] = useState<initialDataFormEditReport>(INITIALDATAFORM);

    useEffect(() => {
        setDataForm({
            powerBiReportId: report?.powerBIReportId || "",
            workspaceId: report?.workSpaceID || "",
            category: {
                id: report?.categoryId,
                name: i18n.language === 'fr' ? report?.categoryNameFr : report?.categoryNameEn
            },
            reportName: {
                en: report?.nameEn || "",
                fr: report?.nameFr || ""
            },
            description: {
                en: report?.descriptionEn || "",
                fr: report?.descriptionFr || ""
            },
            isPrivate: report?.isPublic ? false : true,
            authMethod: {
                id: `${report?.authMethod}`,
                name: report?.authMethod === 1 ? t("reportManagement.editmodule.userContext") : t("reportManagement.editmodule.servicePrinciple")
            }
        });
       setSearchInputValue(i18n.language === 'fr' ? report?.categoryNameFr : report?.categoryNameEn)
    }, [report, i18n.language]);

    const handleOk = async () => {
        setIsValidationOn(true);
        if (dataForm.category.id === "" || dataForm.reportName.en === "" || dataForm.reportName.fr === "") return;
        const reportNameTrim = { en: dataForm.reportName.en.trim(), fr: dataForm.reportName.fr.trim() };
        const descriptionTrim = { en: dataForm.description.en.trim(), fr: dataForm.description.fr.trim() };

        const reportUpdated = {
            id: report.id,
            powerBiReportId: dataForm.powerBiReportId,
            workspaceId: dataForm.workspaceId,
            nameEn: reportNameTrim.en,
            nameFr: reportNameTrim.fr,
            descriptionEn: descriptionTrim.en,
            descriptionFr: descriptionTrim.fr,
            categoryId: dataForm.category.id,
            authMethod: dataForm.authMethod.id,
            isPrivate: dataForm.isPrivate,
            submittedByUserId: oid,
            displayOrder: 0
        }
        let res = await callApi({ endpoint: 'UpdateReport', body: reportUpdated, method: 'PUT' }) //--> api/report/add

        if (res.status !== 200) {
            console.log('error', res)
            notification({ content: { name: t("t2"), message: `${res.status} ${res && res.message || isError && isError.message} ` }, type: 'error' })
            return
        }
        // --> to fetch the report list after adding a new report
        await fetchReportList({ extraquery: { pagesize: pagination.pageSize, pageindex: pagination.pageIndex, categoryid: filters.dropDownValue.id, isPrivate: filters.permissionValue.id === "1" ? false : filters.permissionValue.id === "2" ? true : undefined } })
        // --> to update the number of reports per user
        notification({ content: { name: t("success"), message: t("reportManagement.editmodule.updatedSuccessfully") }, type: 'success' })
        setDataForm(INITIALDATAFORM);
        setIsModalOpen(false);
        setSearchInputValue("");
        setSearchQuery("");
        setShowResults(false);
        setIsModalOpen(false);
    }

    const handleCategorySelect = (category: { id: string, name: string }) => {
        setDataForm({ ...dataForm, category: category })
        setSearchInputValue(category.name);
        setShowResults(false);
    }

    return (
        <>
            <MdOutlineEdit size={20} onClick={() => setIsModalOpen(!isModalOpen)} />
            <Modal
                className='addModule min-w-[700px] h-[700px] height-modal'
                title={
                    <div>
                        <h2>{t("reportManagement.editmodule.title")}</h2>
                        <span className='text-red-600 text-base font-medium'>
                            {i18n.language === 'fr' ? report?.nameFr : report?.nameEn}
                        </span>
                    </div>}
                okText={t("save")}
                cancelText={t("t8")}
                open={isModalOpen}
                onOk={handleOk}
                confirmLoading={callApiLoading} //--->TODO: add a loading state when the data is sending to the server
                onCancel={() => { setIsModalOpen(false); setDataForm(INITIALDATAFORM); setSearchInputValue(i18n.language === 'fr' ? report?.categoryNameFr : report?.categoryNameEn); setIsValidationOn(false);  setSearchQuery(""); setShowResults(false); }}
            >
                <hr />
                <div className='flex flex-col justify-start gap-5 mt-5'>
                    <section className='mb-3 flex flex-col w-full gap-3'>
                        <div>
                            <label className='block text-[14px] font-semibold mb-1'>Power BI Report ID</label>
                            <span className='text-gray-500 tracking-wide'>{dataForm.powerBiReportId}</span>
                        </div>
                        <div>
                            <label className='block text-[14px] font-semibold mb-1'>Workspace ID</label>
                            <span className='text-gray-500 tracking-wide'>{dataForm.workspaceId}</span>
                        </div>
                        <div className="relative">
                            <div>
                                <CategoryAutocomplete
                                    setSearchInputValue={setSearchInputValue}
                                    searchInputValue={searchInputValue}
                                    setSearchQuery={setSearchQuery}
                                    searchQuery={searchQuery}
                                    handleSelect={handleCategorySelect}
                                    setShowResults={setShowResults}
                                    showResults={showResults}
                                />
                            </div>
                            {isValidationOn && dataForm.category.id === "" && <span className="absolute -bottom-4 text-xs text-red-600 right-0">{t("reportManagement.addmodule.fieldRequired")}</span>}
                        </div>
                        <NameAndDescription dataForm={dataForm} setDataform={setDataForm} isValidationOn={isValidationOn} />
                        <div className='flex gap-1 flex-col'>
                            <span className='text-[14px] font-semibold'>{t("reportManagement.addmodule.authMethod")}</span>
                            <span className='text-[grey]'>{report.authMethod.toString() === "1" ? `User context` : `Service Principal`}</span>
                        </div>
                        <div className="absolute bottom-8">
                            <div className="flex items-center gap-2">
                                <input
                                    className={`checkbox ${!isAppAdmin ? "cursor-not-allowed" : "cursor-pointer"}`}
                                    type='checkbox'
                                    onChange={(e) => setDataForm({ ...dataForm, isPrivate: e.target.checked })}
                                    checked={dataForm.isPrivate}
                                    disabled={!isAppAdmin}
                                />
                                <label>{t("reportManagement.addmodule.makePrivate")}</label>
                            </div>
                        </div>
                    </section>
                </div>
            </Modal >
            {contextHolder}
        </>
    )
}

export default EditModule