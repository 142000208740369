import { AccordionInterface, MenuItemInterface } from "./Accordion.types";
import { AiOutlineLeft } from "react-icons/ai";
import { BarLoader } from "src/components/Loader";
import { BiSolidError } from "react-icons/bi";
import { BsPin, BsPinFill } from "react-icons/bs";
import { GlobalContext } from "src/contextAPI/globalContext";
import { IoClose } from "react-icons/io5";
import { Tooltip } from "antd";
import { useClickAway } from "@uidotdev/usehooks";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AccordionSection from "./AccordionSection";
import Button from "src/components/input/Button";
import Favorites from "src/components/Favorites";
import ReportSearch from "./ReportSearch";
import StarFavorite from "src/components/Favorites/StarFavorite";
import TextInputWithSearchIcon from "src/components/input/TextInputWithSearchIcon";

const Accordion = ({ items, selected, setShowAccordion, showAccordion, isError, isLoading, getMenuData }: AccordionInterface) => {
  const { t } = useTranslation();
  const [openSections, setOpenSections]: any = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [showResults, setShowResults] = useState(false)
  const [searchInputValue, setSearchInputValue] = useState("");
  const navigate = useNavigate();
  const [loadingCategories, setLoadingCategories] = useState(false);
  const { isPanelPinned, setIsPanelPinned } = useContext(GlobalContext);

  //To catch the reportGuid and workspaceGuid from the URL when the page is shared
  const [searchParams] = useSearchParams();
  const reportId = searchParams.get('reportId');

  const ref: any = useClickAway(() => { setShowResults(false) });
  // Effect to hide search results when pressing the escape key
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setShowResults(false)
        setSearchQuery("")
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (items && items.length > 0) {
      setLoadingCategories(false); // Cambia a false solo cuando las categorías estén cargadas
    } else {
      setLoadingCategories(true);
    }
    if (Array.isArray(items)) {
      let updated: any = {};
      items.forEach((child: any) => {

        // only if the reportID is present in the URL, it will open the section of the report with the reportID
        if (reportId) {
          for (const item of child.children) {
            if (item.id === reportId) {
              updated[child.label.en] = true;
            }
          }
        }
        if (child && child.label && child.label.length > 0) {
          updated[child.label] = true;
        }
        return null;
      });
      setOpenSections(updated);
    }
  }, [items]);

  const handleOnClickCollapsible = (label: string | undefined) => {
    if (label && label.length > 0) {
      // let updated = { ...openSections }; --> Uncomment To keep opened all the sections
      let isOpen = !!openSections[label];
      setOpenSections({ [label]: !isOpen });
      // setOpenSections({ ...updated, [label]: !isOpen }); --> Uncomment To keep opened all the sections
    }

  };

  const createAccordionSection = (items: MenuItemInterface[]) => {
    return (
      <>
        {Array.isArray(items) &&
          items.map((item, index) => {
            const { label, children, id } = item;
            let isReportSelected = false;
            if (label && label.en && label.en.length > 0) {

              //If the reportGuid is present in the URL, the report with the reportGuid will be selected
              if (reportId && reportId == id) {
                isReportSelected = true;
              }
              else {
                isReportSelected = selected === label.en;
              }
              return (
                <div className="flex items-center relative" key={index}>
                  {item && (!item.children?.length) && <StarFavorite item={item} className="absolute left-3" />}
                  <AccordionSection
                    isOpen={!!openSections[label.en]}
                    item={item}
                    key={index}
                    onClick={item.onClick}
                    onClickCollapse={() => handleOnClickCollapsible(label.en)}
                    selected={isReportSelected}
                  >
                    {children && createAccordionSection(children)}
                  </AccordionSection>
                </div>

              );
            }
            return null;
          })}
      </>
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(e.target.value);
    setSearchQuery(e.target.value);
    setShowResults(true);
    if (e.target.value === "") {
      getMenuData();
    }
  };

  const pinHandleChange = (value: boolean) => {
    setIsPanelPinned(value);
    localStorage.setItem("isPanelPinned", `${value}`);
  }

  useEffect(() => {
    const localIsPanelPinned = localStorage.getItem("isPanelPinned");
    if (localIsPanelPinned === "true") {
      setIsPanelPinned(true)
    } else {

      setIsPanelPinned(false)
    }
  }, [])



  return (
    <div className="w-[400px] drop-shadow-lg bg-white flex flex-col h-full overflow-auto relative">
      <div className="flex justify-between">
        <h1 className="text-bdo-text-01-charcoal text-base font-bold p-4 w-4/5">{t("reports.title")}</h1>
        <aside className="flex justify-end items-center gap-3">
          {
            isPanelPinned
              ?
              <Tooltip placement="left" color='#4d4c4c' title={t("reports.unpinthemenu")}>
                <BsPinFill
                  size={18}
                  className="text-[#ea2948] hover:text-[#e77084] cursor-pointer"
                  onClick={() => pinHandleChange(false)} />
              </Tooltip>
              :
              <Tooltip placement="left" color='#4d4c4c' title={t("reports.pinthemenu")}>
                <BsPin
                  size={18}
                  className="text-[#6d6b6b] hover:text-[#ea2948] cursor-pointer"
                  onClick={() => pinHandleChange(true)}
                />
              </Tooltip>
          }
          <div className="flex items-center group" onClick={() => { !isPanelPinned && setShowAccordion(!showAccordion) }}>
            <Tooltip placement="right" color='#4d4c4c' title={isPanelPinned ? t("reports.menuCanNotbeCollapsed") : t("reports.collapseTheMenu")}>
              <AiOutlineLeft className={`ml-auto mr-[5px]  ${isPanelPinned ? 'text-[#6d6b6b] ' : 'text-[#ea2948] transform -rotate-90 transition-transform duration-200 group-hover:rotate-0 cursor-pointer'} `} />
            </Tooltip>
          </div>
        </aside>
      </div>
      <section className="px-1 py-4" ref={ref}>
        <div className="flex relative">
          <TextInputWithSearchIcon handleChange={handleChange} placeholder={`${t("reports.inputPlaceholder")}`} border_radio={false} value={searchInputValue} />
          <aside>
            <Button btnType="main" className="absolute right-2 top-[13px] rounded-full" icon={<IoClose size={12} />} handleClick={() => { getMenuData(); navigate('/reports'); setSearchInputValue(""); setSearchQuery("") }} />
          </aside>
        </div>
        {showResults && <ReportSearch searchQuery={searchQuery} getMenuData={getMenuData} setShowResults={setShowResults} urlToCall="SearchList" />}
      </section>
      <div className="overflow-y-auto h-full scroll-thin">
        <Favorites />
        {
          items?.length === 0 && !isLoading &&
          <div className="m-auto h-full flex flex-col justify-center items-center">
            <div className="flex justify-center ali">
              <BiSolidError color="#ea2948" size={90} />
            </div>
            <h3 className="text-center text-bdo-text-01-charcoal text-base font-bold p-4">Error: {t("reports.noReportsAvailable")}</h3>
            <p className="proximaNova text-center">{t("reports.weSorryButItLooksLike")}</p>
          </div>
        }
        {isLoading ? <BarLoader /> : items && createAccordionSection(items)}
      </div>
    </div>
  );
};
export default Accordion;