import { AiOutlineExclamationCircle } from "react-icons/ai";
import { PageInterface } from "./index.type";
import { useClickAway } from "@uidotdev/usehooks";
import { useContext, useEffect } from "react";
import { UserManagementPageContext } from "src/contextAPI/userManagementPageContext";
import { useTranslation } from "react-i18next";
import Button from "src/components/input/Button";
import Label from "src/components/Label";
import TextInputWithSearchIcon from "src/components/input/TextInputWithSearchIcon";
import UserAddModule from "src/components/UserAddModule";
import UserFilter from "src/components/UserFilter";
import UserManagementSearch from "src/components/UserManagementSearch";
import UserTableManage from "src/components/UserTableManage";
import useUserManagementPage from "src/hooks/useUserManagementPage";
import { IoClose } from "react-icons/io5";

function UserManage({ setHeaderTitle }: PageInterface) {
  const { t } = useTranslation();
  const ref: any = useClickAway(() => { setShowResults(false) });
  const { filters, pagination, setTotalUsers, totalUsers } = useContext(UserManagementPageContext)
  const {
    contextHolder,
    callApiLoading,
    searchQuery,
    setSearchQuery,
    setShowResults,
    showResults,
    handleSearch,
    searchInputValue,
    setSearchInputValue,
    fetchUserList,
    getUsersQuantity,
    handleReset,
    fetchBusinessUnitList
  } = useUserManagementPage()

  //To know how many reports are there in the list
  const getUsersQtty = async () => {
    let extraHeaders = {
      pagesize: pagination.pageSize,
      pageindex: pagination.pageIndex,
      isDeleted: false
    }
    let res = await getUsersQuantity({ extraHeaders })
    setTotalUsers(res)
  }

  // Effect to hide search results when pressing the escape key
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setShowResults(false)
        setSearchQuery("")
        setSearchInputValue("")
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  //to render the report list on the page in the first load
  useEffect(() => {
    let extraHeaders = {
      pagesize: pagination.pageSize,
      pageindex: pagination.pageIndex,
      isDeleted: false
    }
    fetchUserList({ extraquery: extraHeaders });
    getUsersQtty()
    fetchBusinessUnitList()
  }, [])

  const resetBtn = () => {
    handleReset()
    setShowResults(false)
  }

  //to clean the search input when the record is selected
  const handleSelectUser = () => {
    setSearchQuery("")
    setShowResults(false)
    setSearchInputValue("")
  }


  useEffect(() => setHeaderTitle(t("userManagement.pagetitle")), [t])

  return (
    <main className="flex w-full pt-7">
      <div className='w-full px-11'>
        <header className='flex justify-between items-center mb-6 relative'>
          {
            filters.isFilterActive &&
            <aside className='absolute left-2 -bottom-1 flex items-center gap-1'>
              <AiOutlineExclamationCircle size={15} color='#e81a3b' />
              <span className='text-xs text-[#7e7878]'>{t("reportManagement.filters.activeInfo")}</span>
            </aside>
          }
          <section className="flex sm:flex-col md:flex-row items-center gap-5 ">
            {/* ---- Input Search------ */}
            <div className='px-1 py-4 relative' ref={ref}>
              <div className='flex min-w-[400px] relative' >
                <TextInputWithSearchIcon handleChange={handleSearch} placeholder={t('userManagement.searchUserByEmail')} value={searchInputValue} />
                <aside>
                  {searchInputValue.length > 0 &&
                    <Button btnType="main" className="absolute right-2 top-[13px] rounded-full" icon={<IoClose size={12} />} handleClick={resetBtn} />
                  }
                </aside>
              </div>
              {showResults && <UserManagementSearch searchQuery={searchQuery} handleSelectUser={handleSelectUser} />}
            </div>
            <UserFilter />
          </section>
          <section className='flex sm:justify-between justify-end items-center md:gap-20'>
            <Label text={t('userManagement.activeUsers')} qty={`${totalUsers}`} />
            <UserAddModule />
          </section>
        </header>
        <UserTableManage callApiLoading={callApiLoading} />
      </div>
      {contextHolder}
    </main>
  )
};

export default UserManage;