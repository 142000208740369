import ContentSection from "../../components/layout/content/ContentSection/ContentSection";
import Button from "../../components/input/Button/AccordionButton";
import { NewsAndUpdatesInterface } from "./NewsAndUpdates.types";

const handleButtonClick = (event: React.ChangeEvent<HTMLInputElement>) => {
  console.log("handleButtonClick " + event.target.value);
};

const newsAndUpdates = () => (
  <div className="flex flex-col gap-4 w-full">
    <Button
      className=""
      onClick={handleButtonClick}
      value="95% year to date utilization"
    >
      Interesting Article
    </Button>
    <Button
      className=""
      onClick={handleButtonClick}
      value="75% year to date utilization"
    >
      Breaking news
    </Button>
  </div>
);

const NewsAndUpdates = ({
  className = ""
}: NewsAndUpdatesInterface) => (
  <ContentSection
    className={`
      ${className}
    `}
    title="NEWS and UPDATES"
  >
    {newsAndUpdates()}
  </ContentSection>
);

export default NewsAndUpdates;
