import { AxiosError } from "axios";
import { msGraphClient, rethrowError } from "../client/msGraphClient";
import { IPublicClientApplication } from "@azure/msal-browser";
import { accessToken } from "../client/authClient";


export async function getUserPhoto48Url(instance: IPublicClientApplication, callback: any = null) {
  if (!instance) {
    return;
  }

  try {
    accessToken(instance)
    .then((bearerToken) => {
      if (!bearerToken) {
        callback && callback(null);
      }
      msGraphClient(bearerToken)
          .then(client => client.getUserPhoto48Url())
          .then((blob: any) => {
            window.URL = window.URL || window.webkitURL;
            const blobUrl = window.URL.createObjectURL(blob);
            callback && callback(blobUrl);
          });
    })
    .catch((error: any) => {
      // No photo found throws errors, return null and set the default background
      callback && callback(null);
    });
  } catch (error: any) {
    // If you can't get an access token you're probably not logged in and
    // wouldn't reach this code, but handle by returning null just in case
    callback && callback(null);
  }
};
