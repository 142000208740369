import { Category } from 'src/pages/index.type';
import { GlobalContext } from 'src/contextAPI/globalContext';
import { Popconfirm, message } from 'antd'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { useNotification } from 'src/hooks/useNotification';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react'
import useCallApi from 'src/hooks/useCallApi';
import useCategoryLogic from 'src/hooks/useCategoryLogic';

const DeleteModule = (category: Category) => {
    const { t, i18n } = useTranslation();
    const { notification, contextHolder } = useNotification();
    const { callApi, isError } = useCallApi();
    const { oid } = useContext(GlobalContext)
    const { fetchCategoryList } = useCategoryLogic()

    const handleDeleteCategory = async () => {
        if (category.reportCount >= 1) return notification({ content: { name: t("t2"), message: t("categManagement.cannotDelete") }, type: 'error' })
        const body = {
            submittedByUserId: oid,
            id: category.id
        }

        let res = await callApi({ endpoint: 'DeleteCategory', body: body, method: 'POST' })
        if (isError.status || res.status !== 200) {
            notification({ content: { name: t("t2"), message: `${res.status} ${res && res.message || isError && isError.message} ` }, type: 'error' })
            return
        }
        //----success, then update the categoryList
        fetchCategoryList({ notification })
        message.success(t("categManagement.deletedSuccessfully"))

    }

    return (
        <Popconfirm
            title={t("categManagement.deleteCategory")}
            description={t("categManagement.areYouSure")}
            onConfirm={handleDeleteCategory}
            okText={t("reportManagement.deletemodule.yes")}
            cancelText={t("reportManagement.deletemodule.no")}
        >
            <RiDeleteBin6Line size={18} />
            {contextHolder}
        </Popconfirm>
    )
}

export default DeleteModule