import { message } from "antd";
import { useNotificationType } from "./hooks.types";

export const useNotification = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const notification = ({ content, type }: useNotificationType) => {
    const message = `${content.name}:  ${content.message} `;
    return messageApi.open({
      type: type,
      content: message,
    });
  };

  return { notification, contextHolder };
};
