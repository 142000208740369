import { AiOutlineExclamationCircle } from "react-icons/ai";
import { NameAndDescriptionType } from 'src/pages/index.type';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react'
import TextInputWithSearchIcon from '../input/TextInputWithSearchIcon';

const NameAndDescription = ({ dataForm, setDataform, isValidationOn }: NameAndDescriptionType) => {
    const { t, i18n } = useTranslation();
    const [isEnglish, setIsEnglish] = useState(i18n.language === "en");

    useEffect(() => { setIsEnglish(i18n.language === "en") }, [t])
    return (
        <div className="w-full overflow-hidden">
            <section className='flex items-center justify-between my-2 bg-[#ebe8e8c7] px-3 py-3'>
                <aside className='  flex items-center gap-3'>
                    <AiOutlineExclamationCircle size={15} color='#e81a3b' />
                    <span className='text-sm text-[#443b3b]'>{t("reportManagement.addmodule.nameAndDescription.info")}</span>
                </aside>
                {/* --- Toggle FR and EN ---- */}
                <div className="flex justify-end gap-5">
                    <div className="relative">
                        <span className={`cursor-pointer ${isEnglish && "font-semibold"} text-[#616161]`} onClick={() => setIsEnglish(true)}>EN</span>
                        <hr className={`h-1 ${isEnglish && "bg-[#e81a3b]"}`} />
                        {isValidationOn && dataForm.reportName.en === "" && <span className="text-red-600 text-2xl absolute -top-0 -left-2">*</span>}
                    </div>

                    <div className="relative">
                        <span className={`cursor-pointer ${!isEnglish && "font-semibold"} text-[#616161]`} onClick={() => setIsEnglish(false)}>FR</span>
                        <hr className={`h-1 ${!isEnglish && "bg-[#e81a3b]"}`} />
                        {isValidationOn && dataForm.reportName.fr === "" && <span className="text-red-600 text-2xl absolute -top-0 -left-2">*</span>}
                    </div>
                </div>
            </section>
            <section className="flex w-[1400px]">
                <section className={`w-[652px] transition-all ease-in-out duration-300 ${isEnglish ? "ml-0" : "-ml-[652px]"}`}>
                    {/*------ Name EN ------*/}
                    <div className="relative">
                        <div className="flex gap-1">
                            <label className='block text-[14px] font-semibold mb-1'>{t("reportManagement.addmodule.nameAndDescription.labelEn")}</label>
                            {dataForm.reportName.en === "" && <span className="text-red-600 text-2xl">*</span>}
                        </div>

                        <TextInputWithSearchIcon
                            handleChange={(e) => setDataform({ ...dataForm, reportName: { en: e.target.value, fr: dataForm.reportName.fr } })}
                            placeholder={t("reportManagement.addmodule.nameAndDescription.placeholderEn")}
                            isToSearch={false}
                            value={dataForm.reportName.en}
                        />
                        {isValidationOn && dataForm.reportName.en === "" && <span className="absolute -bottom-4 text-xs text-red-600 right-0">{t("reportManagement.addmodule.fieldRequired")}</span>}
                    </div>
                    {/* ------Description EN -----*/}
                    <div className="mt-3 relative">
                        <h3 className="font-semibold mb-1 text-[14px]">{t("reportManagement.addmodule.nameAndDescription.labelDescriptionEn")}</h3>
                        <textarea className='w-full h-[150px] border border-[#3333331f] rounded-[6px] p-3 resize-none outline-none'
                            maxLength={500}
                            onChange={(e) => setDataform({ ...dataForm, description: { en: e.target.value, fr: dataForm.description.fr } })}
                            placeholder={t("reportManagement.addmodule.nameAndDescription.descriptionPlaceholder")}
                            value={dataForm.description.en}
                        />
                        <div className="text-end w-full">
                            <span className="text-[#7d7b7b] text-xs text-end w-full" >{dataForm.description.en.length}/500</span>
                        </div>
                    </div>
                </section>
                <section className="w-[652px]">
                    {/*------ Name FR ------*/}
                    <div className="relative">
                        <div className="flex gap-1">
                            <label className='block text-[14px] font-semibold mb-1'>{t("reportManagement.addmodule.nameAndDescription.labelFr")}</label>
                            {dataForm.reportName.fr === "" && <span className="text-red-600 text-2xl">*</span>}
                        </div>
                        <TextInputWithSearchIcon
                            handleChange={(e) => setDataform({ ...dataForm, reportName: { en: dataForm.reportName.en, fr: e.target.value } })}
                            placeholder={t("reportManagement.addmodule.nameAndDescription.placeholderFr")}
                            value={dataForm.reportName.fr}
                            isToSearch={false}
                        />
                        {isValidationOn && dataForm.reportName.fr === "" && <span className="absolute -bottom-4 text-xs text-red-600 right-0">{t("reportManagement.addmodule.fieldRequired")}</span>}
                    </div>
                    {/* ------Description FR -----*/}
                    <div className="mt-3">
                        <h3 className="font-semibold mb-1 text-[14px]">{t("reportManagement.addmodule.nameAndDescription.labelDescriptionFr")}</h3>
                        <textarea className='w-full h-[150px] border border-[#3333331f] rounded-[6px] p-3 resize-none outline-none'
                            maxLength={500}
                            onChange={(e) => setDataform({ ...dataForm, description: { en: dataForm.description.en, fr: e.target.value } })}
                            placeholder={t("reportManagement.addmodule.nameAndDescription.descriptionPlaceholder")}
                            value={dataForm.description.fr}
                        />
                        <div className="text-end w-full">
                            <span className="text-[#7d7b7b] text-xs text-end w-full" >{dataForm.description.fr.length}/500</span>
                        </div>
                    </div>
                </section>
            </section>
        </div>
    )
}

export default NameAndDescription