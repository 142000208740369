import React from 'react'
import serviceNow from "./../../assets/serviceNow.png"
import { useTranslation } from 'react-i18next';

const ServiceNow = () => {
    const { t } = useTranslation();
    return (
        <div className="relative group">
            <div className="w-[54px]">
                <div className={`h-[50px] w-full flex justify-center cursor-pointer items-center hover:bg-[#1b191933]`}>
                    <span className="bg-bdo-primary-red"></span>
                    <figure><img src={serviceNow} alt="" width={40} /></figure>
                </div>
            </div>
            <ul className="bg-[#363636] w-[290px] absolute flex-col flex -top-[108px] left-[-400px] group-hover:left-[55px] transition-opacity duration-300 group-hover:flex">
                <li className="text-white p-4 pb-2">AssistMe</li>
                <li><hr className="bg-bdo-primary-red w-[90px] border-none h-[5px] text-sm" /></li>
                <a href="https://bdocanprod.service-now.com/ess?id=sc_cat_item&sys_id=e1608ede1ba42510bd24bbf4cc4bcb02" target="_blank" className="text-white hover:bg-[#0f0e0e33] p-4 cursor-pointer text-sm">{t("createSupportTicket")}</a>
                <a href="https://bdocanprod.service-now.com/ess?id=my_requests" target="_blank" className="text-white hover:bg-[#0f0e0e33] p-4 cursor-pointer text-sm">{t("myServiceNowTickets")}</a>
            </ul>
        </div>
    )
}
export default ServiceNow