import { RadioButtonProps } from "./index.type";
function RadioButtons({ title, options, radioButtonHandler, value }: RadioButtonProps) {
    return (
        <>
            <h3 className="font-semibold text-[14px]">{title}</h3>
            <ul className='text-[13px] mt-1 flex radio-permission gap-4'>
                {options.map((item) => (
                    <li key={item.id} className="flex gap-1">
                        <input
                            name={title} 
                            type='radio'
                            id={item.name}
                            className="cursor-pointer accent-[#e04b4b]"
                            onChange={() => radioButtonHandler(item)}
                            checked={value === item.id} 
                        />
                        <label htmlFor={item.id}>{item.name}</label> 
                    </li>
                ))}
            </ul>
        </>
    );
}

export default RadioButtons;
