
import { GlobalContext } from 'src/contextAPI/globalContext';
import { RiAdminFill } from "react-icons/ri";
import { Tooltip } from 'antd';
import { useContext } from 'react';
import { UserInterface } from './index.type';
import DeleteModule from './DeleteModule';
import EditModule from './EditModule';
import { useTranslation } from 'react-i18next';
import { useNotification } from 'src/hooks/useNotification';
import { t } from 'i18next';

export function Actions({ user }: { user: UserInterface }) {
    const { oid } = useContext(GlobalContext)
    const { contextHolder, notification } = useNotification();
    const { t } = useTranslation();
    if (user.userObjectId === oid || user.isDeleted) return null
    return (
        <ul className="flex justify-center items-center gap-5">
            <Tooltip placement="left" color='#4d4c4c' title={t("userManagement.editUser.title")}>
                <li className="cursor-pointer text-[#857f7f] hover:text-red-500">
                    <EditModule user={user} />
                </li>
            </Tooltip>
            <Tooltip placement="left" color='#4d4c4c' title={t("userManagement.editUser.disableUser")}>
                <li className="cursor-pointer text-[#857f7f] hover:text-red-500">
                    <DeleteModule user={user} />
                </li>
            </Tooltip>
            {contextHolder}
        </ul>)
}

export function UserAndEmail({ user }: { user: UserInterface }) {
    return <ul>
        <li className='font-bold'>
            {user.fullName}
        </li>
        <li>{user.email}</li>
    </ul>
}

export function Permission({ user }: { user: UserInterface }) {

    return <ul className='flex justify-center items-center gap-1'>
        {user.isSystemAdmin && <li><RiAdminFill color='#4d4c4c' /></li>}
        <li className='text-[#857f7f]'>{user.isSystemAdmin ? t("userManagement.admin") : t("userManagement.user")}</li>
    </ul>
}