import { useAccount, useMsal } from '@azure/msal-react'
import { useState } from 'react'
import { useCallApiType } from './hooks.types'

function useCallApi() {
  const { accounts, instance } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [callApiLoading, setCallApiLoading] = useState(false)
  const [isError, setIsError] = useState({ status: false, message: '' })
  const [data, setData] = useState<any>([])

  const callApi = async ({ endpoint, query, body = null, extraHeaders = null, method = 'GET', contentType = 'application/json', isToExport = false }: useCallApiType) => {
    let url = ''
    if (account) {
      let scope = process.env.REACT_APP_API_SCOPE || ''
      const userInfo = await instance.acquireTokenSilent({
        scopes: [scope],
        account: account,
      })
      let headers: any = {
        Authorization: `Bearer ${userInfo.accessToken}`,
        'Content-Type': contentType,
      }

      // console.log(headers)
      const oid = (userInfo?.idTokenClaims as { oid: string })?.oid
      switch (endpoint) {
        case 'AddBulkUsers':
          url = `${process.env.REACT_APP_API_POST_ADD_USER_BULK}` //api/user/addbulk
          break

        case 'AddCategory':
          url = `${process.env.REACT_APP_API_POST_CATEGORY}` //api/category/add
          break

        case 'AddReport':
          url = `${process.env.REACT_APP_API_POST_REPORT}` //api/report/add
          break

        case 'AssignmentList':
          headers.userObjectId = oid
          headers.reportId = extraHeaders.reportId
          headers.accessType = extraHeaders.accessType
          url = `${process.env.REACT_APP_API_GET_REPORT_ASSIGNMENT_LIST}` //api/report/fetchaccess
          break

        case 'AssignmentUpdateUserAndGroup':
          url = `${process.env.REACT_APP_API_POST_REPORT_ASSIGNMENT_UPDATE_ACCESS}` //api/report/searchuserandgroup
          break

        case 'AssignmentSearchUser':
          url = `${process.env.REACT_APP_API_GET_REPORT_ASSIGNMENT_SEARCH_USER}?filterString=${query}` ///api/user
          break

        case 'AssignmentSearchGroup':
          url = `${process.env.REACT_APP_API_GET_REPORT_ASSIGNMENT_SEARCH_GROUP}?filterString=${query}` //api/user/searchentragroups
          break

        case 'AuditLogs':
          headers.auditLogListRequest = JSON.stringify(extraHeaders)
          url = `${process.env.REACT_APP_API_GET_AUDIT_LOGS}` //api/auditlogs
          break

        case 'AuditLogsExport':
          headers.auditLogListRequest = JSON.stringify(extraHeaders)
          url = `${process.env.REACT_APP_API_AUDITLOG_EXPORT}` //api/auditlogs/export
          break

        case 'BusinessUnits':
          url = `${process.env.REACT_APP_API_GET_BUSINESS_UNITS}` //api/businessunit
          break

        case 'Categories':
          url = `${process.env.REACT_APP_API_GET_CATEGORIES}` //api/Category/List
          break

        case 'CategorySearch':
          url = `${process.env.REACT_APP_API_AUTOCOMPLETE_CATEGORY}?categoryName=${query}` //api/Category/searchbyname
          break

        case 'DeleteCategory':
          url = `${process.env.REACT_APP_API_DELETE_CATEGORY}` //api/category/delete
          break

        case 'DeleteReport':
          url = `${process.env.REACT_APP_API_DELETE_REPORT}` //api/report/delete
          break

        case 'DeleteUser':
          url = `${process.env.REACT_APP_API_POST_USER}` //api/user/delete
          break

        case 'Favorites':
          body.userObjectId = oid
          url = `${process.env.REACT_APP_API_FAVORITES}` //api/report/favorite
          break

        case 'GlobalSearchReport':
          url = `${process.env.REACT_APP_API_GET_SEARCH_GLOBAL_REPORTS}/${oid}?searchCriteria=${query}` //report/globalreportsearch/{oid}
          break

        case 'GraphUsers':
          url = `${process.env.REACT_APP_API_GET_SEARCH_GRAPHUSERS}?email=${query}` //api/user/graph
          break

        case 'IsAssignedToPbiService':
          url = `${process.env.REACT_APP_API_IS_USER_IN_PBI_SERVICE}?${query}` //api/report/isuserassignedtopbservice
          break

        case 'IsAuthorized':
          // url = `${process.env.REACT_APP_API_GET_IS_AUTHORISED}?userObjectId=0274e41a-2c37-4b28-ae3a-147779945271` //api/user/authorized
          url = `${process.env.REACT_APP_API_GET_IS_AUTHORISED}?userObjectId=${oid}` //api/user/authorized
          break

        case 'OneReport':
          url = `${process.env.REACT_APP_API_GET_REPORTS}/${oid}?ReportId=${query}` //api/user/reports
          break

        case 'RequestAccess':
          url = `${process.env.REACT_APP_API_POST_REQUEST_ACCESS}` //api/report/sharerequest
          break

        case 'ReportList':
          headers.reportListRequest = JSON.stringify(extraHeaders)
          url = `${process.env.REACT_APP_API_GET_SEARCH_REPORTS}/${oid}` //api/report/search
          break

        case 'ReportUsage':
          headers.reportUsageRequest = JSON.stringify(extraHeaders)
          url = `${process.env.REACT_APP_API_REPORT_USAGE}` //api/report/usage
          break

        case 'SearchList':
          headers.reportListRequest = JSON.stringify(extraHeaders)
          url = `${process.env.REACT_APP_API_GET_REPORTS_BY_NAME}/${oid}?filterstring=${query}`
          break

        case 'SearchUserForAccess':
          url = `${process.env.REACT_APP_API_SEARCH_USERS_FOR_ACCESS}?${query}` //api/user/searchforaccess
          break

        case 'SpReportToken':
          url = `${process.env.REACT_APP_API_GET_SPTOKEN}?${query}` //api/report/token
          break

        case 'UpdateCategory':
          url = `${process.env.REACT_APP_API_UPDATE_CATEGORY}` //api/category/update
          break

        case 'UpdateReport':
          url = `${process.env.REACT_APP_API_UPDATE_REPORT}` //api/report/update
          break

        case 'UserFavorites':
          url = `${process.env.REACT_APP_API_GET_FAVORITES}?userObjectId=${oid}` //api/user/favorite
          break

        case 'UpdateUser':
          url = `${process.env.REACT_APP_API_UPDATE_USER}` //api/user/update
          break

        case 'UserList':
          headers.userListRequest = JSON.stringify(extraHeaders)
          url = `${process.env.REACT_APP_API_GET_SEARCH_USERS}` //api/user/search
          break

        default: //AllReports
          url = `${process.env.REACT_APP_API_GET_REPORTS}/${oid}` //api/user/reports
          break
      }
      try {
        setCallApiLoading(true)
        // console.log("INFORMATION SENT TO BACKEND:  ",{
        //   url: url,
        //   method: method,
        //   headers: headers,
        //   body: body ? JSON.stringify(body) : null,
        // })
        let res = await fetch(url, {
          method: method,
          headers: headers,
          body: body ? JSON.stringify(body) : null,
        })
        if (res.ok) {
          const jsonData = isToExport ? await res.arrayBuffer() : await res.json()
          setData(jsonData)
          setIsError({ status: false, message: '' })
          return jsonData
        } else {
          throw res
        }
      } catch (error: any) {
        console.log(error)
        setIsError({ status: true, message: `Error ${error.status || error}: ${error.statusText || ''} ` })
        return { isError: true, status: error.status || error, statusText: error.statusText || '' }
      } finally {
        setCallApiLoading(false)
      }
    }
  }

  return { callApi, callApiLoading, data, setData, isError }
}

export default useCallApi
