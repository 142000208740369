import { GlobalContext } from "src/contextAPI/globalContext";
import { IoIosExpand } from "react-icons/io";
import { LabelResource } from "../../api/proxy/TempProxy";
import { LandingDefaultPage } from "src/components/NoAuthorized";
import { PageInterface } from "../index.type";
import { ReportPageContext } from 'src/contextAPI/reportPageContext';
import { useEffect, useContext } from 'react';
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AllReportsSearch from "../../pageContent/allReports/AllReportsSearch";
import ContentWrapper from "../../components/layout/content/ContentWrapper/ContentWrapper";
import MaxWidthWrapper from "../../components/page/MaxWidthWrapper";
import Report from "./Report";
import useReportList from "src/hooks/useReportList";

export interface MenuItemMetaDataInterface {
  reportGuid: string | undefined;
  reportId: string | undefined;
  reportName: LabelResource | undefined;
  reportDescription: LabelResource | undefined;
}

function Reports({ setHeaderTitle }: PageInterface) {
  const { reportGuid, reportId, setReportId, reportName, setReportName, reportDescription, setReportDescription, setFavoriteItems } = useContext(ReportPageContext);
  const { reportsMenuData, reportsMenuMetaData, getMenuData, isError, callApiLoading, getFavorites } = useReportList();
  const { setShowAccordion } = useContext(GlobalContext);
  const [searchParams] = useSearchParams();
  const { t, i18n } = useTranslation();

  // Fetch favorites from the API in the first render
  const fetchFavorites = async () => {
    let favorites = await getFavorites();
    if (favorites) {
      setFavoriteItems && setFavoriteItems(favorites);
    }
  }
  useEffect(() => { setHeaderTitle(t("reports.title")); }, [t]);

  useEffect(() => {
    fetchFavorites();
    setReportId("");
    // To load report in the first render if the url was shared
    if (searchParams.has("reportId")) {
      setReportId(searchParams.get("reportId") as string);
    }
    setShowAccordion(true);
  }, []);

  useEffect(() => {
    reportsMenuMetaData && reportsMenuMetaData.forEach((report) => {
      if (
        (report.reportId && report.reportId === reportId) ||
        (report.reportGuid && report.reportGuid === reportGuid)
      ) {

        if (report.reportDescription) {
          setReportDescription?.(report.reportDescription);
        }

        if (report.reportName) {
          setReportName?.(report.reportName);
        }
        return;
      }
    });
  }, [reportsMenuMetaData]);

  const handleFullScreen = () => {
    let reportElement = document.querySelector("#reportContainer");
    reportElement && openFullscreen(reportElement);
  };

  const openFullscreen = (elem: any) => {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  };
  return (
    <div className="flex w-full h-full pt-1 ">
      <MaxWidthWrapper className="flex relative">
        <AllReportsSearch reportsMenuData={reportsMenuData} isError={isError} isLoading={callApiLoading} getMenuData={getMenuData} />
        <MaxWidthWrapper className="flex-col z-[7]">
          {reportName && (
            <div className="grid grid-cols-4 pr-16 pl-8 bg-bdo-bg-04-white">
              <div className="col-span-3">
                <div className="opacity-80 text-md font-bold">{i18n.language === 'fr' ? reportName.fr : reportName.en}</div>
                {/* <div className="text-sm">{i18n.language === 'fr' ? reportDescription?.fr : reportDescription?.en}</div> */}
              </div>
              <div className="flex justify-end items-center gap-3">
                {/* <ShareReport /> */}
                <IoIosExpand onClick={() => handleFullScreen()} size={20} className="cursor-pointer" />
              </div>
            </div>
          )}
          <ContentWrapper className="h-full bg-bdo-bg-03-charcoal-pale">
            {reportId != ""
              ? <Report />
              : <LandingDefaultPage title={t("reportManagement.landing.title")} message={t("reportManagement.landing.message")} typeOfImage="default" />}
          </ContentWrapper>
        </MaxWidthWrapper>
      </MaxWidthWrapper>
    </div>
  );
};

export default Reports;