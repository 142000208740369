import { CircleLoader } from '../Loader';
import { FaUserAlt, FaUserSlash } from 'react-icons/fa';
import { GlobalContext } from 'src/contextAPI/globalContext';
import { GraphUserRequestInfo } from 'src/pages/index.type';
import { IoIosSearch, IoMdPersonAdd } from 'react-icons/io';
import { MdAdd } from 'react-icons/md'
import { Modal, Table, Tooltip } from 'antd';
import { useNotification } from 'src/hooks/useNotification';
import { UserInterface } from '../UserTableManage/index.type';
import { UserManagementPageContext } from 'src/contextAPI/userManagementPageContext';
import { useTranslation } from 'react-i18next';
import Button from '../input/Button'
import React, { useContext, useEffect, useState } from 'react'
import TextInputWithSearchIcon from '../input/TextInputWithSearchIcon';
import useCallApi from 'src/hooks/useCallApi';
import UserTableColumns from './UserTableColumns';
import useUserManagementPage from 'src/hooks/useUserManagementPage';

const UserAddModule = () => {
  const { t } = useTranslation();
  const { pagination, setTotalUsers } = useContext(UserManagementPageContext)
  const { oid } = useContext(GlobalContext)
  const { contextHolder, notification } = useNotification()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchUserInputValue, setSearchUserInputValue] = useState("")
  const [checkBoxValue, setCheckBoxValue] = useState(false)
  const [listOfTable, setListOfTable] = useState<UserInterface[] | []>([])
  const { fetchUserList } = useUserManagementPage()
  const { callApi, callApiLoading, isError } = useCallApi();

  const handleOk = async () => {
    if (listOfTable.length === 0) {
      notification({ content: { name: t("t2"), message: t('userManagement.addUser.listEmpty') }, type: 'error' })
      return
    }
    let body = {
      submittedByUserId: oid,
      users: listOfTable
    }

    let res = await callApi({ endpoint: "AddBulkUsers", method: 'POST', body: body })
    if (res.status !== 200) {
      notification({ content: { name: t("t2"), message: `${res.status} ${res && res.message || res.statusText || isError && isError.message} ` }, type: 'error' })
      return
    }
    await fetchUserList({ extraquery: { pagesize: pagination.pageSize, pageindex: pagination.pageIndex, isDeleted: false } })
    notification({ content: { name: t("success"), message: t('userManagement.addUser.addedSuccessfully') }, type: 'success' })
    setListOfTable([])
    setCheckBoxValue(false)
    setSearchUserInputValue("")
    setIsModalOpen(false)
    setTotalUsers(prev => prev + listOfTable.length)
  }

  const callGraphApi = async () => {

    let res = await callApi({ endpoint: "GraphUsers", query: searchUserInputValue })
    //Validate if the user exists
    if (res.status === 404 || !res.isSuccessful) {
      notification({ content: { name: t("t2"), message: `${res.status === 404 ? t('userManagement.addUser.userNotFound') : res.statusText || isError.message}` }, type: 'error' })
      return
    }
    const userInfo: GraphUserRequestInfo = res.userRequestInfo
    let newUser: UserInterface = {
      userObjectId: userInfo.userObjectId,
      email: userInfo.email,
      fullName: userInfo.fullName,
      isSystemAdmin: checkBoxValue,
      department: userInfo.department || "",
      location: userInfo.location || "",
      businessUnit: userInfo.businessUnit || "",
      jobTitle: userInfo.jobTitle || "",
    }
    setSearchUserInputValue("")
    setListOfTable([...listOfTable, newUser])
  }

  const handleAddToTempList = async () => {
    //verify if it is right email structure
    let emailRegex = /^[a-zA-Z0-9._%+-]+@bdo\.ca$/;
    if (!emailRegex.test(searchUserInputValue)) {
      notification({ content: { name: t("t2"), message: t('userManagement.addUser.invalidEmail') }, type: 'error' })
      return
    }

    //verify if the user selected is already in the temporary list
    let userExist = listOfTable.some((user: UserInterface) => user.email.toLowerCase() === searchUserInputValue.toLowerCase())
    if (userExist) {
      notification({ content: { name: t("t2"), message: t('userManagement.addUser.emailAlreadyInTheList') }, type: 'error' })
      return
    }

    //verify if the user selected is already an app user
    let extraHeaders = {
      pagesize: 9999,
      pageindex: 1,
      sortBy: "email",
      ascending: true,
      isDeleted: false
    }
    let globalUsers = await fetchUserList({ extraquery: extraHeaders, getTotal: true })
    userExist = globalUsers.find((user: UserInterface) => user.email.toLowerCase() === searchUserInputValue.toLowerCase())
    if (userExist) {
      notification({ content: { name: t("t2"), message: t('userManagement.addUser.emailAlreadyOnTheApp') }, type: 'error' })
      return
    }
    //Call function to get GRAPH email
    await callGraphApi()
  }

  const handleReset = async () => {
    setCheckBoxValue(false);
    setSearchUserInputValue("");
    setListOfTable([]);
    setIsModalOpen(false);
  }

  return (
    <>
      <Button
        text={t('userManagement.addUser.title')}
        btnType="main"
        icon={<MdAdd />}
        className="rounded-[4px] h-[40px] px-5 focus-visible:outline-none"
        handleClick={() => setIsModalOpen(!isModalOpen)}
      />
      <Modal
        className='userandgroup-assignment min-w-[850px] h-[900px] height-modal'
        okText={<div className='flex justify-center items-center gap-1'><IoMdPersonAdd />{t('userManagement.addUser.add')}</div>}
        cancelText={t('userManagement.addUser.cancel')}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleReset}
        title={
          <div className='flex items-center gap-3'>
            <span className='text-[18px] text-[#616161]'>{t("userManagement.addUser.title")}</span>
            <FaUserAlt size={15} color='#616161' />
          </div>
        }
      >
        {/* ================Input search ================ */}
        <div className='w-[700px] py-5'>
          <div className='flex gap-5 '>
            <div className='relative w-full' onKeyDown={e => { e.key === "Enter" && handleAddToTempList() }}>
              <TextInputWithSearchIcon
                handleChange={(event) => setSearchUserInputValue(event.target.value)}
                placeholder={t('userManagement.addUser.searchUserByEmail')}
                value={searchUserInputValue}
              />
              {callApiLoading && <div className='absolute right-3 top-3'><CircleLoader /></div>}
            </div>
            <div>
              <Button text={t('userManagement.addUser.search')} btnType='main' className='h-[40px] px-8' icon={<IoIosSearch />} handleClick={handleAddToTempList} />
            </div>
          </div>
        </div>
        {/* ================ Table ===================*/}
        <div className='flex flex-col gap-5 UserTableColumns'>
          <h2 className='font-semibold ml-2'>{t('userManagement.addUser.temporaryList')}</h2>
          <Table
            columns={UserTableColumns({ setListOfTable, listOfTable })}
            dataSource={listOfTable}
            className='w-full '
            rowKey={(record: UserInterface) => record.userObjectId}
            showSorterTooltip={false}
            pagination={false}
            scroll={{ y: 350 }}
            locale={{
              emptyText() {
                return <div className='flex justify-center items-center flex-col h-[250px]'><FaUserSlash size={40} /><h2>{t('userManagement.addUser.listEmpty')}</h2></div>
              },
            }}
          />
        </div>
      </Modal>
      {contextHolder}
    </>
  )
}

export default UserAddModule