import { useAccount, useMsal } from '@azure/msal-react'

export function useGetId() {
  const { accounts, instance } = useMsal()
  const account = useAccount(accounts[0] || {})
  const getOid = async () => {
    if (account) {
      let scope = process.env.REACT_APP_API_SCOPE || ''
      const userInfo = await instance.acquireTokenSilent({
        scopes: [scope],
        account: account,
      })
      const oid = (userInfo?.idTokenClaims as { oid: string })?.oid
      return oid
    }
  }

  return { getOid }
}
