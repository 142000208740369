import axios from "axios";
import { AxiosError } from "axios";
import { MsGraphProxy } from "../proxy/MsGraphProxy";

export const msGraphClient = async (bearerToken: string) => {
    let hostUrl = process.env.REACT_APP_MS_GRAPH_HOSTNAME || "";

    let axiosInstance = axios.create();
    axiosInstance.interceptors.request.use((configuration) => {
        if (configuration && configuration.headers) {
            configuration.headers["Authorization"] = "Bearer " + bearerToken;
            configuration.headers["Accept"] = "application/json";
            configuration.headers["Content-Type"] = "image/jpg";
            configuration.headers["Access-Control-Allow-Origin"] = "*";
            configuration.headers["Access-Control-Allow-Headers"] = "*";
        }
        return configuration;
    });

    return new MsGraphProxy(hostUrl, axiosInstance);
};

export const rethrowError = ((error: any) => {
    throw new AxiosError(error?.cause?.message ?? "Unexpected Client Error");
});
