import "./style.css";
import { BiLogOut } from "react-icons/bi";
import { GlobalContext } from "src/contextAPI/globalContext";
import { NavLink, useLocation } from "react-router-dom";
import { RxDashboard } from "react-icons/rx";
import { RxDividerHorizontal } from "react-icons/rx";
import { SlHome } from "react-icons/sl";
import { TbMapSearch } from "react-icons/tb";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import React, { useContext } from "react";
import Settings from "./Settings";
import serviceNow from "./../../assets/serviceNow.png"
import ServiceNow from "./ServiceNow";

function SideBar() {
  const { isAppAdmin, isReportAdmin, setShowAccordion, showAccordion, isPanelPinned } = useContext(GlobalContext);
  const { t } = useTranslation();

  const styleIcons = {
    color: "white",
    size: 22,
  };

  console.log(process.env.REACT_APP_ENV)

  return (
    <nav className="bg-[#333333] flex flex-col w-[54px] items-center gap-5 z-[999] min-w-[54px]">
      <div className="h-[50px] flex justify-center items-center"></div>
      <NavLink to={"/reports"} className="h-[50px] w-full flex justify-center items-center hover:bg-[#1b191933]" onClick={() => { !isPanelPinned && setShowAccordion(!showAccordion) }}>
        <span className="bg-bdo-primary-red "></span>
        <SlHome color={styleIcons.color} size={styleIcons.size} />

        {/* setShowAccordion(!showAccordion) */}
      </NavLink>

      {/* <NavLink to={"/dashboard"} className="h-[50px] w-full flex justify-center items-center hover:bg-[#1b191933]">
              <span className="bg-bdo-primary-red "></span>
              <RxDashboard color={styleIcons.color} size={styleIcons.size} />
            </NavLink> */}
      {
        isAppAdmin &&
        <NavLink to={"/auditlogs"} className="h-[50px] w-full flex justify-center items-center hover:bg-[#1b191933]">
          <span className="bg-bdo-primary-red "></span>
          <TbMapSearch color={styleIcons.color} size={styleIcons.size} />
        </NavLink>
      }

      <RxDividerHorizontal color="white" />
      {isAppAdmin || isReportAdmin ?
        <Settings color={styleIcons.color} size={styleIcons.size} />
        : null
      }

      <div className="flex justify-center items-center w-full mt-auto mb-20">
        <ServiceNow />
      </div>
    </nav>
  );
};

export default SideBar;