import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import dayjs from 'dayjs';


export function Columns(): any {
    const { t } = useTranslation();

    return [
        {
            title: `${t("auditLogs.userLogin")}`,
            dataIndex: `fullName`,
            key: "fullName",
            ellipsis: { showTitle: false },
            width: "20%",
            sorter: () => { },
            render: (text: string) => (
                <Tooltip placement="top" title={text} color='#4d4c4c'>
                    {text}
                </Tooltip>
            ),
        },
        {
            title: `${t("auditLogs.email")}`,
            dataIndex: `email`,
            key: "email",
            ellipsis: { showTitle: false },
            width: "20%",
            sorter: () => { },
            render: (text: string) => (
                <Tooltip placement="top" title={text} color='#4d4c4c'>
                    {text}
                </Tooltip>
            ),
        },
        {
            title: `${t("reportManagement.table.titleName")}`,
            dataIndex: `reportName`,
            key: "reportName",
            ellipsis: { showTitle: false },
            width: "20%",
            sorter: () => { },
            render: (text: string) => (
                <Tooltip placement="top" title={text} color='#4d4c4c'>
                    {text}
                </Tooltip>
            ),
        },
        {
            title: `${t("auditLogs.timeStamps")}`,
            dataIndex: `timestamp`,
            key: "timestamp",
            ellipsis: { showTitle: false },
            width: "20%",
            sorter: () => { },
            render: (text: Date) => (
                <Tooltip placement="top" title={dayjs(text).format('YYYY-MM-DD HH:mm:ss')} color='#4d4c4c'>
                    {dayjs(text).format('YYYY-MM-DD HH:mm:ss')}
                </Tooltip>
            ),
        },
        {
            title: `${t("auditLogs.actionType")}`,
            dataIndex: `actionType`,
            key: "actionType",
            ellipsis: { showTitle: false },
            width: "20%",
            sorter: () => { },
            render: (text: string) => (
                <Tooltip placement="top" title={text == "ReportAccess" ? "Report Access" : text} color='#4d4c4c'>
                    {text == "ReportAccess" ? "Report Access" : text}
                </Tooltip>
            ),
        },
        {
            title: `${t("auditLogs.details")}`,
            dataIndex: `details`,
            key: "details",
            ellipsis: { showTitle: false },
            width: "20%",
            render: (text: string) => (
                <Tooltip placement="top" title={text} color='#4d4c4c'>
                    {text}
                </Tooltip>
            ),
        },
    ]
}