import { APPEARANCE, APPEARANCE_HOVER, INPUT_PADDING, ROUNDED, TEXT_SIZE_WEIGHT } from "../../commonComponentStyles";
import { ButtonInterface } from "./Button.types";

const isRounded = (rounded: string) => {
  return rounded === "none" ? "" : "rounded";
};

const Button = ({
  appearance = "primary",
  children,
  className = "",
  onClick,
  rounded = "none",
  size = "default",
  textSize = "default",
  type = "button",
  value,
}: ButtonInterface) => (
  <button
    className={`
      w-full
      ${isRounded(rounded)}
      ${APPEARANCE[appearance]}
      ${INPUT_PADDING[size]}
      ${APPEARANCE_HOVER[appearance]}
      ${ROUNDED[rounded]}
      ${TEXT_SIZE_WEIGHT[textSize]}
      ${className}
    `}
    onClick={onClick}
    type={type}
    value={value}
  >
    {children}
  </button>
);

export default Button;
