import Button from "../../../input/Button/AccordionButton";
import { AccordionButtonInterface } from "./AccordionButton.types";

const AccordionButton = ({
  appearance = "accordion",
  className = "",
  children,
  onClick,
  selected,
  size = "md",
  textSize = "md",
  value,
  qtty
}: AccordionButtonInterface) => {
  return (
    <Button
      appearance={appearance}
      className={`${selected ? "bg-[#ED1A3B] text-white " : "  bg-bdo-bg-04-white"}${className}`}
      onClick={onClick}
      size={size}
      textSize={textSize}
      value={value}
    >
      <div className="flex items-center justify-between hyphens-auto break-words" style={{ wordBreak: "break-word" }}>
        {children}
        {qtty &&
          <div className="bg-[#ED1A3B] rounded-full flex items-center justify-center w-5 h-5">
            <span className="text-[13px] text-white font-normal ">{qtty}</span>
          </div>
        }
      </div>
    </Button>
  );
};

export default AccordionButton;