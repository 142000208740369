import Button from "../../components/input/Button/AccordionButton";
import ContentSection from "../../components/layout/content/ContentSection/ContentSection";
import { SuggestedForYouInterface } from "./SuggestedForYou.types";

const handleButtonClick = (event: React.ChangeEvent<HTMLInputElement>) => {
  console.log("handleButtonClick " + event.target.value);
};

const handleClickDiscoverReports = (event: React.ChangeEvent<HTMLInputElement>) => {
  console.log("handleClickDiscoverReports");
};

const suggestedForYou = () => (
  <div className="flex flex-row gap-6 w-full h-full">
    <Button
      appearance="primary"
      className=""
      onClick={handleButtonClick}
      rounded="none"
      size="md"
      textSize="lg"
      type="button"
      value="suggestion_1"
    >
      Suggestion 1
    </Button>
    <Button
      appearance="primary"
      className=""
      onClick={handleButtonClick}
      rounded="none"
      size="md"
      textSize="lg"
      type="button"
      value="suggestion_2"
    >
      Suggestion 2
    </Button>
    <Button
      appearance="primary"
      className=""
      onClick={handleButtonClick}
      rounded="none"
      size="md"
      textSize="lg"
      type="button"
      value="suggestion_3"
    >
      Suggestion 3
    </Button>
  </div>
);

const SuggestedForYou = ({
  className = ""
}: SuggestedForYouInterface) => (
  <ContentSection
    action={handleClickDiscoverReports}
    actionClassName="underline"
    actionLabel={"DISCOVER REPORTS"}
    className={`
      col-span-2 ${className}
    `}
    hasControlActions
    title="SUGGESTED FOR YOU"
  >
    {suggestedForYou()}
  </ContentSection>
);

export default SuggestedForYou;
