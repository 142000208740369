export const APPEARANCE: any = {
  default: "text-bdo-text-01-charcoal bg-bdo-button-secondary-charcoal-pale",
  accordion: "text-bdo-text-01-charcoal ",
  input: "text-bdo-text-01-charcoal bg-bdo-bg-04-white",
  primary: "text-bdo-text-01-charcoal bg-bdo-button-secondary-charcoal-pale",
  secondary: "text-bdo-text-01-charcoal bg-bdo-button-primary-red",
};

export const APPEARANCE_HOVER: any = {
  default: "hover:hover:text-bdo-text-02-white hover:bg-bdo-accordion-rollover-charcoal",
  primary: "hover:hover:text-bdo-text-02-white hover:bg-bdo-accordion-rollover-charcoal",
  secondaryGray: "hover:text-bdo-text-02-white hover:bg-bdo-button-primary-rollover-burgundy",
};

export const BORDER_TOP_ARROW_POSITION: any = {
  default: "after:left-1 before:left-1",
  left: "after:left-0 before:left-0",
  left_2: "after:left-1 before:left-1",
  left_4: "after:left-2 before:left-2",
  left_8: "after:left-3 before:left-3",
  left_16: "after:left-4 before:left-4",
};

export const FONT_SIZE_VALUE_ONLY: any = {
  default: "1rem",
  sm: "0.875rem",
};

export const INPUT_PADDING: any = {
  default: "px-6 py-2",
  lg: "px-[4.5rem] py-2",
  md: "px-4 py-2",
  sm: "px-4 py-2",
};

export const LINE_HEIGHT_VALUE_ONLY: any = {
  default: "1.5rem",
  sm: "1.25rem",
};

export const ROUNDED: any = {
  default: "rounded-md",
  lg: "rounded-lg",
  md: "rounded-md",
  none: "",
  sm: "rounded-sm",
};

export const ROUNDED_LEFT: any = {
  default: "rounded-l-md",
  lg: "rounded-l-lg",
  md: "rounded-l-md",
  none: "",
  sm: "rounded-l-sm",
};

export const ROUNDED_RIGHT: any = {
  default: "rounded-r-md",
  lg: "rounded-r-lg",
  md: "rounded-r-md",
  none: "",
  sm: "rounded-r-sm",
};

export const ROUNDED_VALUE_ONLY: any = {
  default: "0.375rem",
  sm: ".125rem",
  md: "0.375rem",
  lg: "0.5rem",
};

export const TEXT_SIZE_WEIGHT: any = {
  default: "text-md font-normal",
  lg: "text-lg font-normal",
  md: "text-md font-normal",
  sm: "text-sm font-normal",
  xl: "text-xl font-normal",
};

export const WIDTH_VALUE_ONLY: any = {
  default: "fit_content",
  sm: "300px",
  md: "500px",
  lg: "700px",
};
