import { Navigate, Route, Routes } from "react-router-dom";
import { BrowserRoutesInterface } from "./BrowserRoutes.types";
import Reports from "../pages/Reports";
import UserManage from "src/pages/UserManage";
import Home from "src/pages/Home";
import ReportManage from "src/pages/ReportManage";
import CategoryManage from "src/pages/CategoryManage";
import { ReportPageProvider } from "src/contextAPI/reportPageContext";
import { ReportManagementPageContextProvider } from "src/contextAPI/reportManagementPageContext";
import { useContext } from "react";
import { GlobalContext } from "src/contextAPI/globalContext";
import { CategoryManagementPageContextProvider } from "src/contextAPI/categoryManagementPageContext";
import { UserManagementPageContextProvider } from "src/contextAPI/userManagementPageContext";
import ActivityReportsOverview from "src/pages/ActivityReportsOverview";
import { AuditLogsPageContextProvider } from "src/contextAPI/auditLogsPageContext";

const BrowserRoutes = ({ setHeaderTitle }: BrowserRoutesInterface) => {
  const { isAppAdmin, isReportAdmin } = useContext(GlobalContext);
  return (
    <div className="h-full overflow-auto no-scrollbar flex bg-white min-w-[700px] relative">
      <Routes>
        <Route path="/dashboard" element={<Home setHeaderTitle={setHeaderTitle} />} />

        <Route path="/auditlogs" element={
          isAppAdmin
            ?
            <ActivityReportsOverview setHeaderTitle={setHeaderTitle} />
            : <Navigate to="/reports" replace />

        } />

        <Route path="/usermanagement" element={
          isAppAdmin
            ? <UserManagementPageContextProvider>
              <UserManage setHeaderTitle={setHeaderTitle} />
            </UserManagementPageContextProvider>
            : <Navigate to="/reports" replace />

        } />
        <Route path="/reportmanagement"
          element={
            isAppAdmin || isReportAdmin
              ? <ReportManagementPageContextProvider> <ReportManage setHeaderTitle={setHeaderTitle} /></ReportManagementPageContextProvider>
              : <Navigate to="/reports" replace />
          }
        />
        <Route path="/categorymanagement"
          element={
            isAppAdmin
              ? <CategoryManagementPageContextProvider>
                <CategoryManage setHeaderTitle={setHeaderTitle} />
              </CategoryManagementPageContextProvider>
              : <Navigate to="/reports" replace />
          }
        />
        <Route
          path="/reports?/:reportGuid?/:reportId?/:workspaceGuid"
          element={<Reports setHeaderTitle={setHeaderTitle} />}
        />
        <Route path="/" element={<Navigate to="/reports" replace />} />
        <Route path="*" element={<Reports setHeaderTitle={setHeaderTitle} />} />
      </Routes>
    </div>
  );
};

export default BrowserRoutes;
