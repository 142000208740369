import React, { useEffect, useState } from 'react'
import { PageInterface } from './index.type'
import { useTranslation } from 'react-i18next';
import { MdAccessAlarms } from 'react-icons/md';
import { AiOutlineAudit } from 'react-icons/ai';
import AuditLogs from 'src/components/Logs/AuditLogs';
import { TbReportAnalytics } from 'react-icons/tb';
import { AuditLogsPageContextProvider } from 'src/contextAPI/auditLogsPageContext';
import FrequentlyAccessed from 'src/components/Logs/FrequentlyAccessed';

const ActivityReportsOverview = ({ setHeaderTitle }: PageInterface) => {
    const { t } = useTranslation();
    const [page, setPage] = useState(0)
    useEffect(() => { setHeaderTitle(t("auditLogs.activityReportsOverview")); }, [t]);

    return (
        <main className="flex w-full pt-7">
            <div className='w-full px-11'>
                <header className='flex justify-between items-center'>
                    <h1 className='text-3xl font-proximanova font-semibold'>{page === 0 ? t("auditLogs.title") : t("auditLogs.frequentlyAccess")}</h1>
                    <div className='flex gap-8 bg-[#efefef] py-10 px-12 absolute right-0 general-shadow '>
                        <button
                            className={`flex gap-1 items-center  cursor-pointer ${page === 0 ? "font-semibold" : "hover:text-red-600"} relative text-[#585757]`}
                            onClick={() => setPage(0)}>
                            <AiOutlineAudit />
                            <span className='text-sm proximaNova'>{t("auditLogs.title")}</span>
                            <hr className={`${page === 0 && 'bg-[#e81a3b]'} border-0 h-1 w-full absolute -bottom-2`} />
                        </button>
                        <button className={`flex gap-1 items-center  cursor-pointer ${page === 1 ? "font-semibold" : "hover:text-red-600"} relative text-[#585757]`}
                            onClick={() => setPage(1)}>
                            <TbReportAnalytics />
                            <span className='text-sm proximaNova'>{t("auditLogs.frequentlyAccess")}</span>
                            <hr className={`${page === 1 && 'bg-[#e81a3b]'} border-0 h-1 w-full absolute -bottom-2`} />
                        </button>
                    </div>
                </header>
                {
                    page === 0
                        ? <AuditLogsPageContextProvider><AuditLogs /></AuditLogsPageContextProvider>
                        : <FrequentlyAccessed />
                }
            </div>
        </main>
    )
}

export default ActivityReportsOverview