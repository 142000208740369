import { BarLoader } from '../Loader'
import { Columns } from './tableColumns'
import { Table } from 'antd'
import { UserInterface } from './index.type'
import { UserManagementPageContext } from 'src/contextAPI/userManagementPageContext'
import React, { useContext, useEffect, useRef } from 'react'
import useUserManagementPage from 'src/hooks/useUserManagementPage'

interface UserTableManageProps {
  callApiLoading: boolean
}

function UserTableManage({ callApiLoading }: UserTableManageProps) {
  const { totalAccount, pagination: paginationContext, userList, setPagination, filters } = useContext(UserManagementPageContext)
  const { fetchUserList, contextHolder } = useUserManagementPage()
  const paginationRef = useRef(paginationContext);

  //--- Function to handle the table change
  const handleTableChange = async (pagination: any, filter: any, sorter: any) => {
    let sort = {}
    //If sorter is coming from the table, then add the sorting parameters to the query
    if (sorter?.order) {
      let direction = sorter.order === 'ascend' ? true : false
      let sortBy = sorter.field.charAt(0).toUpperCase() + sorter.field.slice(1);
      sort = {
        sortBy: sortBy,
        ascending: direction
      }
    }
    setPagination({ ...pagination, pageSize: pagination.pageSize, pageIndex: pagination.current })

    let extraHeaders = {
      pagesize: pagination.pageSize,
      pageindex: pagination.current,
      isDeleted: filters.status.id === "0" ? null : filters.status.id === "1" ? false : true,
      isSystemAdmin: filters.permissions.id === "0" ? null : filters.permissions.id === "1" ? true : false,
      ...sort
    }
    await fetchUserList({ extraquery: extraHeaders })
  }

  //============================Update every 5 minutes=========================
  // Update the ref when the context changes so that the latest pagination values are always used
  useEffect(() => {
    paginationRef.current = paginationContext;
  }, [paginationContext]);

  const refreshUserList = async () => {
    await fetchUserList({
      extraquery: {
        pagesize: paginationRef.current.pageSize,
        pageindex: paginationRef.current.pageIndex,
        isDeleted: filters.status.id === "0" ? null : filters.status.id === "1" ? false : true,
        businessUnits: filters.businessUnits.id,
        isSystemAdmin: filters.permissions.id === "0" ? null : filters.permissions.id === "1" ? true : false,
        // status: filters.status.id,
      }
    })
  };

  useEffect(() => {
    refreshUserList();
    const intervalId = setInterval(refreshUserList, 300000);
    return () => clearInterval(intervalId);
  }, []);
  //==============================================================

  return (<div>
    <Table
      dataSource={userList}
      columns={Columns()}
      loading={{ indicator: <BarLoader />, spinning: callApiLoading }}
      onChange={handleTableChange}
      rowKey={(record: UserInterface) => record.userObjectId}
      showSorterTooltip={false}
      pagination={{
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '50'],
        pageSize: paginationContext.pageSize,
        current: paginationContext.pageIndex,
        total: totalAccount
      }}
      scroll={{ y: "calc(100vh - 300px)" }}
    />
    {contextHolder}
  </div>
  )
}

export default UserTableManage