import "./style.css"
import { initialDataFormAddReport } from "src/pages/index.type"
import { MdAdd } from 'react-icons/md'
import { Modal, message } from 'antd';
import { optionRadioBtnType } from "../FilterSections/index.type"
import { ReportManagementPageContext } from "src/contextAPI/reportManagementPageContext"
import { useNotification } from "src/hooks/useNotification"
import { useReportManagePage } from "src/hooks/useReportManagePage"
import { useTranslation } from "react-i18next"
import Button from '../input/Button'
import Dropdown from "../FilterSections/Dropdown"
import NameAndDescription from "./NameAndDescription"
import RadioButtons from "../FilterSections/RadioButtons"
import React, { useContext, useEffect, useState } from 'react'
import TextInputWithSearchIcon from "../input/TextInputWithSearchIcon"
import useCallApi from "src/hooks/useCallApi"
import { GlobalContext } from "src/contextAPI/globalContext"
import { BiSolidReport } from "react-icons/bi"
import { useConstant } from "src/hooks/useConstant"
import CategoryAutocomplete from "../CategoryAutocomplete";

const ReporAddModule = () => {
    const { t } = useTranslation();
    const [searchInputValue, setSearchInputValue] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [showResults, setShowResults] = useState(false);
    const { filters, pagination, setReportsPerUser } = useContext(ReportManagementPageContext)
    const { oid } = useContext(GlobalContext)
    const { fetchReportList } = useReportManagePage()
    const { callApi, callApiLoading, isError } = useCallApi();
    const { contextHolder, notification } = useNotification()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isValidationOn, setIsValidationOn] = useState(false);
    const INITIALDATAFORM: initialDataFormAddReport = {
        powerBiReportId: "",
        workspaceId: "",
        category: {
            id: "",
            name: ""
        },
        reportName: {
            en: "",
            fr: ""
        },
        description: {
            en: "",
            fr: ""
        },
        isPrivate: false,
        authMethod: {
            id: "1",
            name: ""
        }
    };
    const OPTIONS_AUTH_METHOD = [
        { name: t("reportManagement.editmodule.userContext"), id: "1" },
        { name: t("reportManagement.editmodule.servicePrinciple"), id: "2" }
    ]

    const [dataForm, setDataForm] = useState<initialDataFormAddReport>(INITIALDATAFORM);

    // --> to validate the format of PowerBiReportId and WorkspaceId (8-4-4-4-12)
    const validateFormat = (inputvalue: string) => /^[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}$/.test(inputvalue);

    const handleOk = async () => {
        console.log(dataForm)
        setIsValidationOn(true);
        if (!validateFormat(dataForm.powerBiReportId) || !validateFormat(dataForm.workspaceId) || dataForm.category.id === "" || dataForm.reportName.en === "" || dataForm.reportName.fr === "") return;
        const reportNameTrim = { en: dataForm.reportName.en.trim(), fr: dataForm.reportName.fr.trim() };
        const descriptionTrim = { en: dataForm.description.en.trim(), fr: dataForm.description.fr.trim() };

        const newReport = {
            powerBiReportId: dataForm.powerBiReportId,
            workspaceId: dataForm.workspaceId,
            nameEn: reportNameTrim.en,
            nameFr: reportNameTrim.fr,
            descriptionEn: descriptionTrim.en,
            descriptionFr: descriptionTrim.fr,
            categoryId: dataForm.category.id,
            authMethod: dataForm.authMethod.id,
            isPrivate: dataForm.isPrivate,
            submittedByUserId: oid,
            displayOrder: 0
        }
        let res = await callApi({ endpoint: 'AddReport', body: newReport, method: 'POST' }) //--> api/report/add

        if (res.status === 400 && res?.message === "Report already exists.") {
            notification({ content: { name: t("t2"), message: t("reportManagement.addmodule.alreadyExists") }, type: 'error' })
            return
        }
        if (res.status === 400) {
            notification({ content: { name: t("t2"), message: t("reportManagement.addmodule.wrongGuid") }, type: 'error' })
            return
        }
        if (res.statusText) {
            notification({ content: { name: t("t2"), message: res.statusText }, type: 'error' })
            return
        }

        if (res.status !== 200) {
            console.log('error', res)
            notification({ content: { name: t("t2"), message: `${res.status} ${res && res.message || isError && isError.message} ` }, type: 'error' })
            return
        }

        // --> to fetch the report list after adding a new report
        await fetchReportList({ extraquery: { pagesize: pagination.pageSize, pageindex: pagination.pageIndex, categoryid: filters.dropDownValue.id, isPrivate: filters.permissionValue.id === "1" ? false : filters.permissionValue.id === "2" ? true : undefined } })
        // --> to update the number of reports per user
        setReportsPerUser((prev: number) => prev + 1)
        notification({ content: { name: t("success"), message: t("reportManagement.addmodule.addedSuccessfully") }, type: 'success' })
        setDataForm(INITIALDATAFORM);
        setSearchInputValue(""); 
        setSearchQuery(""); 
        setShowResults(false);
        setIsModalOpen(false);
    }
    const handleCategorySelect = (category: { id: string, name: string }) => {
        setDataForm({ ...dataForm, category: category })
        setSearchInputValue(category.name);
        setShowResults(false);
    }

    return (
        <>
            <Button text={t("reportManagement.addmodule.title")} btnType="main" icon={<MdAdd />} className="rounded-[4px] h-[40px] px-5 focus-visible:outline-none" handleClick={() => setIsModalOpen(!isModalOpen)} />
            <Modal
                className='addModule min-w-[700px] h-[700px] height-modal'
                title={<div className='flex items-center gap-3'>
                    <span className='text-[18px] text-[#616161]'>{t("reportManagement.addmodule.title")}</span>
                    <BiSolidReport size={15} color='#616161' />
                </div>}
                okText={t("t7")}
                cancelText={t("t8")}
                open={isModalOpen}
                onOk={handleOk}
                confirmLoading={callApiLoading} //--->TODO: add a loading state when the data is sending to the server
                onCancel={() => { setIsModalOpen(false); setDataForm(INITIALDATAFORM); setIsValidationOn(false); setSearchInputValue(""); setSearchQuery(""); setShowResults(false); }}
            >
                <hr />
                <section className='flex flex-col justify-start gap-5 mt-5'>
                    <section className='mb-3 flex flex-col w-full gap-3'>
                        <div className="relative">
                            <div className="flex gap-1">
                                <label className='block text-[14px] font-semibold mb-1'>Power BI Report ID</label>
                                {!validateFormat(dataForm.powerBiReportId) && <span className="text-red-600 text-2xl">*</span>}
                            </div>
                            <TextInputWithSearchIcon
                                handleChange={(e) => setDataForm({ ...dataForm, powerBiReportId: e.target.value.toUpperCase() })}
                                placeholder={t("reportManagement.addmodule.powerbiPlaceholder")}
                                value={dataForm.powerBiReportId}
                                isToSearch={false}
                                withMask={true}
                            />
                            {isValidationOn && !validateFormat(dataForm.powerBiReportId) && <span className="absolute -bottom-4 text-xs text-red-600 right-0">{t("reportManagement.addmodule.fieldRequired")}</span>}
                        </div>
                        <div className="relative">
                            <div className="flex gap-1">
                                <label className='block text-[14px] font-semibold mb-1'>Workspace ID</label>
                                {!validateFormat(dataForm.workspaceId) && <span className="text-red-600 text-2xl">*</span>}
                            </div>
                            <TextInputWithSearchIcon
                                handleChange={(e) => setDataForm({ ...dataForm, workspaceId: e.target.value.toUpperCase() })}
                                placeholder={t("reportManagement.addmodule.workspaceIdPlaceholder")}
                                value={dataForm.workspaceId}
                                isToSearch={false}
                                withMask={true}
                            />
                            {isValidationOn && !validateFormat(dataForm.workspaceId) && <span className="absolute -bottom-4 text-xs text-red-600 right-0">{t("reportManagement.addmodule.fieldRequired")}</span>}
                        </div>
                        <div className="relative">
                            <CategoryAutocomplete
                                setSearchInputValue={setSearchInputValue}
                                searchInputValue={searchInputValue}
                                setSearchQuery={setSearchQuery}
                                searchQuery={searchQuery}
                                handleSelect={handleCategorySelect}
                                setShowResults={setShowResults}
                                showResults={showResults}
                            />
                            {isValidationOn && dataForm.category.id === "" && <span className="absolute -bottom-4 text-xs text-red-600 right-0">{t("reportManagement.addmodule.fieldRequired")}</span>}
                        </div>
                        <NameAndDescription dataForm={dataForm} setDataform={setDataForm} isValidationOn={isValidationOn} />
                        <div className='w-full '>
                            <RadioButtons
                                options={OPTIONS_AUTH_METHOD}
                                radioButtonHandler={(e: optionRadioBtnType) => setDataForm({ ...dataForm, authMethod: e })}
                                title={t("reportManagement.addmodule.authMethod")}
                                value={dataForm.authMethod.id}
                            />
                        </div>
                        <div className="absolute bottom-8">
                            <div className="flex items-center gap-2">
                                <input
                                    className="cursor-pointer checkbox"
                                    type='checkbox'
                                    onChange={(e) => setDataForm({ ...dataForm, isPrivate: e.target.checked })}
                                    checked={dataForm.isPrivate}
                                />
                                <label>{t("reportManagement.addmodule.makePrivate")}</label>
                            </div>
                        </div>
                    </section>
                </section>
            </Modal >
            {contextHolder}
        </>
    )
}

export default ReporAddModule