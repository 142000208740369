/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useClickAway } from "@uidotdev/usehooks";
import { useMsal } from "@azure/msal-react";
import { getUserPhoto48Url } from "src/api/resource/msGraphResource";
import { useTranslation } from "react-i18next";

const InfoUser = () => {
  // state with all the user information
  const [user, setUser] = useState({ photo: null, initials: "", name: "" });
  const [openModal, setOpenModal] = useState(false);
  const { accounts, instance } = useMsal();
  const { t } = useTranslation()

  const label: any = {
    en: "Sign Out",
    fr: "Déconnexion",
  };
  const ref: any = useClickAway(() => {
    setOpenModal(false);
  });
  useEffect(() => {
    getUserInfo();
  }, []);
  // Method to GET user info
  const getUserInfo = async () => {
    const name = accounts[0].name || "NA";
    const firstName = name?.split(",")[1];
    const secondName = name?.split(",")[0];
    const firstInitial = name?.split(",")[1].replace(/\s/g, "")[0];
    const secondInitial = name?.split(",")[0].replace(/\s/g, "")[0];
    setUser({ ...user, name, initials: `${firstInitial}${secondInitial}` });
    await getUserPhoto48Url(instance, (response: any) => {
      if (response) {
        setUser(
          {
            photo: response,
            initials: `${firstInitial}${secondInitial}`,
            name: `${firstName} ${secondName}`
          });
      };
    });
  };

  return (
    <div className="flex items-center justify-center">
      <div className="relative">
        <figure
          className="w-[34px] h-[34px] mr-2 rounded-full overflow-hidden bg-[#dadbe0] flex items-center justify-center cursor-pointer"
          onClick={e => setOpenModal(!openModal)}
        >
          {user.photo ? (
            <img src={`${user.photo}`} className="w-full" alt="thumbnail" />
          ) : (
            <h3 className="font-extrabold color text-[#ad1931]">{user.initials}</h3>
          )}
        </figure>
        {openModal && (
          <aside
            ref={ref}
            className="text-white bg-[#e81a3b] absolute w-48 bottom-[-38px] p-2 right-1 pl-6 cursor-pointer text-sm hover:bg-[#c70c2e]"
            onClick={() => instance.logoutRedirect()}
          >
            {t('t1')}
          </aside>
        )}
      </div>
      <h5 className="font-semibold text-sm" onClick={() => setOpenModal(false)}>
        {user.name}
      </h5>
    </div>
  );
};
export default InfoUser;
