import React, { useContext } from 'react'
import { AiOutlineSetting } from 'react-icons/ai'
import { NavLink, useLocation } from 'react-router-dom'
import { t } from 'i18next';
import { GlobalContext } from 'src/contextAPI/globalContext';
import { useTranslation } from 'react-i18next';
interface styleProps {
    color: string,
    size: number
}

const Settings = ({ color, size }: styleProps) => {
    const { t } = useTranslation();
    const location = useLocation();
    const { isAppAdmin } = useContext(GlobalContext);
    const isActiveRoute = (route: string) => {
        return location.pathname === route;
    };
    return (

        <div className="relative group">
            <div className="w-[54px]">
                <div className={`h-[50px] w-full flex justify-center cursor-pointer items-center hover:bg-[#1b191933] ${isActiveRoute('/usermanagement') || isActiveRoute('/reportmanagement') || isActiveRoute('/categorymanagement') ? 'active' : ''}`}>
                    <span className="bg-bdo-primary-red"></span>
                    <AiOutlineSetting color={color} size={size} />
                </div>
            </div>
            <ul className="bg-[#363636] w-[290px] absolute flex-col flex top-0 left-[-400px] group-hover:left-[55px] transition-opacity duration-300 group-hover:flex">
                <li className="text-white p-4 pb-2">{t("settings")}</li>
                <li><hr className="bg-bdo-primary-red w-[90px] border-none h-[5px] text-sm" /></li>
                {isAppAdmin && <NavLink to={"/categorymanagement"} className="text-white hover:bg-[#0f0e0e33] p-4 cursor-pointer text-sm">{t("categManagement.t1")}</NavLink>}
                <NavLink to={"/reportmanagement"} className="text-white hover:bg-[#0f0e0e33] p-4 cursor-pointer text-sm">{t("reportManagement.t1")}</NavLink>
                {isAppAdmin && <NavLink to={"/usermanagement"} className="text-white hover:bg-[#0f0e0e33] p-4 cursor-pointer text-sm">{t("userManagement.pagetitle")}</NavLink>}
            </ul>
        </div>
    )
}

export default Settings