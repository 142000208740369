import { Tooltip } from 'antd';
import React from 'react'
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

export function Columns() {
    const { t } = useTranslation();

    return [
        {
            title: `${t("reportManagement.table.titleName")}`,
            dataIndex: `reportName`,
            key: "reportName",
            ellipsis: { showTitle: false },
            sorter: (a: any, b: any) => a.reportName.localeCompare(b.reportName),
            render: (text: string) => (
                <Tooltip placement="top" title={text} color='#4d4c4c'>
                    {text}
                </Tooltip>
            ),
        },
        {
            title: `${t("auditLogs.date")}`,
            dataIndex: `date`,
            key: "date",
            ellipsis: { showTitle: false },
            sorter: (a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime(),
            render: (text: Date) => (
                <Tooltip placement="top" title={dayjs(text).format('YYYY-MM-DD')} color='#4d4c4c'>
                    {dayjs(text).format('YYYY-MM-DD')}
                </Tooltip>
            ),
        },
        {
            title: `${t("auditLogs.count")}`,
            dataIndex: `count`,
            align: "center" as 'center',
            key: "count",
            ellipsis: { showTitle: false },
            sorter: (a: any, b: any) => a.count - b.count,
            render: (text: string) => (
                <Tooltip placement="top" title={text} color='#4d4c4c'>
                    {text}
                </Tooltip>
            ),
        }
    ]
}