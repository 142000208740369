import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
export const useConstant = () => {
    const { t } = useTranslation();



    const USER_MANAGE_BUSINESS_UNITS = [
        { name: t("userManagement.all"), id: '0' },
        { name: 'Business Unit 1', id: '1' },
        { name: 'Business Unit 2', id: '2' },
        { name: 'Business Unit 3', id: '3' },
    ]

    const USER_MANAGE_PERMISSION_OPTIONS = [
        { id: '0', name: t("userManagement.all") },
        { id: '1', name: t("userManagement.admin") },
        { id: '2', name: t("userManagement.user") },
    ]

    const USER_MANAGE_STATUS_OPTIONS = [
        { name: t("userManagement.all"), id: '0' },
        { name: t("userManagement.active"), id: '1' },
        { name: t("userManagement.inactive"), id: '2' },
    ]

    const USER_MANAGE_INITIALFILTERVALUES = {
        status: USER_MANAGE_STATUS_OPTIONS[1],
        permissions: USER_MANAGE_PERMISSION_OPTIONS[0],
        businessUnits: USER_MANAGE_BUSINESS_UNITS[0],
        isFilterActive: false,
    }

    const REPORT_ADD_MODULE_HTTP_RESPONSE = {
        "Report already exists.": t("reportManagement.addmodule.reportAlreadyExists"),
        "PowerBI Report not found.": t("reportManagement.addmodule.powerbiReportNotFound")
    }

    const AUDITLOGS_ACTIONTYPE = [
        { id: '0', name: t("all") },
        { id: '1', name: t("create") },
        { id: '2', name: t("update") },
        { id: '3', name: t("delete") },
        { id: '4', name: t("reportAccess") },
        { id: '6', name: t("share") }
    ]

    const AUDITLOGS_FILTERS = {
        date: { from: "", to: "" },
        report: { id: "", name: "" },
        userName: { id: "", name: "" },
        actionType: { id: "0", name: t("all") }
    }

    const FREQUENTLY_ACCESS_FILTERS = {
        report: { name: "", id: "" },
        date: [dayjs(), dayjs()]
    }

    return {
        USER_MANAGE_BUSINESS_UNITS,
        USER_MANAGE_PERMISSION_OPTIONS,
        USER_MANAGE_STATUS_OPTIONS,
        USER_MANAGE_INITIALFILTERVALUES,
        REPORT_ADD_MODULE_HTTP_RESPONSE,
        AUDITLOGS_ACTIONTYPE,
        AUDITLOGS_FILTERS,
        FREQUENTLY_ACCESS_FILTERS
    }
}
