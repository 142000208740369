import { FaUserAlt, FaUsers } from 'react-icons/fa'
import { GlobalContext } from 'src/contextAPI/globalContext';
import { handleSearchUserAndGroupType, responseFetchAccessList, UserAndGroupAssignmentType } from './index.type';
import { useNotification } from 'src/hooks/useNotification';
import { useTranslation } from 'react-i18next';
import Modal from 'antd/es/modal/Modal'
import React, { useContext, useEffect, useState } from 'react'
import useCallApi from 'src/hooks/useCallApi';
import UserAndGroupAssignmentTable from './UserAndGroupAssignmentTable';
import { useReportManagePage } from 'src/hooks/useReportManagePage';
import { ReportManagementPageContext } from 'src/contextAPI/reportManagementPageContext';

const UserAndGroupAssignment = ({ report }: UserAndGroupAssignmentType) => {
  const { t, i18n } = useTranslation();
  const { notification, contextHolder } = useNotification();
  const { oid, isAppAdmin } = useContext(GlobalContext);
  const { fetchReportList } = useReportManagePage();
  const { filters, categories, pagination, setReportsPerUser } = useContext(ReportManagementPageContext)
  const [searchUserInputValue, setSearchUserInputValue] = useState({ user: '', group: '' })
  const [searchQuery, setSearchQuery] = useState({ user: '', group: '' })
  const [showResults, setShowResults] = useState({ user: false, group: false })
  const { callApiLoading, callApi, isError } = useCallApi()
  const [listOfTable, setListOfTable] = useState<responseFetchAccessList>({ user: [], group: [] })
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: { id: 1, name: t('reportManagement.userAndGroups.userTitle') }, //---> I must set nameEn
  });


  useEffect(() => {
    setModalInfo({ ...modalInfo, title: { id: 1, name: t('reportManagement.userAndGroups.userTitle') } })
  }, [i18n.language])


  //To populate the table with the list of users and groups in the first render
  const fetchData = async () => {
    setModalInfo({ ...modalInfo, isOpen: true })
    let extraHeaders = {
      userObjectId: oid,
      reportId: report.id,
      accessType: 1
    }
    let user_response = await callApi({ endpoint: 'AssignmentList', extraHeaders })
    let group_response = await callApi({ endpoint: 'AssignmentList', extraHeaders: { ...extraHeaders, accessType: 2 } })
    setListOfTable({ user: user_response, group: group_response })
  }

  //Method to handle the save button
  const handleOk = async () => {

    const objectToSend = {
      reportAccessList: [...listOfTable.user, ...listOfTable.group].map((item) => {
        const { user, ...rest } = item;
        return {
          ...rest,
          email: user?.email || null, //If the user is null, then the email will be null
        };
      }),
      submittedBy: oid,
      reportId: report.id,
    };

    if (objectToSend.reportAccessList.length === 0) {
      notification({ content: { name: t("t2"), message: t('reportManagement.userAndGroups.listIsEmpty') }, type: 'error' })
      return
    }

    let response = await callApi({ endpoint: 'AssignmentUpdateUserAndGroup', body: objectToSend, method: 'POST' })
    if (response.status !== 200) {
      notification({ content: { name: t("t2"), message: `${response?.status}: ${response.statusText || response?.message}` }, type: 'error' })
      return
    }
    // --> to fetch the report list after adding a new report
    await fetchReportList({ extraquery: { pagesize: pagination.pageSize, pageindex: pagination.pageIndex, categoryid: filters.dropDownValue.id, isPrivate: filters.permissionValue.id === "1" ? false : filters.permissionValue.id === "2" ? true : undefined } })


    notification({ content: { name: t("success"), message: t('reportManagement.userAndGroups.notificationSuccess') }, type: 'success' })
    handleReset()
  }

  const handleReset = () => {
    setModalInfo({ isOpen: false, title: { id: 1, name: t('reportManagement.userAndGroups.userTitle') } })
    setSearchQuery({ user: '', group: '' })
    setSearchUserInputValue({ user: '', group: '' })
    setShowResults({ user: false, group: false })
  }

  //Mehod to handle the search Input of users and groups
  const handleSearch = async ({ event, assignmentType }: handleSearchUserAndGroupType) => {
    //1 for user and 2 for group
    if (assignmentType === 1) {
      setSearchUserInputValue({ ...searchUserInputValue, user: event.target.value })
      setSearchQuery({ ...searchQuery, user: event.target.value })
      setShowResults({ user: true, group: false })
    } else {
      setSearchUserInputValue({ ...searchUserInputValue, group: event.target.value })
      setSearchQuery({ ...searchQuery, group: event.target.value })
      setShowResults({ user: false, group: true })
    }
  }

  return (
    <>
      <FaUsers size={20} onClick={async () => await fetchData()} />
      <Modal
        className='group-assignment userandgroup-assignment min-w-[900px] h-[900px] height-modal'
        title={
          <div className='flex items-center gap-3'>
            <span className='text-[18px]'>{modalInfo.title.name}</span>
            {modalInfo.title.id === 1 ? <FaUserAlt size={15} /> : <FaUsers size={20} />}
          </div>
        }
        okText={t('reportManagement.userAndGroups.save')}
        cancelText={t('reportManagement.userAndGroups.cancel')}
        open={modalInfo.isOpen}
        onOk={handleOk}
        onCancel={() => handleReset()}
      >
        <aside className='flex gap-4 absolute top-0 right-0 text-[15px] py-4 pl-[22px] pr-16 rounded-bl-md bg-[#dddddd7a]'>
          <div>{t("reportManagement.userAndGroups.assignmentBy")}:</div>
          <div className='relative'>
            <h2
              className={`${modalInfo.title.id === 1 && 'font-semibold'} cursor-pointer hover:text-red-600 `}
              onClick={() => setModalInfo({ ...modalInfo, title: { id: 1, name: t('reportManagement.userAndGroups.userTitle') } })}
            >
              {t('reportManagement.userAndGroups.user')}
            </h2>
            <hr className={`${modalInfo.title.id === 1 && 'bg-[#e81a3b]'} border-0 h-1`} />
          </div>
          {/* ---- Groups Table ---- */}
          {
            isAppAdmin &&
            <div className='relative'>
              <h2
                className={`${modalInfo.title.id === 2 && 'font-semibold'} cursor-pointer hover:text-red-600`}
                onClick={() => setModalInfo({ ...modalInfo, title: { id: 2, name: t('reportManagement.userAndGroups.groupTitle') } })}
              >
                {t('reportManagement.userAndGroups.group')}
              </h2>
              <hr className={`${modalInfo.title.id === 2 && 'bg-[#e81a3b]'} border-0 h-1`} />
            </div>
          }
        </aside>
        <h2 className='text-[#e81a3b] py-3'>{i18n.language === "fr" ? report.nameFr : report.nameEn}</h2>
        <UserAndGroupAssignmentTable
          modalInfo={modalInfo}
          listOfTable={listOfTable}
          setListOfTable={setListOfTable}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          searchUserInputValue={searchUserInputValue}
          setSearchUserInputValue={setSearchUserInputValue}
          showResults={showResults}
          setShowResults={setShowResults}
          callApiLoading={callApiLoading}
          handleSearch={handleSearch}
          report={report}
        />
      </Modal>
      {contextHolder}
    </>
  )
}

export default UserAndGroupAssignment