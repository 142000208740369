import { Category, fetchReportListType } from 'src/pages/index.type'
import { ManageReportsData } from 'src/components/ReportTableManage/index.type'
import { ReportManagementPageContext } from 'src/contextAPI/reportManagementPageContext'
import { useContext, useState } from 'react'
import { useNotification } from './useNotification'
import { useTranslation } from 'react-i18next'
import useCallApi from './useCallApi'

export function useReportManagePage() {
  const { t, i18n } = useTranslation()
  const { setPagination, pagination, setTotalAccount, totalAccount, setCategories, setFilters, reportList, setReportList } = useContext(ReportManagementPageContext)
  const { contextHolder, notification } = useNotification()
  const { callApiLoading, callApi } = useCallApi()
  const [searchInputValue, setSearchInputValue] = useState('')
  const [showResults, setShowResults] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  

  const INITIALFILTERVALUE = {
    dropDownValue: { id: '', name: t('reportManagement.filters.all') },
    permissionValue: { id: '', name: 'All' },
    isFilterActive: false,
  }

  //To know how many reports are there in the list
  const getReportsQuantity = async ({ extraHeaders }:any) => {
    let res = await callApi({ endpoint: 'ReportList', extraHeaders}) ///api/report/search
    if (!res.isError) {
      return res.totalCount
    } else {
      return 0
    }
  }

  //To fetch the report list
  const fetchReportList = async ({ extraquery }: fetchReportListType) => {
    //extraquery gets the pagination values and the filters values
    let res: ManageReportsData = await callApi({ endpoint: 'ReportList', extraHeaders: extraquery }) ///api/report/search
    if (!res.isError) {
      setTotalAccount(res.totalCount || 0)
      setReportList(res.data || [])
      setPagination({ totalPages: res.totalPages || 1, pageIndex: res.pageNumber || 1, pageSize: res.pageSize || 1 })
    } else {
      notification({ content: { name: t("t2"), message: `${res.status} ${res.statusText}` }, type: 'error' })
    }
  }

  //To fetch the category list
  const fetchCategoryList = async () => {
    let res = await callApi({ endpoint: 'Categories' }) ///api/category
    let OPTIONS = []
    if (res?.length > 0) {
      //to convert the response to the format that the dropdown component can understand
      OPTIONS = res.map((category: Category) => {
        return {
          id: category.id,
          name: i18n.language.includes('en') ? category.categoryEn : category.categoryFr,
        }
      })
      OPTIONS.unshift({ id: '', name: t('reportManagement.filters.all') })
      setCategories(OPTIONS)
    } else {
      notification({ content: { name: t("t2"), message: `${res.status} ${res.statusText}` }, type: 'error' })
    }
  }

  //to handle the reset button click
  const handleReset = async () => {
    setSearchInputValue('')
    setFilters(INITIALFILTERVALUE)
    fetchReportList({
      extraquery: {
        pagesize: pagination.pageSize,
        pageindex: pagination.pageIndex,
      },
    })
    setTotalAccount(reportList.length)
  }

  //to handle the search input change
  const handleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(e.target.value)
    setSearchQuery(e.target.value)
    setShowResults(true)
  }

  return {
    contextHolder,
    handleSearch,
    handleReset,
    fetchCategoryList,
    fetchReportList,
    searchInputValue,
    setSearchInputValue,
    callApiLoading,
    callApi,
    totalAccount,
    showResults,
    setShowResults,
    searchQuery,
    setSearchQuery,
    getReportsQuantity
  }
}