import { AuditLogsPageContext } from 'src/contextAPI/auditLogsPageContext';
import { ReportType } from '../../ReportTableManage/index.type';
import { useDebounce } from 'src/hooks/useDebounce';
import { useNotification } from 'src/hooks/useNotification';
import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect } from 'react'
import useCallApi from 'src/hooks/useCallApi';

type AuditLogReportSearchType = {
    searchQuery: {
        reportName: string,
        userName: string
    },
    setSearchQuery: (value: any) => void,

    auditLogFilters: {
        date: {
            from: string,
            to: string
        },
        report: {
            id: string,
            name: string
        },
        userName: {
            id: string,
            name: string
        },
        actionType: {
            id: string,
            name: string
        }
    },
    setAuditLogFilters: (value: any) => void,
    setTextInputValues: (value: any) => void,
}


const AuditLogReportSearch = ({ auditLogFilters, searchQuery, setAuditLogFilters, setSearchQuery, setTextInputValues }: AuditLogReportSearchType) => {
    const { t, i18n } = useTranslation();
    // const { filters, searchQuery, setFilters, setSearchInputValue, setSearchQuery } = useContext(AuditLogsPageContext);
    const { callApi, callApiLoading, data, setData, isError } = useCallApi();
    const debouncedSearch = useDebounce(searchQuery.reportName, 500);
    const { notification, contextHolder } = useNotification();

    const fetchReportList = async () => {
        let res = await callApi({ endpoint: "ReportList", extraHeaders: { searchFilter: debouncedSearch, pagesize: 50, pageindex: 1, } });
        (!res.isError) && setData(res.data)
    }

    //Effect to handle search
    useEffect(() => {
        if (debouncedSearch === "") {
            setData([]);
            return;
        }
        //I need to get the 50 first results to show them in the search list under the search input and send filters if they are active
        fetchReportList();
    }, [debouncedSearch]);

    //Effect to handle error
    useEffect(() => {
        if (isError.status) {
            notification({ content: { name: t("t2"), message: isError.message }, type: 'error' })
        }
    }, [isError]);

    const handleReportSelect = (report: ReportType) => {
        setTextInputValues((prev: any) => ({ ...prev, reportName: i18n.language === 'fr' ? report.nameFr : report.nameEn }))
        setSearchQuery({ ...searchQuery, reportName: "" })
        setAuditLogFilters({ ...auditLogFilters, report: { name: report.nameEn, id: report.id } })
        setData([])
    }

    return (
        <>
            {debouncedSearch !== "" && (
                <ul className="absolute bg-[#fbfbfb] w-full max-h-96 overflow-y-auto scroll-smooth scroll-thin  z-10 general-shadow m-0 p-0">
                    {
                        callApiLoading
                            ? <li className="w-full py-3 pl-3 pr-2 cursor-pointer text-sm">{t("loading")}</li>
                            : <>
                                {data.length === 0 && searchQuery.reportName !== "" && (
                                    <li className="w-full py-3 pl-3 pr-2 cursor-pointer text-sm">{t("no_Matching_Results")}</li>
                                )}
                                {data.map((item: ReportType) => (
                                    <li key={item.id}
                                        className="w-full py-3 pl-3 pr-2 cursor-pointer hover:text-[#ed1a3b] hover:bg-[#fff2ee] text-sm"
                                        onClick={() => handleReportSelect(item)}
                                    >
                                        {i18n.language === 'fr' ? item.nameFr : item.nameEn}
                                    </li>
                                ))}
                            </>
                    }
                </ul>
            )}
            {contextHolder}
        </>
    )
}

export default AuditLogReportSearch