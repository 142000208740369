import "./style.css";

export const BarLoader = () => {
  return (
    <div className="flex justify-center items-center mt-96">
      <div className="loadership_XJWDM">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export const CircleLoader = () => {
  return <div className="spinner"></div>
}

