import { responseFetchAccessList, User, UserAccessInfo, UserSearchResultProps } from './index.type';
import { useDebounce } from 'src/hooks/useDebounce';
import { useNotification } from 'src/hooks/useNotification';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react'
import useCallApi from 'src/hooks/useCallApi';
import { useClickAway } from '@uidotdev/usehooks';

const UserAndGroupSearchResult = (
    {
        searchQuery,
        setSearchQuery,
        setSearchUserInputValue,
        assignmentType,
        listOfTable,
        setListOfTable,
        checkBoxValue,
        setShowResults,
        report
    }: UserSearchResultProps) => {
    const { t, i18n } = useTranslation();
    const debouncedSearch = useDebounce(searchQuery, 500);
    const { callApi, callApiLoading, data, setData, isError } = useCallApi();
    const { notification, contextHolder } = useNotification();
    const ref: any = useClickAway(() => { setShowResults({ user: false, group: false }) });
    const [listOfUsersAndGroups, setListOfUsersAndGroups] = useState([] as any[])

    const handleSelect = async (item: any) => {
        //1 for user and 2 for group        
        if (assignmentType === 1) {
            //verify if the user selected is already in the table
            let userExist = listOfTable.user.find((user: UserAccessInfo) => user.entraId === item.userObjectId)
            if (userExist) {
                notification({ content: { name: t("t2"), message: 'User already added' }, type: 'error' })
                return
            }

            //-------check if the user is assigned to PBI Service
            let queryIsAssigned = `powerBIReportId=${report.powerBIReportId}&entraId=${item.userObjectId}&accessType=1`
            let isAssigned = await callApi({ endpoint: "IsAssignedToPbiService", query: queryIsAssigned }) //--> API TO CALL TO CHECK IF THE USER IS ASSIGNED TO PBI SERVICE
            if (!isAssigned) {
                notification({ content: { name: t("t2"), message: `${t("reportManagement.userAndGroups.userMustBeAssignedFirst")} : ${item.email}` }, type: 'error' })
                return
            }

            //structure of the object to be added to the table
            let newUser: UserAccessInfo = {
                accessType: 1,
                entraId: item.userObjectId,
                groupName: null,
                email: item.email,
                hasAccess: true,
                isReportAdmin: checkBoxValue || false, //===> esto puedo ser un error si el group EMNDPOINT 
                user: item
            }
            //add the user to the table
            setListOfTable({ ...listOfTable, user: [...listOfTable.user, newUser] })

        } else {
            //verify if the group selected is already in the table
            let groupExist = listOfTable.group.find((group: any) => group.entraId === item.id)
            if (groupExist) {
                notification({ content: { name: t("t2"), message: 'Group already added' }, type: 'error' })
                return
            }

            //-------check if the GROUP is assigned to PBI Service
            let queryIsAssigned = `powerBIReportId=${report.powerBIReportId}&entraId=${item.id}&accessType=2`
            let isAssigned = await callApi({ endpoint: "IsAssignedToPbiService", query: queryIsAssigned }) //--> API TO CALL TO CHECK IF THE USER IS ASSIGNED TO PBI SERVICE
            if (!isAssigned) {
                notification({ content: { name: t("t2"), message: `${t("reportManagement.userAndGroups.groupMustBeAssignedFirst")} : ${item.displayName}` }, type: 'error' })
                return
            }

            //structure of the object to be added to the table
            let newGroup: UserAccessInfo = {
                accessType: 2,
                entraId: item.id,
                groupName: item.displayName,
                hasAccess: true,
                isReportAdmin: false,
                user: null
            }
            //add the group to the table
            setListOfTable({ ...listOfTable, group: [...listOfTable.group, newGroup] })
        }
        
        //set the search input to empty
        setData([])
        //set the search input to empty
        setSearchQuery({ user: "", group: "" })
        //set the search input to empty
        setSearchUserInputValue({ user: "", group: "" })
    }

    //Effect to handle search
    useEffect(() => {
        searchUsers();
    }, [debouncedSearch]);

    //----Method to search users when debouncedSearch changes
    const searchUsers = async () => {
        if (debouncedSearch === "") {
            setData([]);
            setListOfUsersAndGroups([])
            return;
        }
        let res: []
        if (assignmentType === 1) {
            res = await callApi({ endpoint: "AssignmentSearchUser", query: debouncedSearch }); //--> API TO CALL TO GET THE LIST OF USERS
        } else {
            res = await callApi({ endpoint: "AssignmentSearchGroup", query: debouncedSearch }); //--> API TO CALL TO GET THE LIST OF GROUPS
        }
        setListOfUsersAndGroups(res)
    }

    //Effect to handle error
    useEffect(() => {
        if (isError.status) {
            notification({ content: { name: t("t2"), message: isError.message }, type: 'error' })
        }
    }, [isError]);

    return (
        <>
            {searchQuery !== "" && debouncedSearch !== "" && (
                <ul ref={ref} className="absolute bg-[#fbfbfb] w-[520px] max-h-96 overflow-y-auto scroll-smooth scroll-thin  z-10 general-shadow m-0 p-0">
                    {
                        callApiLoading
                            ? <li className="w-full py-3 pl-3 pr-2 cursor-pointer text-sm">Loading...</li>
                            : <>
                                {
                                    data.length === 0 && searchQuery !== "" && (
                                        <li className="w-full py-3 pl-3 pr-2 cursor-pointer text-sm">No matching results</li>
                                    )}
                                {
                                    assignmentType === 1 ?
                                        listOfUsersAndGroups.map((item: User) => (
                                            <li
                                                key={item.userObjectId}
                                                className="w-full py-3 pl-3 pr-2 cursor-pointer hover:text-[#ed1a3b] hover:bg-[#fff2ee] text-sm"
                                                onClick={async () => await handleSelect(item)}>
                                                {item?.email || "No email"}
                                            </li>
                                        ))
                                        :
                                        listOfUsersAndGroups.map((item: any) => (
                                            <li key={item.id}
                                                className="w-full py-3 pl-3 pr-2 cursor-pointer hover:text-[#ed1a3b] hover:bg-[#fff2ee] text-sm" onClick={() => handleSelect(item)}>
                                                {item?.displayName || "No name"}
                                            </li>
                                        ))
                                }
                            </>
                    }
                </ul>
            )}
            {contextHolder}
        </>
    )
}

export default UserAndGroupSearchResult