import { createContext, ReactNode, useRef, useState } from "react";
import { GlobalContextInterface } from "./contextApi.type";

export const GlobalContext = createContext<GlobalContextInterface>({
    isAppAdmin: false,
    setIsAppAdmin: () => { },
    isReportAdmin: false,
    setIsReportAdmin: () => { },
    oid: "",
    setOid: () => { },
    sessionTimeoutRef: { current: null } as React.MutableRefObject<NodeJS.Timeout | null>,
    intervalRef: { current: null } as React.MutableRefObject<NodeJS.Timeout | null>,
    isIdle: false,
    setIsIdle: () => { },
    modalIsOpen: false,
    setIsModalOpen: () => { },
    setShowAccordion: () => { },
    showAccordion: true,
    isPanelPinned: false,
    setIsPanelPinned: () => { }

});

export function GlobalContextProvider({ children }: { children: ReactNode }) {
    const [isAppAdmin, setIsAppAdmin] = useState(false);
    const [isReportAdmin, setIsReportAdmin] = useState(false);
    const [modalIsOpen, setIsModalOpen] = useState(false);
    const [isIdle, setIsIdle] = useState(false); // state to check if the user is idle
    const [isPanelPinned, setIsPanelPinned] = useState(false);
    const [showAccordion, setShowAccordion] = useState(true);
    const sessionTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    const [oid, setOid] = useState("");

    return (
        <GlobalContext.Provider
            value={{
                isAppAdmin,
                setIsAppAdmin,
                isReportAdmin,
                setIsReportAdmin,
                oid,
                setOid,
                sessionTimeoutRef,
                intervalRef,
                isIdle,
                setIsIdle,
                modalIsOpen,
                setIsModalOpen,
                setShowAccordion,
                showAccordion,
                isPanelPinned,
                setIsPanelPinned
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
}
