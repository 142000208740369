import { IconInterface } from "./Icon.types";

/**
 * Requires Goolge Icons stylsheet to be added via the <head> tag for material-symbols-outlined to be available.
 *  <link
 *     rel="stylesheet"
 *     href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0"
 *   />
 */
const Icon = ({ className = "", handleOnClick, name }: IconInterface) => {
  return (
    <span
      className={`
        material-symbols-outlined
        ${handleOnClick ? "cursor-pointer" : ""}
        ${className}
      `}
      onClick={handleOnClick}
    >
      {name}
    </span>
  );
};

export default Icon;
