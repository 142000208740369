/* eslint-disable react-hooks/exhaustive-deps */
import "./style.css";
import { AllReportsSearchInterface } from "./AllReportsSearch.types";
import { BsChevronCompactRight } from "react-icons/bs";
import { MenuItemInterface } from "../../components/layout/navigation/Accordion/Accordion.types";
import { ReportPageContext } from "src/contextAPI/reportPageContext";
import { GlobalContext } from "src/contextAPI/globalContext"; 
import { ReportsMenuItemResource } from "../../api/proxy/TempProxy";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Accordion from "../../components/layout/navigation/Accordion/Accordion";
const AllReportsSearch = ({
  reportsMenuData,
  isError,
  isLoading,
  getMenuData
}: AllReportsSearchInterface) => {
  const [reportsMenuItems, setReportsMenuItems] = useState<MenuItemInterface[]>([]);
  const navigate = useNavigate();

  const { setReportId, setWorkspaceGuid, setReportName, setReportDescription, selected, setSelected} = useContext(ReportPageContext);
  const { showAccordion, setShowAccordion } = useContext(GlobalContext);

  useEffect(() => {
    let reportMenuItems: MenuItemInterface[] = [];
    reportsMenuData &&
      reportsMenuData.forEach((report: ReportsMenuItemResource) => {
        let reportMenuItem = buildReportMenuItem(report);
        if (reportMenuItem) {
          reportMenuItems.push(reportMenuItem);
        }
      });

    setReportsMenuItems(reportMenuItems);
  }, [reportsMenuData]);

  const buildReportMenuItem = (report: ReportsMenuItemResource) => {
    // if it is a report without sub categories
    if (report.label && report.label) {
      let reportItem: MenuItemInterface = {
        label: report.label,
        id: report?.target?.reportId || "",
        onClick: () => {
          if (report.target && report.target.reportName && report.target.reportId && report.target.workspaceGuid) {
            setSelected(report?.label?.en);
            setReportId && setReportId(report.target.reportId);
            setReportDescription && setReportDescription(report?.target?.reportDescription);
            setReportName && setReportName(report.target.reportName);
            setWorkspaceGuid && setWorkspaceGuid(report.target.workspaceGuid);
            navigate(`/reports?reportId=${report.target.reportId}`);
          }
        },
        children: new Array<MenuItemInterface>(),
      };
      // if it is sub category
      if (report.children && report.children.length > 0) {
        report.children.forEach((child: ReportsMenuItemResource) => {
          let childMenuItem = buildReportMenuItem(child);
          if (childMenuItem && reportItem.children) {
            reportItem.children.push(childMenuItem);
          }
        });
      }
      return reportItem;
    }
    return null;
  };
  return (
    <>
      <div className="open" onClick={() => setShowAccordion(true)}>
        <BsChevronCompactRight color="#333" size={50} fill="#fff" />
      </div>
      <div className={`containerAccordion h-full ${showAccordion ? "moveToRight" : "moveToLeft"}`}>
        <Accordion
          items={reportsMenuItems}
          selected={selected}
          showAccordion={showAccordion}
          setShowAccordion={setShowAccordion}
          isError={isError}
          isLoading={isLoading}
          getMenuData={getMenuData}
        />
      </div>
    </>

  );
};

export default AllReportsSearch;
