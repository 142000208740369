import { Category } from 'src/pages/index.type';
import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next';
import React from 'react'
import EditModule from './EditModule';
import DeleteModule from './DeleteModule';

const CategoryLabel = (category: Category) => {
    const { t, i18n } = useTranslation();
    return (
        <ul className='flex align-middle w-full justify-between'>
            <li className='w-[40%] max-w-[500px] truncate overflow-hidden whitespace-nowrap name-desc'>
                <Tooltip placement='top' title={i18n.language === 'fr' ? category.categoryFr : category.categoryEn} color='#4d4c4c'>
                    {i18n.language === 'fr' ? category.categoryFr : category.categoryEn}
                </Tooltip>
            </li>
            <li className='w-[40%] max-w-[500px] text-center truncate overflow-hidden whitespace-nowrap name-desc'>
                <Tooltip placement='topLeft' title={i18n.language === 'fr' ? category.descriptionFr : category.descriptionEn} color='#4d4c4c'>
                    {i18n.language === 'fr' ? category.descriptionFr : category.descriptionEn}
                </Tooltip>
            </li>
            <li className='w-[20%] flex justify-center'>
                <ul className='flex gap-5 w-full justify-center'>
                    <Tooltip placement='top' color='#4d4c4c' title={t("categManagement.editCategory")}>
                        <li className="cursor-pointer text-[#857f7f] hover:text-red-500">
                            <EditModule {...category} />
                        </li>
                    </Tooltip>
                    <Tooltip placement='top' color='#4d4c4c' title={t("categManagement.deleteCategory")}>
                        <li className="cursor-pointer text-[#857f7f] hover:text-red-500">
                            <DeleteModule {...category} />
                        </li>
                    </Tooltip>
                </ul>
            </li>
        </ul>
    )
}

export default CategoryLabel