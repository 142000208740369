import "./style.css"
import business from '../../assets/business.png'
import notAuth from '../../assets/401.png'
import notAuthApp from '../../assets/unauthorizedApp.png'
import notAuthAppError from '../../assets/unauthorizedApp1.png'
import React from 'react'
import RequestAccess from "../RequestAccess"
import Button from "../input/Button"
import { useTranslation } from "react-i18next"

export interface landingPageInterface {
    title: string,
    message: string,
    typeOfImage: "userAuth" | "appAuth" | "appError" | "default",
    requestButton?: boolean,
}

export function LandingDefaultPage({ message, title, typeOfImage, requestButton }: landingPageInterface) {
    const { t } = useTranslation();
    let image = "default";
    switch (typeOfImage) {
        case "userAuth":
            image = notAuth;
            break;
        case "appAuth":
            image = notAuthApp;
            break;
        case "appError":
            image = notAuthAppError;
            break;
        default:
            image = business;
            break;
    }

    return (
        <div className="flex justify-center w-full bg-white not-auth relative h-full">
            <section className='flex flex-col justify-center items-center gap-6'>
                <figure className='flex justify-center items-center w-[15vw]' >
                    <img src={image} alt="" />
                </figure>
                <h1 className='proximaNova font-semibold text-center'>{title}</h1>
                <h3>{message}</h3>
                {requestButton && //  <RequestAccess />
                    <a href="https://bdocanprod.service-now.com/ess?id=sc_cat_item&sys_id=e1608ede1ba42510bd24bbf4cc4bcb02" target="_blank">
                        <Button btnType="main" handleClick={() => { }} text={t("createSupportTicket")} className='h-10 px-4' />
                    </a>
                }
            </section>
            <aside className='absolute bottom-0 right-0'>
                <div className="triangleA w-[23vw] h-[18vw] bg-[#e81a3b] flex justify-end items-end">
                    <figure className='w-[10vw] mb-[3vw] mr-[2vw]'>
                        <svg className="logoA" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.47 108.86">
                            <g id="e28a7a73-72d6-4679-afcb-e4fd02f2ff7e" data-name="Artwork">
                                <polygon points="37.23 82.78 37.24 82.78 37.24 82.78 37.23 82.78" style={{ fill: '#fff' }} />
                                <polygon points="115.46 82.78 115.46 82.78 115.46 82.78 115.46 82.78" style={{ fill: '#fff' }} />
                                <path
                                    d="M53.77,45.12h9.87c16.23,0,24.5,3.55,24.5,13,0,8.42-6.64,12-17,12H55.3L37.24,82.78H71c20.2,0,33.81-7.28,33.81-24.11,0-14.68-12-20.45-22.79-20.45,7.43,0,16.2-6.65,16.2-17.94S87.88.9,76.12.9H34.22l3,6.71V76.89L53.77,65.31Zm0-31.56H71.1c4.76,0,10.78,1.13,10.78,9.21s-8.61,11.37-15,11.37H53.77Z"
                                    transform="translate(0 0)"
                                    style={{ fill: '#fff' }}
                                />
                                <path
                                    d="M132,13.56h11.26c4.65,0,28.34,1.17,28.34,28.28,0,30.94-28.34,28.29-28.34,28.29h-9.72L115.46,82.78H147.9c20.2,0,40.92-12.42,40.92-40.94C188.82,16.56,170.73.9,147.9.9H112.44l3,6.71V76.89L132,65.31Z"
                                    transform="translate(0 0)"
                                    style={{ fill: '#fff' }}
                                />
                                <path
                                    d="M196.46,41.84c0,32.67,26.4,41.85,43.5,41.85s43.51-9.18,43.51-41.85S257.07,0,240,0,196.46,9.18,196.46,41.84Zm17.22,0c0-23.15,15.95-29.65,26.28-29.65s26.29,6.5,26.29,29.65S250.3,71.5,240,71.5,213.68,65,213.68,41.84Z"
                                    transform="translate(0 0)"
                                    style={{ fill: '#fff' }}
                                />
                                <polygon points="0 0.91 0 102.97 16.15 91.66 16.15 0.91 0 0.91" style={{ fill: '#fff' }} />
                                <polygon points="19.17 95.44 0 108.86 282.57 108.86 282.57 95.44 19.17 95.44" style={{ fill: '#fff' }} />
                            </g>
                        </svg>
                    </figure>
                </div>

            </aside>
        </div>
    )
}