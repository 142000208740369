import Button from "../../components/input/Button/AccordionButton";
import ContentSection from "../../components/layout/content/ContentSection/ContentSection";
import { KpisInterface } from "./Kpis.types";

const handleButtonClick = (event: React.ChangeEvent<HTMLInputElement>) => {
  console.log("handleButtonClick " + event.target.value);
};

const kpis = () => (
  <div className="flex flex-col gap-4 w-full">
    <Button
      className=""
      onClick={handleButtonClick}
      value="95% year to date utilization"
    >
      95% year to date utilization
    </Button>
    <Button
      className=""
      onClick={handleButtonClick}
      value="75% year to date utilization"
    >
      75% year to date utilization
    </Button>
    <Button
      className=""
      onClick={handleButtonClick}
      value="How well am I doing"
    >
      How well am I doing
    </Button>
  </div>
);

const Kpis = ({
  className = ""
}: KpisInterface) => (
  <ContentSection
    className={`
      ${className}
    `}
    title="KPIs"
  >
    {kpis()}
  </ContentSection>
);

export default Kpis;
