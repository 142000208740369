import { Table } from 'antd'
import React, { useContext, useEffect, useId, useState } from 'react'
import { Columns } from './tableColumns'
import { AuditLogsPageContext } from 'src/contextAPI/auditLogsPageContext'
import useCallApi from 'src/hooks/useCallApi'
import { BarLoader } from 'src/components/Loader'

type AuditLogsTableProps = {
  callApiLoading: boolean,
  callApi: any,
  auditLogFilters: {
    date: {
      from: string,
      to: string
    },
    report: {
      id: string,
      name: string
    },
    userName: {
      id: string,
      name: string
    },
    actionType: {
      id: string,
      name: string
    }
  },

}

const AuditLogsTable = ({ callApiLoading, auditLogFilters, callApi }: AuditLogsTableProps) => {
  const { pagination: paginationContext, setPagination, auditLogList, setAuditLogList } = useContext(AuditLogsPageContext)
  const uniqueId = useId()

  const handleTableChange = async (pagination: any, filter: any, sorter: any) => {
    let sort = {}
    // //If sorter is coming from the table, then add the sorting parameters to the query
    if (sorter?.order) {
      let direction = sorter.order === 'ascend' ? true : false
      let sortBy = sorter.field.charAt(0).toUpperCase() + sorter.field.slice(1);
      sort = {
        sortBy: sortBy,
        ascending: direction
      }
    }
    setPagination({ ...pagination, pageSize: pagination.pageSize, pageIndex: pagination.current })

    // //If the Report Name is selected
    let reportIdSelected = auditLogFilters?.report.id !== "" ? auditLogFilters.report.id : ""
    //if the User Email is selected select the OUID
    let userOUIDSelected = auditLogFilters?.userName.id
    // //if the date range is selected
    let dateRangeSelectedFrom = auditLogFilters?.date?.from ? new Date(auditLogFilters.date.from) : new Date(new Date().setHours(0, 0, 0, 0));
    let dateRangeSelectedTo = auditLogFilters?.date?.to ? new Date(auditLogFilters.date.to) : new Date();
    // //if the action type is selected
    let actionTypeSelected = auditLogFilters?.actionType?.id !== "0" ? Number(auditLogFilters?.actionType?.id) : 0

    let extraHeaders = {
      pagesize: pagination.pageSize,
      pageindex: pagination.current,
      startDate: dateRangeSelectedFrom,
      endDate: dateRangeSelectedTo,
      reportId: reportIdSelected,
      UserObjectID: userOUIDSelected,
      auditType: actionTypeSelected,
      ...sort
    }
    let res = await callApi({ endpoint: 'AuditLogs', extraHeaders: extraHeaders }) ///api/auditlogs
    if (res.data && res.data.length > 0) {
      setAuditLogList(res.data);
      setPagination((prev: any) => ({ ...prev, totalCount: res.totalCount }))
    } else {
      setAuditLogList([]);
    }

  }
  return (
    <Table
      dataSource={auditLogList}
      loading={{ indicator: <BarLoader />, spinning: callApiLoading }}
      columns={Columns()}
      rowKey={(record) => record.timestamp + uniqueId}
      showSorterTooltip={false}
      onChange={handleTableChange}
      pagination={{
        showSizeChanger: true,
        pageSizeOptions: ['30', '50', '100', '200'],
        pageSize: paginationContext.pageSize,
        current: paginationContext.pageIndex,
        total: paginationContext.totalCount,
      }}
      scroll={{ y: "calc(100vh - 300px)" }}
    />
  )
}

export default AuditLogsTable