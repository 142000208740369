import Button from "../../components/input/Button/AccordionButton";
import ContentSection from "../../components/layout/content/ContentSection/ContentSection";
import { MyTicketsInterface } from "./MyTickets.types";

const handleButtonClick = (event: React.ChangeEvent<HTMLInputElement>) => {
  console.log("handleButtonClick " + event.target.value);
};

const myTickets = () => (
  <div className="flex flex-col gap-4 w-full">
    <Button
      className=""
      onClick={handleButtonClick}
      value="95% year to date utilization"
    >
      Ticket #1
    </Button>
    <Button
      className=""
      onClick={handleButtonClick}
      value="75% year to date utilization"
    >
      Ticket #2
    </Button>
    <Button
      className=""
      onClick={handleButtonClick}
      value="How well am I doing"
    >
      Ticket #3
    </Button>
  </div>
);

const MyTickets = ({
  className = ""
}: MyTicketsInterface) => (
  <ContentSection
    className={`
      ${className}
    `}
    title="MY TICKETS"
  >
    {myTickets()}
  </ContentSection>
);

export default MyTickets;
