import { useEffect, useState } from 'react'
import { ReportsMenuItemResource } from '../api/proxy/TempProxy'
import { ApiStructure } from '../pages/Reports/Reports.type'
import { MenuItemMetaDataInterface } from './hooks.types'
import useCallApi from './useCallApi'

const useReportList = () => {
  const [reportsMenuData, setReportsMenuData] = useState<ReportsMenuItemResource[]>([])
  const [reportsMenuMetaData, setReportsMenuMetaData] = useState<MenuItemMetaDataInterface[]>([])
  const { callApi, callApiLoading, data, setData, isError } = useCallApi()

  useEffect(() => {
    getMenuData()
  }, [])

  const getFavorites = async () => {
    let jsonData = await callApi({ endpoint: 'UserFavorites' })
    if (!jsonData) return
    return jsonData
  }

  // Transform API Data to App Data Structure
  const dataConversion = (jsonData: ApiStructure) => {
    let menuData: any[] = []
    jsonData.categories.forEach(item => {
      menuData.push({
        auth: jsonData.userAuth,
        category: {
          en: item.descriptionEn || '',
          fr: item.descriptionFr || '',
        },
        label: {
          en: item.categoryEn || '',
          fr: item.categoryFr || '',
        },
        target: undefined,
        children: item.reports.map(report => {
          return {
            key: report.powerBIReportId,
            auth: jsonData.userAuth,
            category: undefined,
            children: [],
            label: {
              en: report.nameEn || '',
              fr: report.nameFr || '',
            },
            target: {
              reportDescription: {
                en: report.descriptionEn || '',
                fr: report.descriptionFr || '',
              },
              reportName: {
                en: report.nameEn || '',
                fr: report.nameFr || '',
              },
              reportGuid: report.powerBIReportId,
              reportId: report.id,
              workspaceGuid: report.workSpaceID,
              isFavorite: report.isFavorite,
            },
          }
        }),
      })
    })
    return menuData
  }

  // method to get Menu Data
  const getMenuData = async (id?: string) => {
    let jsonData

    if (id) {
      jsonData = await callApi({ endpoint: 'OneReport', query: id })
    } else {
      jsonData = await callApi({ endpoint: 'AllReports' })
    }

    if (!jsonData) return
    let menuData = dataConversion(jsonData)
    setReportsMenuData(menuData)
    let metaData: any[] = []
    menuData &&
      menuData.forEach((report: ReportsMenuItemResource) => {
        let reportItems = buildReportMetaData(report)
        metaData = [...metaData, ...reportItems]
      })
    setReportsMenuMetaData(metaData)
  }

  const buildReportMetaData = (report: ReportsMenuItemResource) => {
    let reportItems: MenuItemMetaDataInterface[] = []

    //--------if it is a Report
    if (report.target) {
      let reportItem: MenuItemMetaDataInterface = {
        reportGuid: report.target.reportGuid,
        reportId: report.target.reportId,
        reportName: report.target.reportName,
        reportDescription: report.target.reportDescription,
        isFavorite: report.target.isFavorite,
      }
      reportItems.push(reportItem)

      //--------If the report has sub-reports or sub-categories
      if (report.children && report.children.length > 0) {
        report.children.forEach((child: ReportsMenuItemResource) => {
          let childMenuItems = buildReportMetaData(child)
          reportItems = [...reportItems, ...childMenuItems]
        })
      }
    }
    // If it is a Category
    if (report.children && report.children.length > 0) {
      report.children.forEach((child: ReportsMenuItemResource) => {
        let childMenuItems = buildReportMetaData(child)
        reportItems = [...reportItems, ...childMenuItems]
      })
    }

    return reportItems
  }

  return { reportsMenuData, reportsMenuMetaData, getMenuData, isError, callApiLoading, getFavorites }
}

export default useReportList
