import { ContentWrapperInterface } from "./ContentWrapper.type";
import MaxWidthWrapper from "../../../page/MaxWidthWrapper";

const ContentWrapper = ({
  children,
  className = ""
}: ContentWrapperInterface) => (
  <MaxWidthWrapper className={`${className}`}>
    {children}
  </MaxWidthWrapper>
);

export default ContentWrapper;
