import { useDebounce } from '@uidotdev/usehooks';
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { AuditLogsPageContext } from 'src/contextAPI/auditLogsPageContext';
import useCallApi from 'src/hooks/useCallApi';
import { useNotification } from 'src/hooks/useNotification';
import { UserInterface } from '../../UserTableManage/index.type';


type AuditLogUserSearchType = {
    searchQuery: {
        reportName: string,
        userName: string
    },
    setSearchQuery: (value: any) => void,

    auditLogFilters: {
        date: {
            from: string,
            to: string
        },
        report: {
            id: string,
            name: string
        },
        userName: {
            id: string,
            name: string
        },
        actionType: {
            id: string,
            name: string
        }
    },
    setAuditLogFilters: (value: any) => void,
    setTextInputValues: (value: any) => void,
}

const AuditLogsUserSearch = ({ auditLogFilters, searchQuery, setAuditLogFilters, setSearchQuery, setTextInputValues }: AuditLogUserSearchType) => {
    const { t, i18n } = useTranslation();
    const { notification, contextHolder } = useNotification();
    const { callApi, callApiLoading, data, setData, isError } = useCallApi();
    const debouncedSearch = useDebounce(searchQuery.userName, 500);

    const fetchUserList = async () => {
        let res = await callApi({ endpoint: "UserList", extraHeaders: { searchFilter: debouncedSearch, pagesize: 50, pageindex: 1, } }); //API call to get user list
        (!res.isError) && setData(res.data);
    }

    //Effect to handle search
    useEffect(() => {
        if (debouncedSearch === "") {
            setData([]);
            return;
        }
        fetchUserList();
    }, [debouncedSearch]);


    //Effect to handle error
    useEffect(() => {
        if (isError.status) {
            notification({ content: { name: t("t2"), message: isError.message }, type: 'error' })
        }
    }, [isError]);


    const handleUserSelect = (user: UserInterface) => {
        setTextInputValues((prev: any) => ({ ...prev, userName: user.email }))
        setSearchQuery({ ...searchQuery, userName: "" })
        setAuditLogFilters({ ...auditLogFilters, userName: { name: user.email, id: user.userObjectId } })
        setData([])
    }
    return (
        <>
            {debouncedSearch !== "" && (
                <ul className="absolute bg-[#fbfbfb] w-full max-h-96 overflow-y-auto scroll-smooth scroll-thin  z-10 general-shadow m-0 p-0">
                    {
                        callApiLoading
                            ? <li className="w-full py-3 pl-3 pr-2 cursor-pointer text-sm">{t("loading")}</li>
                            : <>
                                {data.length === 0 && searchQuery.userName !== "" && (
                                    <li className="w-full py-3 pl-3 pr-2 cursor-pointer text-sm">{t("no_Matching_Results")}</li>
                                )}
                                {data.map((item: UserInterface) => (
                                    <li key={item.userObjectId}
                                        className="w-full py-3 pl-3 pr-2 cursor-pointer hover:text-[#ed1a3b] hover:bg-[#fff2ee] text-sm"
                                        onClick={() => handleUserSelect(item)}
                                    >
                                        {item.email}
                                    </li>
                                ))}
                            </>
                    }
                </ul>
            )}
            {contextHolder}
        </>
    )
}

export default AuditLogsUserSearch