import { createContext, ReactNode, useState } from 'react';
import { LabelResource } from 'src/api/proxy/TempProxy';
import { ReportPageContextType } from './contextApi.type';

export const ReportPageContext = createContext<ReportPageContextType>({
    reportId: "",
    setReportId: () => { },
    reportGuid: "",
    setReportGuid: () => { },
    workspaceGuid: "",
    setWorkspaceGuid: () => { },
    reportName: undefined,
    setReportName: () => { },
    reportDescription: undefined,
    setReportDescription: () => { },
    favoriteItems: [],
    setFavoriteItems: () => { },
    selected: "",
    setSelected: () => { },
    // showAccordion: true,
    // setShowAccordion: () => { },
    // isPanelPinned: false,
    // setIsPanelPinned: () => { }

});

export function ReportPageProvider({ children }: { children: ReactNode }) {
    const [reportId, setReportId] = useState<string>("");
    const [reportGuid, setReportGuid] = useState<string>("");
    const [workspaceGuid, setWorkspaceGuid] = useState<string>("");
    const [reportName, setReportName] = useState<LabelResource>();
    const [reportDescription, setReportDescription] = useState<LabelResource>();
    const [favoriteItems, setFavoriteItems] = useState<any[]>([]);
    const [selected, setSelected] = useState<string>("");
    // const [showAccordion, setShowAccordion] = useState(true);
    // const [isPanelPinned, setIsPanelPinned] = useState(false);



    


    return (
        <ReportPageContext.Provider
            value={{
                reportId,
                setReportId,
                reportGuid,
                setReportGuid,
                workspaceGuid,
                setWorkspaceGuid,
                reportName,
                setReportName,
                reportDescription,
                setReportDescription,
                favoriteItems,
                setFavoriteItems,
                selected,
                setSelected,
                // showAccordion,
                // setShowAccordion,
                // isPanelPinned,
                // setIsPanelPinned
            }}
        >
            {children}
        </ReportPageContext.Provider>
    );
}
