import { PowerBIEmbed } from "powerbi-client-react";
import { models, Report, Embed } from "powerbi-client";
import { useState, useEffect, useContext } from "react";
import { PowerBiEmbededInterface } from "./PowerBiEmbeded.types";
import { GlobalContext } from "src/contextAPI/globalContext";
import { useTranslation } from "react-i18next";
import { ReportPageContext } from "src/contextAPI/reportPageContext";

const PowerBiEmbeded = ({ reportId, embedUrl, accessToken, tokenType }: PowerBiEmbededInterface) => {
  const [currentReport, setCurrentReport] = useState<Embed>();
  const [reportInstance, setReportInstance] = useState<any>();
  const { t, i18n } = useTranslation();
  const { intervalRef, sessionTimeoutRef, setIsModalOpen, setIsIdle, setShowAccordion, isPanelPinned } = useContext(GlobalContext);
  useEffect(() => { !isPanelPinned && setShowAccordion(false); }, []) // Hide the accordion when the report is loaded

  const stayActive = () => {
    setIsModalOpen(false);
    setIsIdle(false); // Reset the idle state
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null; // Clear the reference to avoid memory leaks
    }
    clearTimeout(sessionTimeoutRef.current as NodeJS.Timeout);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null; // Clear the reference to avoid memory leaks
    }
  };

  const saveFilters = async () => {
    if (reportInstance && tokenType === '1') {
      await reportInstance.savePersistentFilters(); // Llamada a la API de Power BI para guardar los filtros
    }
  };

  //To save the filters every 15 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      saveFilters();
      console.log("Filters saved");
    }, 20000); // CALL THE INTERVAL EVERY 20 SECONDS
    // clean interval on unmount
    return () => clearInterval(interval);
  }, [reportInstance]);

  useEffect(() => {
    console.log("INFO SENT TO PBI IFRAME: ", {
      reportId: reportId,
      embedUrl: embedUrl,
      accessToken: accessToken ? "********" : "NO TOKEN",
      tokenType: tokenType === '1' ? "AAD => User Context " : "EMBED => Service Principal",
    });
  }, []);

  return (
    <div
      id="reportContainer"
      className="w-full bg-white ml-5"
      onMouseOver={() => stayActive()}
    >

      <PowerBIEmbed
        embedConfig={{
          type: "report",
          id: reportId,
          embedUrl: `${embedUrl}&language=${i18n.language}&formatlocale=${i18n.language + "-" + i18n.language.toUpperCase()}`,
          accessToken: accessToken,
          tokenType: tokenType === '1' ? models.TokenType.Aad : models.TokenType.Embed,

          settings: {
            // background: models.BackgroundType.Transparent,
            panes: {
              filters: {
                visible: true,
              },
              bookmarks: {
                visible: true,
              },
              visualizations: {
                expanded: false,
              },
              pageNavigation: {
                visible: true,
                position: 0 //0:Bottom, 1:Top --> To make it work, it can not have "bar" visible

              }
            },
            persistentFiltersEnabled: true,
            bookmarksPaneEnabled: true,
            // ----------------
            bars: {
              actionBar: {
                visible: false,
              },
              statusBar: {
                visible: false
              }
            },
            // ----------------
            personalBookmarksEnabled: true,
            // localeSettings: {
            //   language: reportLng,
            //   formatLocale: reportLng,
            // }
          },
        }}
        eventHandlers={
          new Map([
            [
              "loaded",
              async function (event, report: any) {
                setReportInstance(report);
                stayActive()
              },
            ],
            [
              "rendered",
              function (event, report: any) {
                stayActive()
              },
            ],
            [
              "error",
              function (event) {
                console.log(event);
              },
            ]
          ])
        }
        cssClassName={"reportClass w-full h-full"}
        getEmbeddedComponent={(embeddedReport) => {
          setCurrentReport(embeddedReport as Report);
        }}
      />
    </div>
  );
};
export default PowerBiEmbeded;