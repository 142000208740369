import { PageInterface } from './index.type';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react'
import CategoryTableManage from 'src/components/CategoryTableManage';
import AddModule from 'src/components/CategoryTableManage/AddModule';

function CategoryManage({ setHeaderTitle }: PageInterface) {
    const { t, i18n } = useTranslation();
    useEffect(() => setHeaderTitle(t("categManagement.t1")), [t])
    return (
        <main className="flex w-full pt-7">
            <div className='w-full px-11'>
                <header className='flex justify-between items-end mb-6'>
                    <section className="flex sm:flex-col md:flex-row items-center gap-3 ">
                        <h1 className='text-2xl font-proximanova font-medium'>{t("categManagement.listOfCategories")}</h1>
                    </section>
                    <section className='flex sm:justify-between justify-end items-center md:gap-20'>
                       <AddModule />
                    </section>
                </header>
                <section className='mt-7'>
                    <div className='bg-[#dddddd] rounded-t-md'>
                        <ul className='flex w-full align-middle justify-between pl-4 pr-[41px]'>
                            <li className='w-[40%] max-w-[500px] text-center text-[14px] h-full flex justify-center align-middle py-3 font-semibold'><span>{t("categManagement.name")}</span></li>
                            <li className='w-[40%] max-w-[500px] text-center text-[14px] h-full flex justify-center align-middle py-3 font-semibold'><span>{t("categManagement.description")}</span></li>
                            <li className='text-center w-[20%] text-[14px] justify-center align-middle py-3 font-semibold'><span>{t("categManagement.actions")}</span></li>
                        </ul>
                    </div>
                    <CategoryTableManage />
                </section>
            </div>
        </main>
    )
}
export default CategoryManage