import { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type AuditLogsPageContextType = {
    auditLogList: any[],
    setAuditLogList: (value: any[]) => void,
    pagination: { totalPages: number, pageIndex: number, pageSize: number, totalCount: number },
    setPagination: (value: any) => void,
}

export const AuditLogsPageContext = createContext<AuditLogsPageContextType>({
    auditLogList: [],
    setAuditLogList: () => { },
    pagination: { totalPages: 1, pageIndex: 1, pageSize: 10, totalCount: 0 },
    setPagination: () => { },

})

export function AuditLogsPageContextProvider({ children }: { children: React.ReactNode }) {
    const { t } = useTranslation();
    const [auditLogList, setAuditLogList] = useState<any[]>([]);

    const [pagination, setPagination] = useState({
        totalPages: 1,
        pageIndex: 1,
        pageSize: 50,
        totalCount: 0
    })

    return (
        <AuditLogsPageContext.Provider value={{
            auditLogList,
            setAuditLogList,
            pagination,
            setPagination,
        }}>
            {children}
        </AuditLogsPageContext.Provider>
    )
}