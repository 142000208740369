import { useDebounce } from 'src/hooks/useDebounce';
import { useNotification } from 'src/hooks/useNotification';
import { UserInterface } from '../UserTableManage/index.type';
import { UserManagementPageContext } from 'src/contextAPI/userManagementPageContext';
import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect } from 'react'
import useCallApi from 'src/hooks/useCallApi';

interface UserManagementSearchProps {
    searchQuery: string,
    handleSelectUser: () => void
}

function UserManagementSearch({ searchQuery, handleSelectUser }: UserManagementSearchProps) {
    const { t, i18n } = useTranslation();
    const { callApi, callApiLoading, data, setData, isError } = useCallApi();
    const { filters, setUserList } = useContext(UserManagementPageContext)
    const debouncedSearch = useDebounce(searchQuery, 500);
    const { notification, contextHolder } = useNotification();

    const fetchUserList = async () => {
        let extraHeaders = {
            pagesize: 50,
            pageindex: 1,
            searchFilter: debouncedSearch,
            ascending: true,
            isSystemAdmin: filters.permissions.id === "0" ? null : filters.permissions.id === "1" ? true : false,
            isDeleted: filters.status.id === "0" ? null : filters.status.id === "1" ? false : true,
        }
        let res = await callApi({ endpoint: "UserList", extraHeaders }); //API call to get user list


        (!res.isError) && setData(res.data);
    }


    //Effect to handle search
    useEffect(() => {
        if (debouncedSearch === "") {
            setData([]);
            return;
        }
        fetchUserList();
    }, [debouncedSearch]);


    //Effect to handle error
    useEffect(() => {
        if (isError.status) {
            notification({ content: { name: t("t2"), message: isError.message }, type: 'error' })
        }
    }, [isError]);

    return (
        <>
            {debouncedSearch !== "" && (
                <ul className="absolute bg-[#fbfbfb] w-[400px] max-h-96 overflow-y-auto scroll-smooth scroll-thin  z-10 general-shadow m-0 p-0">
                    {
                        callApiLoading
                            ? <li className="w-full py-3 pl-3 pr-2 cursor-pointer text-sm">{t("loading")}</li>
                            : <>
                                {data.length === 0 && searchQuery !== "" && (
                                    <li className="w-full py-3 pl-3 pr-2 cursor-pointer text-sm">{t("no_Matching_Results")}</li>
                                )}
                                {data.map((item: UserInterface) => (
                                    <li key={item.userObjectId}
                                        className="w-full py-3 pl-3 pr-2 cursor-pointer hover:text-[#ed1a3b] hover:bg-[#fff2ee] text-sm"
                                        onClick={() => { setUserList([item]); handleSelectUser(); }}
                                    >
                                        {item.email}
                                    </li>
                                ))}
                            </>
                    }
                </ul>
            )}
            {contextHolder}
        </>
    )
}

export default UserManagementSearch