import { Tooltip } from "antd";
import { Actions, Permission, UserAndEmail } from "./CustomColumns";
import { UserInterface } from "./index.type";
import { useTranslation } from "react-i18next";

export function Columns(): any {
  const { t } = useTranslation();
  return [
    // Name
    {
      title: `${t("userManagement.t22")}`,
      dataIndex: "fullName",
      render: (_: any, record: UserInterface) => <UserAndEmail user={record} />,
      key: "fullName",
      sorter: () => { },
      ellipsis: true,
    },
    // Status
    {
      title: `${t("userManagement.t23")}`,
      dataIndex: "isDeleted",
      key: "isDeleted",
      align: "center",
      render: (_: any, record: UserInterface) => record.isDeleted ? t("userManagement.inactive") : t("userManagement.active"),
      sorter: () => { },
      ellipsis: true,
      width: "120px",
    },
    // Job Profile
    {
      title: `${t("userManagement.jobProfile")}`,
      dataIndex: "jobTitle",
      key: "jobTitle",
      // align: "center",
      sorter: () => { },
      ellipsis: { showTitle: false },
      render: (text: string) => (
        <Tooltip placement="left" title={text} color='#4d4c4c'>
          {text}
        </Tooltip>
      ),
    },
    // Business Unit
    {
      title: `${t("userManagement.t24")}`,
      dataIndex: "businessUnit",
      key: "businessUnit",
      // align: "center",
      sorter: () => { },
      ellipsis: { showTitle: false },
      render: (text: string) => (
        <Tooltip placement="left" title={text} color='#4d4c4c'>
          {text}
        </Tooltip>
      ),

    },
    // Permission
    {
      title: `${t("userManagement.t25")}`,
      dataIndex: "isSystemAdmin",
      key: "isSystemAdmin",
      align: "center",
      sorter: () => { },
      render: (_: any, record: UserInterface) => <Permission user={record} />,
      ellipsis: true,
      width: "140px",

    },
    //Live Count
    {
      title: `${t("userManagement.reportCount")}`,
      dataIndex: "reportCount",
      key: "reportCount",
      align: "center",
      sorter: () => { },
      ellipsis: true,
      width: "140px",
    },
    // Actions
    {
      title: `${t("userManagement.t27")}`,
      dataIndex: "actions",
      key: "actions",
      align: "center",
      render: (_: any, record: UserInterface) => <Actions user={record} />,
      width: "140px",
    }
  ];
}
