import { IPublicClientApplication } from "@azure/msal-browser";
import { AxiosError } from "axios";

export const accessToken = async (instance: IPublicClientApplication) => {
  if (!instance) {
    return throwError("Authentication error: no instance");
  }

  const accounts = instance.getAllAccounts();
  if (!(accounts && accounts.length > 0)) {
    return throwError("Authentication error: not logged in");
  }

  const authRequest = {
    scopes: [
      "User.Read",
    ],
    account: accounts[0]
  };

  const response = await instance.acquireTokenSilent(authRequest);
  return response ? response.accessToken : throwError("Authentication error: no access token acquired");
};

export const throwError = ((msg: string) => {
    throw new AxiosError(msg ?? "Unexpected Client Error");
});
