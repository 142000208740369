import ContentWrapper from "../components/layout/content/ContentWrapper/ContentWrapper";
import Kpis from "../pageContent/home/Kpis";
import MaxWidthWrapper from "../components/page/MaxWidthWrapper";
import MyTickets from "../pageContent/home/MyTickets";
import MyTopReports from "../pageContent/home/MyTopReports";
import NewsAndUpdates from "../pageContent/home/NewsAndUpdates";
import ReportCategories from "../pageContent/home/ReportCategories";
import SuggestedForYou from "../pageContent/home/SuggestedForYou";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { PageInterface } from "./index.type";

const Home = ({ setHeaderTitle }: PageInterface) => {
  const navigate = useNavigate();

  useEffect(() => {
    setHeaderTitle("Dashboard");
  }, []);

  return (
    <div className="flex w-full h-full">
      <MaxWidthWrapper className="flex-col">
        <ContentWrapper className="grid grid-cols-3">
          <MyTopReports navigate={navigate} />
          <Kpis />
        </ContentWrapper>
        <ContentWrapper className="grid grid-cols-3">
          <ReportCategories navigate={navigate} />
          <MyTickets />
        </ContentWrapper>
        <ContentWrapper className="grid grid-cols-3">
          <SuggestedForYou />
          <NewsAndUpdates />
        </ContentWrapper>
      </MaxWidthWrapper>
    </div>
  );
};

export default Home;