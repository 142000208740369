import Button from "../../components/input/Button/AccordionButton";
import ContentSection from "../../components/layout/content/ContentSection/ContentSection";
import { ReportCategoriesInterface } from "./ReportCategories.types"

const handleButtonClick = (event: React.ChangeEvent<HTMLInputElement>) => {
  console.log("handleButtonClick " + event.target.value);
};

const reportCategories = () => (
  <div className="flex flex-row gap-6 w-full h-full">
    <Button
      className=""
      onClick={handleButtonClick}
      value="category_1"
    >
      Category 1
    </Button>
    <Button
      className=""
      onClick={handleButtonClick}
      value="category_2"
    >
      Category 2
    </Button>
    <Button
      className=""
      onClick={handleButtonClick}
      value="category_3"
    >
      Category 3
    </Button>
  </div>
);

const ReportCategories = ({
  className = "",
  navigate
}: ReportCategoriesInterface) => (
  <ContentSection
    action={() => { navigate && navigate("/reports/categories/all"); }}
    actionClassName="underline"
    actionLabel={"ALL CATEGORIES"}
    className={`
      col-span-2 ${className}
    `}
    hasControlActions
    title="REPORT CATEGORIES"
  >
    {reportCategories()}
  </ContentSection>
);

export default ReportCategories;
