import axios from 'axios';
import type { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios';

export class MsGraphProxy {
  private instance: AxiosInstance;
  private baseUrl: string;

  constructor(baseUrl?: string, instance?: AxiosInstance) {
    this.instance = instance ? instance : axios.create();
    this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "http://localhost:8080";
  }

  /**
   * @return Success
   */
  getUserPhoto48Url( cancelToken?: CancelToken | undefined): Promise<Blob> {
      let url_ = this.baseUrl + "/me/photos/48x48/$value";
      url_ = url_.replace(/[?&]$/, "");

      let options_: AxiosRequestConfig = {
          method: "GET",
          url: url_,
          responseType: "blob",
          cancelToken
      };

      return this.instance.request(options_).catch((_error: any) => {
          if (isAxiosError(_error) && _error.response) {
              return _error.response;
          } else {
              throw _error;
          }
      }).then((_response: AxiosResponse) => {
          return this.processGetUserPhoto48(_response);
      });
  }

  protected processGetUserPhoto48(response: AxiosResponse): Promise<Blob> {
      const status = response.status;
      let _headers: any = {};
      if (response.headers && typeof response.headers === "object") {
          for (let k in response.headers) {
              if (response.headers.hasOwnProperty(k)) {
                  _headers[k] = response.headers[k];
              }
          }
      }
      if (status === 200) {
          return Promise.resolve(response.data);

      } else if (status === 404) {
          const _responseText = response.data;
          let result404: any = null;
          let resultData404  = _responseText;
          result404 = ProblemDetails.fromJS(resultData404);
          return throwException("Not Found", status, _responseText, _headers, result404);

      } else if (status === 401) {
          const _responseText = response.data;
          let result401: any = null;
          let resultData401  = _responseText;
          result401 = ProblemDetails.fromJS(resultData401);
          return throwException("Unauthorized", status, _responseText, _headers, result401);

      } else if (status !== 200 && status !== 204) {
          const _responseText = response.data;
          return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }
      return Promise.resolve<Blob>(null as any);
  }
};

export class ApiException extends Error {
    override message: string;
    status: number;
    response: string;
    headers: { [key: string]: any; };
    result: any;

    constructor(message: string, status: number, response: string, headers: { [key: string]: any; }, result: any) {
        super();

        this.message = message;
        this.status = status;
        this.response = response;
        this.headers = headers;
        this.result = result;
    }

    protected isApiException = true;

    static isApiException(obj: any): obj is ApiException {
        return obj.isApiException === true;
    }
};

function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): any {
    if (result !== null && result !== undefined)
        throw result;
    else
        throw new ApiException(message, status, response, headers, null);
};

function isAxiosError(obj: any | undefined): obj is AxiosError {
    return obj && obj.isAxiosError === true;
};

export class ProblemDetails implements IProblemDetails {
    type?: string | undefined;
    title?: string | undefined;
    status?: number | undefined;
    detail?: string | undefined;
    instance?: string | undefined;

    [key: string]: any;

    constructor(data?: IProblemDetails) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            for (var property in _data) {
                if (_data.hasOwnProperty(property))
                    this[property] = _data[property];
            }
            this.type = _data["type"];
            this.title = _data["title"];
            this.status = _data["status"];
            this.detail = _data["detail"];
            this.instance = _data["instance"];
        }
    }

    static fromJS(data: any): ProblemDetails {
        data = typeof data === 'object' ? data : {};
        let result = new ProblemDetails();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        for (var property in this) {
            if (this.hasOwnProperty(property))
                data[property] = this[property];
        }
        data["type"] = this.type;
        data["title"] = this.title;
        data["status"] = this.status;
        data["detail"] = this.detail;
        data["instance"] = this.instance;
        return data;
    }
};

export interface IProblemDetails {
    type?: string | undefined;
    title?: string | undefined;
    status?: number | undefined;
    detail?: string | undefined;
    instance?: string | undefined;

    [key: string]: any;
}
