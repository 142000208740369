import { CollapseProps } from "antd";
import { createContext, useState } from "react";
import { Category } from "src/pages/index.type";
import { ManageCategoryPageContextType } from "./contextApi.type";

export const CategoryManagementPageContext = createContext<ManageCategoryPageContextType>({
    categoryList: [],
    setCategoryList: () => { },
    isCollapsible: true,
    setIsCollapsible: () => { },
    apiResponseCategoryList: [],
    setApiResponseCategoryList: () => { },
})

export function CategoryManagementPageContextProvider({ children }: { children: React.ReactNode }) {
    const [categoryList, setCategoryList] = useState<CollapseProps['items'] | []>([])
    const [isCollapsible, setIsCollapsible] = useState(true)
    const [apiResponseCategoryList, setApiResponseCategoryList] = useState<Category[]>([])
    return (
        <CategoryManagementPageContext.Provider value={{
            categoryList,
            setCategoryList,
            isCollapsible,
            setIsCollapsible,
            apiResponseCategoryList,
            setApiResponseCategoryList
        }}>
            {children}
        </CategoryManagementPageContext.Provider>
    )
}