import BdoLogo from "src/components/BdoLogo";
import HeaderWrapper from "../../components/layout/header/HeaderWrapper";
import { HeaderInterface } from "./index.types";
import InfoUser from "../../components/InfoUser";
import SwitchLenguage from "src/components/SwitchLenguage";
import TopBarDropdown from "src/components/TopBarDropdown";
import { useTranslation } from "react-i18next";
import GlobalSearchReport from "./GlobalSearchReport";

const Header = ({ headerTitle }: HeaderInterface) => {
  const { t } = useTranslation();
const helpItemList = [
  { title: t("topBar.FAQ"), link: t("topBar.FAQlink") },
  { title: t("topBar.video"), link: t("topBar.videoLink") },
  { title: t("topBar.referenceGuide"), link: t("topBar.referenceGuideLink") },
]

  return (
    <HeaderWrapper className="h-bdo-header bg-bdo-bg-04-white drop-shadow-lg z-10">
      <div className="flex items-center w-full text-bdo-text-01-charcoal">
        <BdoLogo title={headerTitle} />
        <div className="flex gap-7 items-center ml-auto">
          {/* <GlobalSearchReport/> */}
          <TopBarDropdown btnText={t("topBar.help")} itemList={helpItemList}/>
          <a href="https://analytics-legacy.app.bdo.ca/" target="_blank" rel="noopener noreferrer" className="text-sm">
            <div className="text-[#333] rounded-full px-3 py-[0.5px] justify-start flex items-center hover:font-semibold">
              {t("topBar.legacyAnalyticsApp")}
            </div>
          </a>
          <SwitchLenguage />
          <InfoUser />
        </div>
      </div>
    </HeaderWrapper>
  );
};

export default Header;
