import "./../style.css"
import "dayjs/locale/fr-ca";
import { AuditLogsPageContext } from 'src/contextAPI/auditLogsPageContext';
import { ConfigProvider, DatePicker } from 'antd';
import { IoClose } from 'react-icons/io5';
import { IoIosSearch } from 'react-icons/io'
import { TbFileExport } from "react-icons/tb";
import { useClickAway } from '@uidotdev/usehooks';
import { useConstant } from "src/hooks/useConstant";
import { useTranslation } from 'react-i18next';
import AuditLogReportSearch from './AuditLogReportSearch';
import AuditLogsTable from './AuditLogsTable';
import AuditLogsUserSearch from './AuditLogsUserSearch';
import Button from '../../input/Button'
import dayjs from 'dayjs';
import Dropdown from "src/components/FilterSections/Dropdown";
import enLocale from "antd/es/locale/en_US";
import locale from "antd/es/locale/fr_CA";
import React, { useContext, useEffect, useState } from 'react'
import TextInputWithSearchIcon from '../../input/TextInputWithSearchIcon'
import useCallApi from "src/hooks/useCallApi";

const AuditLogs = () => {
    const { t, i18n } = useTranslation();
    const { RangePicker } = DatePicker;
    const { callApi, callApiLoading } = useCallApi();
    const { AUDITLOGS_ACTIONTYPE, AUDITLOGS_FILTERS } = useConstant()
    const refReport: any = useClickAway(() => { setShowResults((prev: any) => ({ ...prev, reportName: false })) });
    const refUser: any = useClickAway(() => { setShowResults((prev: any) => ({ ...prev, userName: false })) });
    const [auditLogFilters, setAuditLogFilters] = useState(AUDITLOGS_FILTERS)
    const [textInputValues, setTextInputValues] = useState({ reportName: '', userName: '' })
    const [dateRangeValue, setDateRangeValue] = useState<any>([dayjs(), dayjs()]);
    const [searchQuery, setSearchQuery] = useState({ reportName: '', userName: '' })
    const [showResults, setShowResults] = useState({ reportName: false, userName: false });
    const { setAuditLogList, pagination, setPagination } = useContext(AuditLogsPageContext)

    //to handle the search input change REPORTS
    const handleReportSearch = async (e: any) => {
        setAuditLogFilters({ ...auditLogFilters, report: { id: '', name: e.target.value } })
        setTextInputValues({ ...textInputValues, reportName: e.target.value })
        setSearchQuery({ ...searchQuery, reportName: e.target.value })
        setShowResults({ ...showResults, reportName: true })
    }

    //to handle the search input change USERS
    const handleUserSearch = async (e: any) => {
        setAuditLogFilters({ ...auditLogFilters, userName: { id: '', name: e.target.value } })
        setTextInputValues({ ...textInputValues, userName: e.target.value })
        setSearchQuery({ ...searchQuery, userName: e.target.value })
        setShowResults({ ...showResults, userName: true })
    }

    //to handle the RANGE date change
    const handleDateChange = (date: any, stringDate: any) => {
        setDateRangeValue(date)
        setAuditLogFilters({ ...auditLogFilters, date: { from: stringDate[0], to: stringDate[1] } })
    }

    //to handle the reset button click
    const handleReset = async () => {
        setAuditLogFilters(AUDITLOGS_FILTERS)
        setTextInputValues({ reportName: '', userName: '' })
        setSearchQuery({ reportName: '', userName: '' })
        setDateRangeValue([dayjs(), dayjs()])
        await initialFetch();
    }

    //to handle the search button click
    const handleSearch = async () => {
        let extraHeaders = {
            pagesize: pagination.pageSize,
            pageindex: 1,
            startDate: dateRangeValue ? new Date(dateRangeValue[0]) : new Date(new Date().setHours(0, 0, 0, 0)),
            endDate: dateRangeValue ? new Date(dateRangeValue[1]) : new Date(),
            reportId: auditLogFilters?.report.id,
            userObjectID: auditLogFilters?.userName.id,
            auditType: Number(auditLogFilters.actionType.id)
        }
        let res = await callApi({ endpoint: 'AuditLogs', extraHeaders: extraHeaders }) ///api/auditlogs
        if (res.data && res.data.length > 0) {
            setAuditLogList(res.data);
            setPagination((prev: any) => ({ ...prev, totalCount: res.totalCount }))
        } else {
            setAuditLogList([]);
            setPagination((prev: any) => ({ ...prev, totalCount: 0 }))
        }
    }

    const initialFetch = async () => {
        let extraHeaders = {
            pagesize: pagination.pageSize,
            pageindex: 1,
            startDate: new Date(new Date().setHours(0, 0, 0, 0)),
            endDate: new Date(),
            reportId: "",
            userObjectID: "",
            auditType: 0
        }
        let res = await callApi({ endpoint: 'AuditLogs', extraHeaders: extraHeaders }) ///api/auditlogs
        if (res.data && res.data.length > 0) {
            setAuditLogList(res.data);
            setPagination((prev: any) => ({ ...prev, totalCount: res.totalCount }))
        } else {
            setAuditLogList([]);
        }
    }

    const handleDowloadExcel = async () => {
        let extraHeaders = {
            pagesize: 0,
            pageindex: 1,
            startDate: dateRangeValue ? new Date(dateRangeValue[0]) : new Date(new Date().setHours(0, 0, 0, 0)),
            endDate: dateRangeValue ? new Date(dateRangeValue[1]) : new Date(),
            reportId: auditLogFilters?.report.id,
            userObjectID: auditLogFilters?.userName.id,
            auditType: Number(auditLogFilters.actionType.id)
        }
        let res = await callApi({ endpoint: 'AuditLogsExport', extraHeaders: extraHeaders, contentType: 'blob', isToExport: true }) ///api/auditlogs
        console.log('resp', res)
        const blob = new Blob([res], { type: 'application/vnd.ms-excel' });
        const outputFilename = `Insight Hub - Audit Log - ${new Date().toLocaleDateString('en-CA')}.xlsx`;
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);


    }

    //To populate table in the first render
    useEffect(() => {
        initialFetch();
    }, [])

    // To update the date picker locale based on the language
    useEffect(() => {
        if (i18n.language === "fr") {
            dayjs.locale("fr-ca");
        } else {
            dayjs.locale("en");
        }
        let messageIndex = AUDITLOGS_ACTIONTYPE.findIndex((item) => item.id === auditLogFilters.actionType.id)
        setAuditLogFilters({ ...auditLogFilters, actionType: AUDITLOGS_ACTIONTYPE[messageIndex] })
    }, [t])

    return (
        <div className='flex flex-col justify-center gap-10'>
            <div className='flex items-end justify-between gap-3 mt-[100px]'>
                {/* ---Report Name ---- */}
                <section className='relative w-full' ref={refReport}>
                    <div className='w-full'>
                        <div className="flex gap-1">
                            <label className='block text-[14px] font-semibold mb-1'>{t("reportManagement.table.titleName")}</label>
                        </div>
                        <div className='flex relative' >
                            <TextInputWithSearchIcon
                                value={textInputValues.reportName}
                                placeholder={t("reportManagement.searchPlaceHolder")}
                                isToSearch={true}
                                handleChange={(e) => handleReportSearch(e)}
                            />
                            <aside>
                                {textInputValues.reportName.length > 0 &&
                                    <Button
                                        btnType="main"
                                        className="absolute right-2 top-[13px] rounded-full"
                                        icon={<IoClose size={12} />}
                                        handleClick={() => {
                                            setTextInputValues({ ...textInputValues, reportName: "" })
                                            setAuditLogFilters({ ...auditLogFilters, report: { id: '', name: "" } })
                                            setSearchQuery({ ...searchQuery, reportName: "" })
                                            setShowResults({ ...showResults, reportName: false })
                                        }}
                                    />
                                }
                            </aside>
                        </div>
                    </div>
                    {showResults.reportName &&
                        <AuditLogReportSearch
                            auditLogFilters={auditLogFilters}
                            searchQuery={searchQuery}
                            setAuditLogFilters={setAuditLogFilters}
                            setTextInputValues={setTextInputValues}
                            setSearchQuery={setSearchQuery}
                        />}
                </section>
                {/* ----Email ---- */}
                <section className='relative w-full' ref={refUser}>
                    <div className=''>
                        <div className="flex gap-1">
                            <label className='block text-[14px] font-semibold mb-1'>{t("auditLogs.email")}</label>
                        </div>
                        <div className='flex relative' >
                            <TextInputWithSearchIcon
                                value={textInputValues.userName}
                                placeholder={t("auditLogs.searchByUserEmail")}
                                isToSearch={true}
                                handleChange={(e) => handleUserSearch(e)}
                            />
                            <aside>
                                {textInputValues.userName.length > 0 &&
                                    <Button
                                        btnType="main"
                                        className="absolute right-2 top-[13px] rounded-full"
                                        icon={<IoClose size={12} />}
                                        handleClick={() => {
                                            setAuditLogFilters({ ...auditLogFilters, userName: { id: '', name: "" } })
                                            setTextInputValues({ ...textInputValues, userName: "" })
                                            setSearchQuery({ ...searchQuery, userName: "" })
                                            setShowResults({ ...showResults, userName: false })
                                        }
                                        } />
                                }
                            </aside>
                        </div>
                    </div>
                    {showResults.userName &&
                        <AuditLogsUserSearch
                            auditLogFilters={auditLogFilters}
                            searchQuery={searchQuery}
                            setAuditLogFilters={setAuditLogFilters}
                            setTextInputValues={setTextInputValues}
                            setSearchQuery={setSearchQuery}
                        />}
                </section>
                {/* ---- Action Type ----- */}
                <section className='relative w-full'>
                    <div className=''>
                        <Dropdown
                            title={t("auditLogs.actionType")}
                            dropdownHandler={(e) => setAuditLogFilters({ ...auditLogFilters, actionType: e })}
                            options={AUDITLOGS_ACTIONTYPE}
                            value={auditLogFilters.actionType}
                            className="rounded-[5px]"
                        />
                    </div>
                </section>
                {/* ---- Date Range ----- */}
                <section className="w-full">
                    <div className="flex items-center">
                        <label className='block text-[14px] w-1/2 font-semibold mb-1'>{t("auditLogs.from")}</label>
                        <label className='block text-[14px] font-semibold mb-1'>{t("auditLogs.to")}</label>
                    </div>
                    <ConfigProvider locale={i18n.language === "fr" ? locale : enLocale}>
                        <RangePicker
                            className='h-10 w-full'
                            onChange={handleDateChange}
                            value={dateRangeValue}
                        />
                    </ConfigProvider>
                </section>
                {/* ---- Buttons ----- */}
                <section className='flex items-end justify-end gap-2 h-full w-full'>
                    <Button
                        btnType='secondary'
                        text={t("auditLogs.reset")}
                        className='h-10 px-8 rounded-[5px]'
                        handleClick={async () => handleReset()}
                    />
                    <Button
                        btnType='main'
                        text={t("auditLogs.search")}
                        className='h-10 px-10 rounded-[5px]'
                        icon={<IoIosSearch />}
                        handleClick={async () => handleSearch()}
                    />
                </section>
            </div>
            <section className="flex flex-col gap-2">
                <div className="flex justify-end">
                    <Button
                        btnType='main'
                        text={t("auditLogs.exportToPdf")}
                        className='h-10 px-6 rounded-[5px]'
                        icon={<TbFileExport />}
                        handleClick={async () => handleDowloadExcel()}
                    />
                </div>
                <AuditLogsTable
                    callApiLoading={callApiLoading}
                    auditLogFilters={auditLogFilters}
                    callApi={callApi}
                />
            </section>
        </div>
    )
}

export default AuditLogs