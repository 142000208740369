import { RiDeleteBin6Line } from 'react-icons/ri';
import { Tooltip } from 'antd';
import { UserInterface } from '../UserTableManage/index.type';
import { useTranslation } from 'react-i18next';
import React from 'react'

interface UserAndGroupsTableColumnsType {
    setListOfTable: (value: UserInterface[]) => void,
    listOfTable: UserInterface[],
}

const UserTableColumns = ({ setListOfTable, listOfTable }: UserAndGroupsTableColumnsType) => {
   
    const { t, i18n } = useTranslation();
    return [
        {
            title: t('reportManagement.userAndGroups.userName'),
            key: 'fullName',
            render: (record: UserInterface) => record.fullName || '',
            sorter: (a: any, b: any) => a?.fullName.localeCompare(b?.fullName),
        },
        {
            title: t('reportManagement.userAndGroups.email'),
            key: 'email',
            align: 'center' as 'center',
            render: (record: UserInterface) => record.email || '',
            sorter: (a: any, b: any) => a.email.localeCompare(b?.email)
        },
        {
            title: t('userManagement.admin'),
            key: 'isSystemAdmin',
            align: 'center' as 'center',
            sorter: (a: any, b: any) => a.isAdmin - b.isAdmin,
            defaultSortOrder: 'descend' as 'descend' | 'ascend' | undefined,
            render: (record: UserInterface) =>
                <input
                    className="cursor-pointer checkbox"
                    type='checkbox'
                    onChange={() => setListOfTable(listOfTable.map((item: UserInterface) => item.userObjectId === record.userObjectId ? { ...item, isSystemAdmin: !item.isSystemAdmin } : item))}
                    // onChange={() => setListOfTable(listOfTable.map((item: UserInterface) => item.email === record.email ? { ...item, isAdmin: !item.isSystemAdmin } : item))}
                    checked={record.isSystemAdmin}
                />
        },
        {
            title: t('reportManagement.userAndGroups.actions'),
            key: 'userObjectId',
            align: 'center' as 'center',
            render: (record: UserInterface) => <div className='flex justify-center'>
                <Tooltip placement="left" color='#4d4c4c' title={t('reportManagement.userAndGroups.removeAccess')}>
                    <RiDeleteBin6Line
                        className='cursor-pointer text-[#857f7f] hover:text-red-500'
                        size={15}
                        onClick={() => {
                            const newListOfTable = listOfTable.filter((item: UserInterface) => item.userObjectId !== record.userObjectId)
                            setListOfTable(newListOfTable)
                        }}
                    />
                </Tooltip>
            </div >
        },
    ]
}

export default UserTableColumns