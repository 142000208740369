import { FaUserLock } from "react-icons/fa";
import { ReportType } from "./index.type"
import EditModule from "./EditModule";
import DeleteModule from "./DeleteModule";
import UserAndGroupAssignment from "./UserAndGroupAssignment";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { useNotification } from "src/hooks/useNotification";
import { t } from "i18next";

export function Access({ report }: { report: ReportType }) {
    return <section>
        {report.isPublic === false
            ? <div className="flex justify-center items-center gap-2">
                <FaUserLock style={{ fill: '#636060', minWidth: '15px' }} />
                <h2>{t("reportManagement.table.private")}</h2>
            </div>
            : <h2>{t("reportManagement.table.public")}</h2>
        }
    </section>
}

export function Actions({ report }: { report: ReportType }) {
    const { t, i18n } = useTranslation();
    const { contextHolder, notification } = useNotification();
    return (
        <ul className="flex justify-center items-center gap-5">
            <li className="cursor-pointer text-[#857f7f] hover:text-red-500">
                <Tooltip placement="left" color='#4d4c4c' title={t('reportManagement.editmodule.title')}>
                    <>
                        <EditModule report={report} />
                    </>
                </Tooltip>
            </li>

            <li className="cursor-pointer text-[#857f7f] hover:text-red-500">
                <Tooltip placement="left" color='#4d4c4c' title={t('reportManagement.userAndGroups.title')}>
                    <>
                        <UserAndGroupAssignment report={report} />
                    </>
                </Tooltip>
            </li>
            <li className="cursor-pointer text-[#857f7f] hover:text-red-500">
                <Tooltip placement="left" color='#4d4c4c' title={t('reportManagement.deletemodule.title')}>
                    <>
                        <DeleteModule report={report} />
                    </>
                </Tooltip>
                {contextHolder}
            </li>
        </ul>
    )
}

