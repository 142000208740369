import React from 'react'
import { FiChevronDown } from "react-icons/fi";

interface ITopBarDropdown {
    btnText: string;
    itemList: { title: string; link: string; }[]
}

const TopBarDropdown = ({ btnText, itemList }: ITopBarDropdown) => {
    return (
        <div className='relative group w-fit'>
            <section className='flex items-center justify-center gap-1 px-3 py-[0.5px] cursor-pointer group-hover:font-semibold'>
                <span className='text-[#333] text-[14px]'>{btnText}</span>
                <FiChevronDown color='red' className='transform transition-transform duration-300 group-hover:-rotate-180' />
            </section>
            <ul className='absolute w-fit hidden pt-4 bg-white group-hover:block '>
                {
                    itemList.map((item, index) => (
                        <li key={index} className='cursor-pointer hover:bg-[#fff2ee] hover:text-[#ed1a3b] text-left overflow-hidden whitespace-nowrap text-ellipsis flex flex-col gap-1 justify-center'>
                            <a href={item.link} target="_blank" className='py-2 pl-4 pr-14 text-[14px]'>{item.title}</a>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

export default TopBarDropdown