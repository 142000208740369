import { Category } from 'src/pages/index.type';
import { fetchCategoryListInterface } from './hooks.types';
import CategoryLabel from 'src/components/CategoryTableManage/CategoryLabel';
import React, { useContext } from 'react'
import ReportTable from 'src/components/CategoryTableManage/ReportTable';
import useCallApi from './useCallApi';
import { CategoryManagementPageContext } from 'src/contextAPI/categoryManagementPageContext';
import { useTranslation } from 'react-i18next';

const useCategoryLogic = () => {
    const { t, i18n } = useTranslation()
  const { callApi, isError } = useCallApi();
  const {setCategoryList, setApiResponseCategoryList } = useContext(CategoryManagementPageContext)

  const sortList = (res: Category[]) => {
    return res.sort((a: Category, b: Category) => {
      const nameA = a.categoryEn?.toLowerCase() || "";
      const nameB = b.categoryFr?.toLowerCase() || "";
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    })
  }
  const fetchCategoryList = async ({ notification }: fetchCategoryListInterface) => {
    let res = await callApi({ endpoint: 'Categories' }) //api/category
    if (res?.length > 0) {
      const sortedList = sortList(res) //---> sort the list of categories by name
      sortList.length > 0 && setApiResponseCategoryList(sortedList)
      const COLLAPSEITEMS = sortedList.map((category: Category) => {
        return {
          key: category.id,
          label: <CategoryLabel {...category} />,
          children: <ReportTable reportList={category.reports || []} />
        }
      })
      setCategoryList(COLLAPSEITEMS)
    } else {
      notification({ content: { name: t("t2"), message: `${res.status} ${res.statusText} ${isError.message}` }, type: 'error' })
    }
  }

  return { fetchCategoryList }
}

export default useCategoryLogic