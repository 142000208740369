import React from 'react'
import { SearchProps } from './index.type';
import { IoIosSearch } from 'react-icons/io';
import InputMask from "react-input-mask"

import './style.css'

const TextInputWithSearchIcon = ({ handleChange, placeholder, border_radio = true, value = "", isToSearch = true, withMask }: SearchProps) => {
  return (
    <div className='h-10 flex w-full'>
      {isToSearch && <span className={`search-icon flex justify-center items-center pl-3 pr-2 ${border_radio && "radio-icon"}`}><IoIosSearch color='#00000071' /></span>}
      <div className='w-full' >
        {
          withMask ?
            <InputMask
              className={`focus-visible:outline-none font-proximanova w-full h-full pr-2 text-[#333333d7] ${border_radio && "radio-input"} ${isToSearch ? "isToSearch" : "isNotToSearch"}`}
              mask="********-****-****-****-************"
              alwaysShowMask={false}
              onChange={handleChange}
              value={value}
              placeholder={placeholder}
              onPaste={(e) => {
                e.preventDefault();
                let valueToPaste = e.clipboardData.getData('Text')
                // let newValue = valueToPaste.replace(/-/g, "").substring(0, 36)
                handleChange({ target: { value: valueToPaste } } as React.ChangeEvent<HTMLInputElement>)

              }}
            />
            :
            <input
              type="text"
              name="search"
              id="search"
              autoComplete="off"
              onChange={handleChange}
              className={`font-proximanova w-full h-full pr-7 text-[#333333d7] ${border_radio && "radio-input"} ${isToSearch ? "isToSearch" : "isNotToSearch"}`}
              placeholder={placeholder}
              value={value}
            />
        }


      </div>
    </div>
  )
}
export default TextInputWithSearchIcon