import { useTranslation } from "react-i18next";

const BdoLogo = ({ title }: any) => {
  const { t, i18n } = useTranslation()
  return (
    <div className="flex gap-2 items-end">
      <div className="flex justify-between items-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="62" height="24" viewBox="0 0 52 20" fill="none">
          <g clipPath="url(#clip0_197_4691)">
            <path
              d="M9.86361 8.28954H11.6742C14.6514 8.28954 16.1685 8.94175 16.1685 10.6779C16.1685 12.2249 14.9504 12.8826 13.05 12.8826H10.1443L6.83134 15.2085H13.0243C16.7298 15.2085 19.2264 13.871 19.2264 10.779C19.2264 8.08193 17.0251 7.02186 15.0458 7.02186C16.4088 7.02186 18.0176 5.8001 18.0176 3.72588C18.0176 1.65166 16.1208 0.165344 13.9635 0.165344H6.27734L6.82767 1.39812V14.1264L9.86361 11.9989V8.28954ZM9.86361 2.49127H13.0426C13.9158 2.49127 15.0201 2.69887 15.0201 4.18335C15.0201 5.66782 13.4407 6.27227 12.2685 6.27227H9.86361V2.49127Z"
              fill="#22409A"
            />
            <path
              d="M24.2151 2.49127H26.2806C27.1336 2.49127 31.4793 2.70622 31.4793 7.68693C31.4793 13.3713 26.2806 12.8844 26.2806 12.8844H24.4976L21.1809 15.2085H27.1318C30.8373 15.2085 34.6382 12.9267 34.6382 7.68693C34.6382 3.04243 31.3197 0.165344 27.1318 0.165344H20.627L21.1773 1.39812V14.1264L24.2151 11.9989V2.49127Z"
              fill="#22409A"
            />
            <path
              d="M36.0391 7.68694C36.0391 13.6891 40.8819 15.3757 44.0187 15.3757C47.1556 15.3757 52.0003 13.6891 52.0003 7.68694C52.0003 1.68473 47.1574 0 44.0261 0C40.8947 0 36.0391 1.68657 36.0391 7.68694ZM39.1979 7.68694C39.1979 3.43377 42.1238 2.23957 44.0187 2.23957C45.9137 2.23957 48.8414 3.43377 48.8414 7.68694C48.8414 11.9401 45.9155 13.1361 44.0261 13.1361C42.1366 13.1361 39.1979 11.9419 39.1979 7.68694Z"
              fill="#22409A"
            />
            <path d="M0 0.167175V18.9179L2.96257 16.84V0.167175H0Z" fill="#ED1A3B" />
            <path d="M3.51656 17.5344L0 20H51.8349V17.5344H3.51656Z" fill="#ED1A3B" />
          </g>
          <defs>
            <clipPath id="clip0_197_4691">
              <rect width="52" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <h1 className="text-xl font-semibold ">{t("bdoLodoTitle")}<span className="mx-3 text-xl">|</span>{title}</h1>
    </div>
  )
}

export default BdoLogo;
