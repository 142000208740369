import "./style.css"
import { DropdownProps } from './index.type';
import { IoChevronForward } from "react-icons/io5";
import React, { useEffect, useState } from 'react'
import { useClickAway } from "@uidotdev/usehooks";

const Dropdown = ({ title, dropdownHandler, options, value, placeholder, className = "", mandatory = false }: DropdownProps) => {

    const [isOpen, setIsOpen] = useState(false);
    const [newValue, setNewValue] = useState(value);
    const handleSelect = (option: any) => {
        setIsOpen(false);
        dropdownHandler(option);
    }
    const ref: any = useClickAway(() => { setIsOpen(false) });


    useEffect(() => {
        setNewValue(value);
    }, [value]);

    return (
        <>
            <div className="flex gap-1">
                <h3 className="font-semibold text-[14px]">{title}</h3>
                {mandatory && <span className="text-red-600 text-2xl">*</span>}
            </div>
            <div className='text-[13px] mt-2' ref={ref}>
                <div className="relative">
                    <input
                        className={`${className} w-full pl-2 h-[40px] border border-gray-300 outline-none cursor-pointer`}
                        readOnly
                        onClick={() => setIsOpen(!isOpen)}
                        value={newValue.name}
                        aria-expanded={isOpen}
                        placeholder={placeholder}
                    />
                    {
                        isOpen && (
                            <ul className="absolute z-10 w-full py-1 max-h-96 bg-white border border-t-0 border-gray-300 overflow-y-auto scroll-thin shadow-dropdown">
                                {options.map((option, index) => (
                                    <li
                                        key={option.id}
                                        className="px-2 cursor-pointer hover:bg-gray-100 text-left overflow-hidden whitespace-nowrap text-ellipsis flex flex-col gap-1 justify-center"
                                        onClick={() => handleSelect(option)}
                                    >
                                        <span className='py-1'>{option.name}</span>
                                        {index < options.length - 1 && <hr className='w-full h-1' />}
                                    </li>
                                ))}
                            </ul>
                        )
                    }
                    <IoChevronForward size={15} color="#706e6e" className={`absolute top-1/2 right-2 transform -translate-y-1/2 transition-all ${isOpen && "rotate-90 transition-all "}`} />
                </div>
            </div>
        </>
    );
}

export default Dropdown