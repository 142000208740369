import "./style.css"
import { BarLoader } from '../Loader'
import { Columns } from './tableColumns'
import { Table } from 'antd'
import React, { useContext, useEffect, useRef } from 'react'
import { ReportManagementPageContext } from "src/contextAPI/reportManagementPageContext"
import { ReportTableManageProps, ReportType } from "./index.type"
import { useReportManagePage } from "src/hooks/useReportManagePage"

function ReportTableManage({ callApiLoading }: ReportTableManageProps) {
    const { pagination: paginationContext, totalAccount, reportList, filters, setPagination } = useContext(ReportManagementPageContext)
    const { fetchReportList, contextHolder } = useReportManagePage()
    const paginationRef = useRef(paginationContext);

    const handleTableChange = async (pagination: any, filter: any, sorter: any) => {

        let sort = {}
        //If sorter is coming from the table, then add the sorting parameters to the query
        if (sorter?.order) {
            let direction = sorter.order === 'ascend' ? true : false
            let sortBy = sorter.field.charAt(0).toUpperCase() + sorter.field.slice(1);
            sort = {
                sortBy: sortBy,
                ascending: direction
            }
        }
        setPagination({ ...pagination, pageSize: pagination.pageSize, pageIndex: pagination.current })

        //If the category is selected and the filter is active, then categorySelected will be the selected category id
        let categorySelected = (filters.dropDownValue.id !== "" && filters.isFilterActive) ? filters.dropDownValue.id : undefined
        //If the permission is selected and the filter is active, then isPrivateSelected will be the selected permission id
        let isPrivateSelected = (filters.permissionValue.id !== "" && filters.isFilterActive) ? filters.permissionValue.id === "1" ? false : filters.permissionValue.id === "2" ? true : undefined : undefined

        let extraHeaders = {
            pagesize: pagination.pageSize,
            pageindex: pagination.current,
            categoryid: categorySelected,
            isPrivate: isPrivateSelected,
            ...sort
        }
        fetchReportList({ extraquery: extraHeaders })
    }

    //============================Update every 5 minutes=========================
    // Update the ref when the context changes so that the latest pagination values are always used
    useEffect(() => {
        paginationRef.current = paginationContext;
    }, [paginationContext]);

    const refreshUserList = async () => {
        await fetchReportList(
            {
                extraquery: {
                    pagesize: paginationRef.current.pageSize,
                    pageindex: paginationRef.current.pageIndex,
                    categoryid: filters.dropDownValue.id,
                    isPrivate: filters.permissionValue.id === "1"
                        ? false
                        : filters.permissionValue.id === "2"
                            ? true
                            : undefined
                }
            })
    };

    useEffect(() => {
        refreshUserList();
        const intervalId = setInterval(refreshUserList, 300000);
        return () => clearInterval(intervalId);
    }, []);
    //==============================================================

    return (
        <div>
            <Table
                dataSource={reportList}
                columns={Columns()}
                loading={{ indicator: <BarLoader />, spinning: callApiLoading }}
                onChange={handleTableChange}
                rowKey={(record: ReportType) => record.id}
                showSorterTooltip={false}
                pagination={{
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30', '50'],
                    pageSize: paginationContext.pageSize,
                    current: paginationContext.pageIndex,
                    total: totalAccount,
                }}

                scroll={{ y: "calc(100vh - 300px)" }}
            />
            {contextHolder}
        </div>
    )
}
export default ReportTableManage