import { MaxWrapperInterface } from "./MaxWrapper.types";

const MaxWidthWrapper = ({
  children,
  className = ""
}: MaxWrapperInterface) => (
  <div className={`flex w-full mx-auto ${className}`}>
    {children}
  </div>
);

export default MaxWidthWrapper;
