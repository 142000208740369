import { i18n } from "i18next";

export const ByLang = (input: { [key: string]: string; } | undefined, i18n: i18n) => {
    if (!input) {
        return "";
    }

    let resolvedLanguage = i18n.language;
    let result = resolvedLanguage && input[resolvedLanguage]
        ? input[resolvedLanguage]
        : input["en"];

    return result ? result : "";
}
