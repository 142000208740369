import { createContext, ReactNode, useState } from 'react';
import { ManageReportsPageContextType } from './contextApi.type';
import { useTranslation } from 'react-i18next';

export const ReportManagementPageContext = createContext<ManageReportsPageContextType>({
    reportList: [],
    setReportList: () => { },
    pagination: {
        totalPages: 1,
        pageIndex: 1,
        pageSize: 10,
    },
    setPagination: () => { },
    totalAccount: 0,
    setTotalAccount: () => { },
    filters: {
        permissionValue: {
            id: "",
            name: "All"
        },
        dropDownValue: {
            id: "",
            name: ""
        },
        isFilterActive: false
    },
    setFilters: () => { },

    categories: [],
    setCategories: () => { },
    reportsPerUser: 0,
    setReportsPerUser: () => { }
});

export function ReportManagementPageContextProvider({ children }: { children: ReactNode }) {
    const { t } = useTranslation();
    const [reportList, setReportList] = useState<any[]>([]);
    const [totalAccount, setTotalAccount] = useState(0);
    const [categories, setCategories] = useState<any[]>([])
    const [reportsPerUser, setReportsPerUser] = useState(0)
    const [pagination, setPagination] = useState({
        totalPages: 1,
        pageIndex: 1,
        pageSize: 10,
    })
    const [filters, setFilters] = useState({
        permissionValue: { id: "", name: "All" },
        dropDownValue: { id: "", name: t("reportManagement.filters.all") },
        isFilterActive: false
    });
    return (
        <ReportManagementPageContext.Provider
            value={
                {
                    reportList,
                    setReportList,
                    pagination,
                    setPagination,
                    totalAccount,
                    setTotalAccount,
                    filters,
                    setFilters,
                    categories,
                    setCategories,
                    reportsPerUser,
                    setReportsPerUser
                }
            }
        >
            {children}
        </ReportManagementPageContext.Provider>
    );
}