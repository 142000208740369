import React, { useContext, useState } from 'react'
import useCallApi from './useCallApi'
import { useNotification } from './useNotification'
import { UserManagementPageContext } from 'src/contextAPI/userManagementPageContext'
import { useConstant } from './useConstant'
import { useTranslation } from 'react-i18next'
// import { USER_MANAGE_INITIALFILTERVALUES } from 'src/pages/constants'

const useUserManagementPage = () => {
  const { t, i18n } = useTranslation()
  const { contextHolder, notification } = useNotification()
  const { callApiLoading, callApi, isError } = useCallApi()
  const { USER_MANAGE_INITIALFILTERVALUES } = useConstant()
  const [searchInputValue, setSearchInputValue] = useState('')
  const [showResults, setShowResults] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')

  const { setPagination, setTotalAccount, setUserList, setFilters, pagination, userList, businessUnits, setBusinessUnits } = useContext(UserManagementPageContext)

  //To know how many reports are there in the list
  const getUsersQuantity = async ({ extraHeaders }: any) => {
    let res = await callApi({ endpoint: 'UserList', extraHeaders }) ///api/report/search
    if (!res.isError) {
      return res.totalCount
    } else {
      return 0
    }
  }

  const fetchBusinessUnitList = async () => {
    let res = await callApi({ endpoint: 'BusinessUnits' }) //api/user/businessunitlist
    let OPTIONS: any = []
    if (res?.length > 0) {
      //to convert the response to the format that the dropdown component can understand
      OPTIONS = res.map((business: string) => {
        return {
          id: business,
          name: business,
        }
      })
    }
    OPTIONS.unshift({ id: '0', name: t('reportManagement.filters.all') })
    setBusinessUnits(OPTIONS)
  }

  //To fetch the report list
  const fetchUserList = async ({ extraquery, getTotal }: any) => {
    //GetTotal is a boolean to know if I need to get the total quantity of reports for AddUserModule, EditUserModule and DeleteUserModule
    //extraquery gets the pagination values and the filters values
    let res = await callApi({ endpoint: 'UserList', extraHeaders: extraquery }) ///api/report/search
    if (!res.isError) {
      setTotalAccount(res.totalCount || 0)
      if (!getTotal) setUserList(res.data || [])
      if (!getTotal) setPagination({ totalPages: res.totalPages || 1, pageIndex: res.pageNumber || 1, pageSize: res.pageSize || 1 })
      return res.data
    } else {
      notification({ content: { name: t("t2"), message: `${res.status} ${(res && res.message) || res.statusText || (isError && isError.message)} ` }, type: 'error' })
    }
  }

  //to handle the reset button click
  const handleReset = async () => {
    setSearchInputValue('')
    setFilters(USER_MANAGE_INITIALFILTERVALUES)
    fetchUserList({
      extraquery: {
        pagesize: pagination.pageSize,
        pageindex: pagination.pageIndex,
        isDeleted: false,
      },
    })
    setTotalAccount(userList.length)
  }

  //to handle the search input change
  const handleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(e.target.value)
    setSearchQuery(e.target.value)
    setShowResults(true)
  }
  return {
    contextHolder,
    notification,
    callApiLoading,
    callApi,
    searchInputValue,
    setSearchInputValue,
    showResults,
    setShowResults,
    searchQuery,
    setSearchQuery,
    handleSearch,
    fetchUserList,
    getUsersQuantity,
    handleReset,
    fetchBusinessUnitList,
  }
}

export default useUserManagementPage
