import { GlobalContext } from 'src/contextAPI/globalContext'
import { MdOutlineEdit } from 'react-icons/md'
import { Modal } from 'antd'
import { useNotification } from 'src/hooks/useNotification'
import { UserInterface } from './index.type'
import { UserManagementPageContext } from 'src/contextAPI/userManagementPageContext'
import { useTranslation } from 'react-i18next'
import Dropdown from '../FilterSections/Dropdown'
import React, { useContext, useState, useEffect } from 'react'
import useCallApi from 'src/hooks/useCallApi'
import useUserManagementPage from 'src/hooks/useUserManagementPage'

export interface EditModuleType {
    user: UserInterface
}

const EditModule = ({ user }: EditModuleType) => {
    const { t } = useTranslation();
    const { pagination, filters } = useContext(UserManagementPageContext)
    const { oid } = useContext(GlobalContext)
    const { fetchUserList } = useUserManagementPage()
    const { callApi, callApiLoading, isError } = useCallApi();
    const { contextHolder, notification } = useNotification()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [dataForm, setDataForm] = useState({
        permission: { id: user.isSystemAdmin ? "1" : "2", name: user.isSystemAdmin ? "Admin" : "User" },
        status: user.isDeleted ? t("userManagement.active") : t("userManagement.inactive")
    })

    const PERMISSIONOPTIONS = [
        { id: '1', name: "Admin" },
        { id: '2', name: "User" }
    ]

    const handleOk = async () => {
        const userUpdated = {
            submittedByUserId: oid,
            userObjectId: user.userObjectId,
            isSystemAdmin: dataForm.permission.id === "1" ? true : false,
        }

        // --> to call the API to update the User
        let res = await callApi({ endpoint: "UpdateUser", method: "PUT", body: userUpdated });

        if (isError.status || res.status !== 200) {
            notification({ content: { name: t("t2"), message: `${res.status} ${res && res.message || isError && isError.message} ` }, type: 'error' })
            return
        }

        // --> to fetch the User list after adding a new User and refresh the table
        await fetchUserList({
            extraquery: {
                pagesize: pagination.pageSize,
                pageindex: pagination.pageIndex,
                isDeleted: filters.status.id === "0" ? null : filters.status.id === "1" ? false : true,
                businessUnits: filters.businessUnits.id,
                isSystemAdmin: filters.permissions.id === "0" ? null : filters.permissions.id === "1" ? true : false,
                // status: filters.status.id,
            }
        })

        // --> to update the number of Users
        notification({ content: { name: t("success"), message: t("userManagement.editUser.updatedSuccessfully") }, type: 'success' })
        setDataForm({
            permission: { id: user.isSystemAdmin ? "1" : "2", name: user.isSystemAdmin ? "Admin" : "User" },
            status: user.isDeleted ? t("userManagement.active") : t("userManagement.inactive")
        })
        setIsModalOpen(false);

    }

    const handleCancel = () => {
        setDataForm({
            permission: { id: user.isSystemAdmin ? "1" : "2", name: user.isSystemAdmin ? "Admin" : "User" },
            status: user.isDeleted ? t("userManagement.active") : t("userManagement.inactive")
        });
        setIsModalOpen(false);
    };

    useEffect(() => {
        setDataForm({
            permission: { id: user.isSystemAdmin ? "1" : "2", name: user.isSystemAdmin ? "Admin" : "User" },
            status: user.isDeleted ? t("userManagement.active") : t("userManagement.inactive")
        })
    }, [isModalOpen])

    return (
        <>
            <MdOutlineEdit size={20} onClick={() => setIsModalOpen(!isModalOpen)} />
            <Modal
                className='addModule w-[500px] height-modal'
                title={t("userManagement.editUser.title")}
                okText={t("save")}
                cancelText={t("userManagement.t34")}
                open={isModalOpen}
                onOk={handleOk}
                confirmLoading={callApiLoading}
                onCancel={handleCancel}
            >
                <hr />
                <div className='mt-2'>
                    <span className='font-semibold block'>{user.fullName}</span>
                    <span>{user.email}</span>
                </div>
                <form className='flex justify-start items-center gap-10 mt-5'>
                    <section className='w-full'>
                        <Dropdown title={t("userManagement.editUser.selectPermission")}
                            dropdownHandler={(option) => setDataForm({ ...dataForm, permission: option })}
                            options={PERMISSIONOPTIONS}
                            value={dataForm.permission}
                        />
                    </section>
                </form>
                {contextHolder}
            </Modal >
        </>
    )
}

export default EditModule