import { Popconfirm, message } from 'antd';
import { RiProhibitedLine } from 'react-icons/ri';
import { UserInterface } from './index.type'
import { UserManagementPageContext } from 'src/contextAPI/userManagementPageContext';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react'
import useCallApi from 'src/hooks/useCallApi';
import useUserManagementPage from 'src/hooks/useUserManagementPage';
import { GlobalContext } from 'src/contextAPI/globalContext';

interface DeleteModuleType {
    user: UserInterface,
}

const DeleteModule = ({ user }: DeleteModuleType) => {
    const { t } = useTranslation();
    const { oid } = useContext(GlobalContext);
    const { pagination, filters, setTotalUsers } = useContext(UserManagementPageContext)
    const { callApi, isError } = useCallApi();
    const { fetchUserList } = useUserManagementPage()

    const confirm = async () => {
        const body = {
            submittedByUserId: oid,
            id: user.userObjectId
        }

        const res = await callApi({ endpoint: 'DeleteUser', body, method: 'POST' })

        if (res.status !== 200 || isError.status) {
            console.log('error', res)
            message.error(t(res))
            return
        }

        setTotalUsers((prev: number) => prev - 1)
        await fetchUserList({
            extraquery: {
                pagesize: pagination.pageSize,
                pageindex: pagination.pageIndex,
                isDeleted: filters.status.id === "0" ? null : filters.status.id === "1" ? false : true,
                isSystemAdmin: filters.permissions.id === "0" ? null : filters.permissions.id === "1" ? true : false,
                businessUnits: filters.businessUnits.id,
            }
        })
        message.success(t("userManagement.deleteUser.deletedSuccessfully"))
    }

    return (
        <div className="cursor-pointer hover:text-red-500">
            <Popconfirm
                title={t("userManagement.deleteUser.title")}
                description={t("userManagement.deleteUser.message")}
                onConfirm={confirm}
                okText={t("reportManagement.deletemodule.yes")}
                cancelText={t("reportManagement.deletemodule.no")}
                placement='left'
            >
                <RiProhibitedLine size={20} />
            </Popconfirm>
        </div>
    )
}

export default DeleteModule