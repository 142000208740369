import "./style.css"
import { ConfigProvider, DatePicker } from 'antd';
import { IoClose } from 'react-icons/io5';
import { IoIosSearch } from 'react-icons/io';
import { useConstant } from 'src/hooks/useConstant';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/input/Button';
import dayjs from 'dayjs';
import FrequentlyAccessedTable from './FrequentlyAccessedTable';
import React, { useEffect, useState } from 'react'
import TextInputWithSearchIcon from 'src/components/input/TextInputWithSearchIcon'
import useCallApi from 'src/hooks/useCallApi';

import "dayjs/locale/fr-ca";
import locale from "antd/es/locale/fr_CA";
import enLocale from "antd/es/locale/en_US";

function FrequentlyAccessed() {
  const { t, i18n } = useTranslation();
  const { FREQUENTLY_ACCESS_FILTERS } = useConstant();
  const { RangePicker } = DatePicker;
  const [formData, setFormData] = useState(FREQUENTLY_ACCESS_FILTERS)
  const [tableList, setTableList] = useState({ most: [], least: [] })
  const { callApi, callApiLoading, data, isError } = useCallApi()

  //Method to call Both API's to fetch Most and Least Accessed Reports
  const fetchFrequentlyAccess = async ({ toReset = false }: { toReset?: boolean }) => {
    let extraHeaders = {
      startDate: toReset ? dayjs(new Date()).format('YYYY-MM-DD') : dayjs(formData.date[0]).format('YYYY-MM-DD'),
      endDate: toReset ? dayjs(new Date()).format('YYYY-MM-DD') : dayjs(formData.date[1]).format('YYYY-MM-DD'),
      searchFilter: toReset ? "" : formData.report.name,
    }
    let resMost = await callApi({ endpoint: 'ReportUsage', extraHeaders: { ...extraHeaders, reportUsageType: 1 } })
    let resLeast = await callApi({ endpoint: 'ReportUsage', extraHeaders: { ...extraHeaders, reportUsageType: 2 } })

    setTableList((prev) => ({ ...prev, most: resMost }))
    setTableList((prev) => ({ ...prev, least: resLeast }))
  }

  //to handle the RANGE date change
  const handleDateChange = (date: any, stringDate: any) => {
    setFormData({ ...formData, date: date })
  }

  // To populate the table with data in the first render
  useEffect(() => { fetchFrequentlyAccess({}) }, [])

  // To update the date picker locale based on the language
  useEffect(() => {
    if (i18n.language === "fr") {
      dayjs.locale("fr-ca");
    } else {
      dayjs.locale("en");
    }
  }, [t])



  return (
    <div className='flex flex-col justify-center gap-10'>
      <div className='flex items-end justify-between gap-3 mt-[100px]'>
        {/* ---Report Name ---- */}
        <section className='relative w-full'>
          <div className='' onKeyDown={async (e) => e.key === "Enter" && await fetchFrequentlyAccess({})}>
            <div className="flex gap-1">
              <label className='block text-[14px] font-semibold mb-1'>{t("reportManagement.table.titleName")}</label>
            </div>
            <div className='flex ' >

              <TextInputWithSearchIcon
                value={formData.report.name}
                placeholder={t("reportManagement.searchPlaceHolder")}
                isToSearch={true}
                handleChange={(e) => setFormData({ ...formData, report: { ...formData.report, name: e.target.value } })}
              />
              <aside>
                <Button
                  btnType="main"
                  className="h-full w-8"
                  icon={<IoClose />}
                  handleClick={() => { setFormData({ ...formData, report: { ...formData.report, name: "" } }) }}
                />
              </aside>
            </div>
          </div>
        </section>
        {/* ---- Date Range ----- */}
        <section className="w-full" onKeyDown={async (e) => e.key === "Enter" && await fetchFrequentlyAccess({})}>
          <div className="flex items-center">
            <label className='block text-[14px] w-1/2 font-semibold mb-1'>{t("auditLogs.from")}</label>
            <label className='block text-[14px] font-semibold mb-1'>{t("auditLogs.to")}</label>
          </div>
          <ConfigProvider locale={i18n.language === "fr" ? locale : enLocale}>
            <RangePicker
              className='h-10 w-full'
              onChange={handleDateChange}
              value={formData.date as any}
            />
          </ConfigProvider>
        </section>
        {/* ---- Buttons ----- */}
        <section className='flex items-end justify-end gap-2 h-full w-full' >
          <Button
            btnType='secondary'
            text={t("auditLogs.reset")}
            className='h-10 px-8 rounded-[5px]'
            handleClick={async () => { setFormData(FREQUENTLY_ACCESS_FILTERS); await fetchFrequentlyAccess({ toReset: true }) }}
          />
          <Button
            btnType='main'
            text={t("auditLogs.search")}
            className='h-10 px-10 rounded-[5px]'
            icon={<IoIosSearch />}
            handleClick={async () => await fetchFrequentlyAccess({})}
          />
        </section>
      </div>
      <FrequentlyAccessedTable tableList={tableList} callApiLoading={callApiLoading} />
    </div>
  )
}

export default FrequentlyAccessed