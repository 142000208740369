import Button from "../../components/input/Button/AccordionButton";
import ContentSection from "../../components/layout/content/ContentSection/ContentSection";
import { MyTopReportsInterface } from "./MyTopReports.types";

const handleButtonClick = (event: React.ChangeEvent<HTMLInputElement>) => {
  console.log("handleButtonClick " + event.target.value);
};

const myTopReports = () => (
  <div className="flex flex-row w-full gap-6">
    <div className="flex flex-col gap-4 w-1/2">
      <Button
        className=""
        onClick={handleButtonClick}
        value="Report #1"
      >
        Report #1
      </Button>
      <Button
        className=""
        onClick={handleButtonClick}
        value="Report #2"
      >
        Report #2
      </Button>
      <Button
        className=""
        onClick={handleButtonClick}
        value="Report #3"
      >
        Report #3
      </Button>
    </div>
    <div className="flex flex-col gap-4 w-1/2">
      <Button
        className=""
        onClick={handleButtonClick}
        value="Report #4"
      >
        Report #4
      </Button>
      <Button
        className=""
        onClick={handleButtonClick}
        value="Report #5"
      >
        Report #5
      </Button>
      <Button
        className=""
        onClick={handleButtonClick}
        value="Report #6"
      >
        Report #6
      </Button>
    </div>
  </div>
);

const MyTopReports = ({
  className = "",
  navigate
}: MyTopReportsInterface) => (
  <ContentSection
    action={() => { navigate && navigate("/reports/discover"); }}
    actionClassName="underline"
    actionLabel={"ALL REPORTS"}
    className={`
            col-span-2 ${className}
        `}
    hasControlActions
    title="MY TOP REPORTS"
  >
    {myTopReports()}
  </ContentSection>
);

export default MyTopReports;
