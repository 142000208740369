import React, { useContext, useEffect } from 'react'
import useCallApi from 'src/hooks/useCallApi';
import { useDebounce } from 'src/hooks/useDebounce';
import { AccordionSearchReportsInterface, propsAccordionSearch } from './AccordionSection.types';
import { useNotification } from 'src/hooks/useNotification';
import { useNavigate } from 'react-router-dom';
import { ReportPageContext } from 'src/contextAPI/reportPageContext';
import { useTranslation } from 'react-i18next';

function ReportSearch({ searchQuery, getMenuData, setShowResults, urlToCall }: propsAccordionSearch) {
    const { t, i18n } = useTranslation();
    const { callApi, callApiLoading, data, setData, isError } = useCallApi();
    const debouncedSearch = useDebounce(searchQuery, 500);
    const { notification, contextHolder } = useNotification();
    const navigate = useNavigate();
    const { setReportId, setWorkspaceGuid, setReportName, setReportDescription } = useContext(ReportPageContext);

    useEffect(() => {
        if (debouncedSearch === "") {
            setData([]);
            return;
        }
        callApi({ endpoint: urlToCall, query: debouncedSearch });
    }, [debouncedSearch]);

    //Effect to handle error
    useEffect(() => {
        if (isError.status) {
            notification({ content: { name: t("t2"), message: isError.message }, type: 'error' })
        }
    }, [isError]);

    const handleOnClick = (item: AccordionSearchReportsInterface) => {
        setShowResults(false)
        setReportId && setReportId(item.id);
        setWorkspaceGuid && setWorkspaceGuid(item.workSpaceID);
        setReportName && setReportName({ en: item.nameEn, fr: item.nameFr });
        setReportDescription && setReportDescription({ en: item.descriptionEn, fr: item.descriptionFr });
        navigate(`/reports?reportId=${item.id}`);
        getMenuData(item.id);
    };

    return (
        <>
            {searchQuery !== "" && debouncedSearch !== "" && (
                <ul className="absolute bg-[#fbfbfb] max-h-96 overflow-y-auto scroll-smooth scroll-thin z-50 w-[392px] general-shadow">
                    {
                        callApiLoading
                            ? <li className="w-full py-3 pl-3 pr-2 cursor-pointer text-sm">{t("loading")}</li>
                            : <>
                                {data.length === 0 && searchQuery !== "" && (
                                    <li className="w-full py-3 pl-3 pr-2 cursor-pointer text-sm">{t("reports.NoMartchingResults")}</li>
                                )}
                                {data.map((item: AccordionSearchReportsInterface) => (
                                    <li key={item.id}
                                        className="w-full py-3 pl-3 pr-2 cursor-pointer hover:text-[#ed1a3b] hover:bg-[#fff2ee] text-sm break-words whitespace-normal" onClick={() => handleOnClick(item)}>
                                        {i18n.language === 'fr' ? item.nameFr : item.nameEn}
                                    </li>
                                ))}
                            </>
                    }
                </ul>
            )}
            {contextHolder}
        </>
    );
}
export default ReportSearch;