import { HeaderWrapperInterface } from "./HeaderWrapper.type";
import MaxWidthWrapper from "../../../components/page/MaxWidthWrapper";

const HeaderWrapper = ({
  children,
  className = ""
}: HeaderWrapperInterface) => (
  <MaxWidthWrapper
    className={`
      flex flex-row p-4
      ${className}
    `}
  >
    {children}
  </MaxWidthWrapper>
);

export default HeaderWrapper;
