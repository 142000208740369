import { CircleLoader } from '../Loader'
import { IoIosStar } from 'react-icons/io'
import { MenuItemInterface } from '../layout/navigation/Accordion/Accordion.types'
import { ReportPageContext } from 'src/contextAPI/reportPageContext'
import { useNotification } from 'src/hooks/useNotification'
import { useTranslation } from 'react-i18next'
import React, { useContext, useEffect, useState } from 'react'
import useCallApi from 'src/hooks/useCallApi'

interface StarFavoriteInterface {
    className?: string
    item: MenuItemInterface
}

const StarFavorite = ({ className, item }: StarFavoriteInterface) => {
    const { callApi, callApiLoading } = useCallApi()
    const { t } = useTranslation();
    const { contextHolder, notification } = useNotification()
    const [isFavorite, setIsFavorite] = useState({ isFavorite: false, color: '#b8b3b3' })
    const { setFavoriteItems, favoriteItems } = useContext(ReportPageContext)


    useEffect(() => {
        if (!favoriteItems) return
        let value = favoriteItems.some((favoriteItem: MenuItemInterface) => favoriteItem.id === item.id)
        let color = value ? '#f5b042' : '#b8b3b3'
        setIsFavorite({ isFavorite: value, color: color })
    }, [favoriteItems]);

    const handleClick = async (isAlreadyFavorite: boolean) => {
        let body = {
            reportId: item.id,
            isFavorite: !isFavorite.isFavorite //--> the opposite of the current state
        }
        console.log(isAlreadyFavorite)

        //If the number of favorite items is more than 2, show a notification
        if (favoriteItems.length >= 10 && !isAlreadyFavorite) {
            notification({
                type: 'error',
                content: {
                    name: t("t2"),
                    message: t('reports.favorite.limit')
                }
            })
            return
        }

        let res = await callApi({ endpoint: 'Favorites', body: body, method: 'POST' })
        //If there is an error, show a notification
        if (res.isError) {
            console.log("error", res)
            notification({
                type: 'error',
                content: {
                    name: t("t2"),
                    message: `${res.status || res} ${res.message || res.statusText}`
                }
            })
            return
        }
        //If there is no error, show a success notification
        if (res) {
            setFavoriteItems((prevItems: MenuItemInterface[]) =>
                isFavorite.isFavorite
                    ? prevItems.filter(favoriteItem => favoriteItem.id !== item.id)  //  delete if it is not marked as favorite
                    : [...prevItems, { ...item, nameEn: item?.label?.en, nameFr: item?.label?.fr }] // Add if it is marked as favorite
            );

            setIsFavorite({ isFavorite: !isFavorite.isFavorite, color: !isFavorite.isFavorite ? '#f5b042' : '#b8b3b3' })
            notification({
                type: 'success',
                content: {
                    name: t("success"),
                    message: isFavorite.isFavorite ? t('reports.favorite.removed') : t('reports.favorite.added')
                }
            })
        }
    }
    return <div className={` ${className}cursor-pointer flex justify-center items-center ${className}`}>
        {callApiLoading
            ? <CircleLoader />
            :
            <IoIosStar
                onClick={() => handleClick(isFavorite.isFavorite)}
                size={20}
                style={{ fill: `${isFavorite.color}`, stroke: '#5d4040', minWidth: '15px' }}
            />
        }
        {contextHolder}
    </div>
}
export default StarFavorite