import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationResult, MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { useContext, useEffect, useState } from "react";
import PowerBiEmbeded from "src/powerBi/PowerBiEmbed/PowerBiEmbeded";
import useCallApi from "src/hooks/useCallApi";
import { LandingDefaultPage } from '../../components/NoAuthorized';
import { ReportPageContext } from "src/contextAPI/reportPageContext";
import { CircleLoader } from "src/components/Loader";
import { useNotification } from "src/hooks/useNotification";
import { pbiIdservicePrincipalToken } from "./Report.types";
import { useTranslation } from "react-i18next";
import { status } from '../../components/FilterSections/index.type';

const Report = () => {
  const { accounts, instance } = useMsal();
  const { t } = useTranslation();
  const [reportAccessToken, setReportAccessToken] = useState<string>();
  const [canSeeReport, setcanSeeReport] = useState(false)
  const [pbiReportId, setPbiReportId] = useState("")
  const { callApi, callApiLoading, isError } = useCallApi();
  const { reportId, workspaceGuid, setWorkspaceGuid } = useContext(ReportPageContext);
  const { notification, contextHolder } = useNotification();
  const [authMethodPBI, setAuthMethodPBI] = useState('1')

  const hostName = process.env.REACT_APP_POWER_BI_HOSTNAME;

  const authRequest = {
    scopes: [
      "https://analysis.windows.net/powerbi/api/Report.Read.All",
      "https://analysis.windows.net/powerbi/api/Dataset.Read.All",
    ],
    account: accounts[0],
  };

  //Get Token for User Context
  const aquirePbiAccessTokenUserContext = () => {
    instance
      .acquireTokenSilent(authRequest)
      .then(response => {
        setReportAccessToken(response.accessToken);
      })
      .catch(error => {
        instance
          .acquireTokenPopup(authRequest)
          .then(response => {
            setReportAccessToken(response.accessToken);
          })
          .catch(error => {
            console.log(error);
          });
      });
  };

  //Get Token for Service Principal
  const aquirePbiAccessTokenServicePrincipal = async ({ pbiId, workspaceId }: pbiIdservicePrincipalToken) => {
    let query = `workspaceId=${workspaceId}&reportId=${pbiId}`
    let res = await callApi({ endpoint: 'SpReportToken', query: query })


    if (res === undefined || (res?.status && res.status != 200)) {
      console.log("****Error getting token for Service Principal****")
      notification({ content: { name: t("t2"), message: `Error getting token for Service Principal:  ${isError.message}` }, type: 'error' })
      return
    }
    console.log("****Note: Access Token for Service Principal loaded succesfully****")
    setReportAccessToken(res.token)
  }

  const checkAccessToReport = async () => {
    let jsonData = await callApi({ endpoint: 'OneReport', query: reportId })
    if (!jsonData || jsonData.categories.length === 0) {
      setcanSeeReport(false);  // Forzar que se evalúe el acceso como denegado
      return;
    }

    if (jsonData.categories.length > 0) {
      setPbiReportId(jsonData.categories[0].reports[0].powerBIReportId)
      setWorkspaceGuid(jsonData.categories[0].reports[0].workSpaceID)
      const typeOfAuth = jsonData?.categories[0]?.reports[0]?.authMethod
      const pbiId = jsonData?.categories[0]?.reports[0]?.powerBIReportId
      const workspaceId = jsonData?.categories[0]?.reports[0]?.workSpaceID

      if (typeOfAuth !== '1' && typeOfAuth !== '2') {
        notification({ content: { name: t("t2"), message: `AuthMethod is coming empty` }, type: 'error' })
        return
      }
      setAuthMethodPBI(typeOfAuth)
      if (typeOfAuth === '1') { // ===> 1: User Context
        aquirePbiAccessTokenUserContext();
      } else { // ===> 2: Service Ppal
        await aquirePbiAccessTokenServicePrincipal({ pbiId, workspaceId })
      }
      setcanSeeReport(true)
    } else {
      setcanSeeReport(false)
    }
  };


  useEffect(() => {
    checkAccessToReport()
  }, [reportId, t, canSeeReport]);



  const ErrorComponent: React.FC<MsalAuthenticationResult> = ({ error }) => {
    return (
      <div className="h4">
        An Error Occurred: {error && error.errorCode ? error.errorCode : "an unknown authorization error occurred"}
      </div>
    );
  };

  const Loading = () => {
    return <div className="h4">Authentication in progress...</div>;
  };

  return (
    <>
      {callApiLoading ? (
        <div className="flex justify-center items-center w-full">
          <CircleLoader />
        </div>
      ) : reportAccessToken && pbiReportId !== "" && canSeeReport ? (
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={authRequest}
          errorComponent={ErrorComponent}
          loadingComponent={Loading}
        >
          <PowerBiEmbeded
            accessToken={reportAccessToken}
            embedUrl={`${hostName}?reportId=${pbiReportId}&groupId=${workspaceGuid}`}
            reportId={pbiReportId}
            tokenType={authMethodPBI}
          />
        </MsalAuthenticationTemplate>
      ) : (
        !callApiLoading && (canSeeReport === false) &&
        <LandingDefaultPage title={t("unauthorized_title")} message={t("unauthorizedMessage")} typeOfImage="userAuth" requestButton/>
      )}
      {contextHolder}
    </>
  );
};
export default Report;