import { useContext } from "react"
import { LabelType } from "./index.type";

function Label<T>({ text, qty }: LabelType<T>){
    
    return (
        <div className="text-[15px] flex gap-2 justify-center items-center">
            <h5 >{text}: </h5>
            <span className="text-[15px] font-semibold">{qty}</span>
        </div>
    )
}
export default Label