import { CardInterface } from "./Card.types";
import MaxWidthWrapper from "../page/MaxWidthWrapper";

const Card = ({
  children,
  className = ""
}: CardInterface) => (
  <MaxWidthWrapper className={`w-full h-full ${className}`}>
      {children}
  </MaxWidthWrapper>
);

export default Card;
