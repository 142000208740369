import { GlobalContext } from 'src/contextAPI/globalContext';
import { Popconfirm, message } from 'antd'
import { ReportManagementPageContext } from 'src/contextAPI/reportManagementPageContext';
import { ReportType } from './index.type';
import { RiProhibitedLine } from 'react-icons/ri';
import { useReportManagePage } from 'src/hooks/useReportManagePage';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react'
import useCallApi from 'src/hooks/useCallApi';

interface DeleteModuleType {
    report: ReportType,
}

function DeleteModule({ report }: DeleteModuleType) {
    const { t } = useTranslation();
    const { oid } = useContext(GlobalContext);
    const { setReportsPerUser, pagination, filters } = useContext(ReportManagementPageContext);
    const { callApi } = useCallApi();
    const { fetchReportList } = useReportManagePage();

    const confirm = async () => {
        const body = {
            submittedByUserId: oid,
            id: report.id
        }

        const res = await callApi({ endpoint: 'DeleteReport', body, method: 'POST' })

        if (res.status !== 200) {
            console.log('error', res)
            message.error(t(res))
            return
        }
     
        message.success(t("reportManagement.deletemodule.deletedSuccessfully"))
        setReportsPerUser((prev: number) => prev - 1)
        await fetchReportList({ extraquery: { pagesize: pagination.pageSize, pageindex: pagination.pageIndex, categoryid: filters.dropDownValue.id, isPrivate: filters.permissionValue.id === "1" ? false : filters.permissionValue.id === "2" ? true : undefined } })
    };

    return (
        <div className="cursor-pointer hover:text-red-500">
            <Popconfirm
                title={t("reportManagement.deletemodule.title")}
                description={t("reportManagement.deletemodule.message")}
                onConfirm={async () => await confirm()}
                okText={t("reportManagement.deletemodule.yes")}
                cancelText={t("reportManagement.deletemodule.no")}
                placement='left'
            >
                <RiProhibitedLine size={20} />
            </Popconfirm>
        </div>
    )
}

export default DeleteModule