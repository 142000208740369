import { Columns } from './tableColumns'
import { Table } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

type FrequentlyAccessedTableProps = {
  tableList: { most: any[], least: any[] },
  callApiLoading: boolean
}

function FrequentlyAccessedTable({ tableList, callApiLoading }: FrequentlyAccessedTableProps) {
  const [tabSelected, setTabSelected] = useState(1)
  const { t, i18n } = useTranslation();
  return (
    <div className='FrequentlyAccessed'>
      <ul className='flex items-center gap-5'>
        <li className={`py-3 text-[14px] cursor-pointer rounded-tl relative ${tabSelected === 1 ? "font-semibold" : "hover:text-[#e81a3b]"}`}
          onClick={() => setTabSelected(1)}>
          <div className='relative'>
            <span>{t("auditLogs.mostAccessed")}</span>
            {tabSelected === 1 && <hr className="bg-[#e81a3b] border-0 h-1 w-full absolute" />}
          </div>
        </li>
        <li className={`py-3 text-[14px] cursor-pointer ${tabSelected === 2 ? "font-semibold" : "hover:text-[#e81a3b]"}`}
          onClick={() => setTabSelected(2)}>
          <div className='relative'>
            <span>{t("auditLogs.leastAccessed")}</span>
            {tabSelected === 2 && <hr className="bg-[#e81a3b] border-0 h-1 w-full absolute" />}
          </div>
        </li>
      </ul>
      <Table
        className='log-table'
        loading={callApiLoading}
        rowKey={(record) => record.reportId}
        dataSource={tabSelected === 1 ? tableList.most : tableList.least}
        columns={Columns()}
        showSorterTooltip={false}
        pagination={false}
        scroll={{ y: "calc(100vh - 300px)" }}
      />
    </div>
  )
}

export default FrequentlyAccessedTable